import {
  CompositeNavigationProp,
  RouteProp,
  useFocusEffect,
} from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import axios from "axios";
import {
  Heading,
  HStack,
  Pressable,
  Spinner,
  VStack,
  Box,
  Text,
  Toast,
} from "native-base";
import React, { useState } from "react";
import useSWR from "swr";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import HeaderLayout from "../../../components/Layout/HeaderLayout";
import MessageToast, {
  MesssageToastType,
} from "../../../components/Toast/MessageToast";
import { Venue } from "../../../models/requests/Venue";
import {
  ClubBottomTabNavigatorParamList,
  MainStackNavigatorParamList,
} from "../../../routers/Types";
import { formatCoreUrl } from "../../../services/ServiceUtil";
import { deleteVenue } from "../../../services/VenueServices";
import { getTranslation } from "../../../utils/translation";
import VenueItemList from "../../../components/VenueList";
import { showApiToastError } from "../../../components/ApiToastError";
import BannerButton from "../../../components/BannerButton";

const t = getTranslation(["screen.ClubScreens.VenueList", "constant.button"]);

export type VenueListNavigationProps = CompositeNavigationProp<
  NativeStackNavigationProp<ClubBottomTabNavigatorParamList, "ClubVenueList">,
  NativeStackNavigationProp<MainStackNavigatorParamList>
>;

export type VenueRouteProp = RouteProp<
  ClubBottomTabNavigatorParamList,
  "ClubVenueList"
>;

interface VenueListScreenNavigationProps {
  navigation: VenueListNavigationProps;
  route: VenueRouteProp;
}

enum ActiveTab {
  Created = "Created",
  Happening = "Happening",
}

const availableTabs = [ActiveTab.Created, ActiveTab.Happening];

export default function VenueList({
  navigation,
}: VenueListScreenNavigationProps) {
  const [activeTab, setActiveTab] = useState("Created");
  const [deleteVenueId, setDeleteVenueId] = React.useState<number>();
  const [isModalOpen, setModalOpen] = React.useState(false);

  const {
    data: venues,
    isValidating: isValidatingVenueBookings,
    mutate: mutateVenueBookings,
  } = useSWR<Venue[]>(formatCoreUrl("/venue/"), (path) =>
    axios.get(path).then((res) => (Array.isArray(res.data) ? res.data : []))
  );

  // refresh the list when focus
  // useFocusEffect(
  //   React.useCallback(() => {
  //     mutateVenueBookings();
  //   }, [mutateVenueBookings])
  // );

  const tabs = () => {
    return (
      <HStack my="3" space="3">
        {availableTabs.map((tab) => {
          return (
            <Pressable key={tab} onPress={() => setActiveTab(tab)}>
              <Heading color={activeTab === tab ? "#000" : "#b3b3b3"}>
                {t(tab)}
              </Heading>
            </Pressable>
          );
        })}
      </HStack>
    );
  };

  const renderVenueBookingRequests = () => {
    if (isValidatingVenueBookings) {
      return <Spinner />;
    }

    if (!venues || venues.length === 0) {
      return (
        <Text mt="3" alignSelf="center">
          {t("No venue")}
        </Text>
      );
    }

    return VenueItemList({
      venues,
      onPressVenue: (venue: Venue) => {
        // See venue details
        navigation.navigate("ClubVenueDetails", { venue });
      },
      onPressManage: (venue: Venue) => {
        navigation.navigate("ManageVenue", { venue });
      },
      onPressDelete: async (venue: Venue) => {
        // Delete venue booking
        setDeleteVenueId(venue.id);
        setModalOpen(true);
      },
      onPressEdit: (venue: Venue) => {
        // Update venue booking
        navigation.navigate("ClubUpdateVenue", {
          venue,
        });
      },
    });
  };

  return (
    <HeaderLayout
      headerProps={{
        title: t("Venue list"),
        hasBackButton: false,
      }}
      supportPullToRefresh
      onRefresh={() => {
        mutateVenueBookings();
      }}
    >
      <VStack space="4">
        <BannerButton
          headerLabel={t("Create Venue")}
          description={t("Tap here to create a new venue")}
          onPress={() => {
            navigation.navigate("ClubAddVenue");
          }}
        />
        <VStack space="4" mx="4">
          {activeTab === ActiveTab.Created && renderVenueBookingRequests()}
        </VStack>
      </VStack>

      <ConfirmationModal
        alertType="Fail"
        confirmText={t("Yes, delete")}
        cancelText={t("Cancel")}
        isOpen={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        title={t("Are you sure to delete this venue you created?")}
        description={t(
          "Once the venue delete participants are not longer to reach this venue"
        )}
        onConfirm={async () => {
          setModalOpen(false);
          if (deleteVenueId) {
            try {
              await deleteVenue(deleteVenueId);
              if (!Toast.isActive("deleteSuccess"))
                Toast.show({
                  id: "deleteSuccess",
                  duration: 2000,
                  render: () => {
                    return (
                      <MessageToast
                        type={MesssageToastType.Success}
                        title={t("Your venue is deleted")}
                      />
                    );
                  },
                });
              mutateVenueBookings();
            } catch (error) {
              showApiToastError(error);
            }
          }
        }}
      />
    </HeaderLayout>
  );
}
