import React from "react";
import { Icon } from "native-base";
import { G, Svg, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function ExportIcon(props: InterfaceIconProps) {
  return (
    <Icon size="lg" viewBox="0 0 16 18" {...props}>
      <Svg width="17" height="16" viewBox="0 0 17 16" fill="none">
        <Path
          d="M12.0023 5.34831C12.1043 5.25806 12.1616 5.1358 12.1616 5.00833C12.1616 4.88086 12.1043 4.7586 12.0023 4.66836L9.13864 2.1411C9.03522 2.05071 8.89555 2 8.75 2C8.60445 2 8.46478 2.05071 8.36136 2.1411L5.49773 4.66836C5.39572 4.7586 5.33843 4.88086 5.33843 5.00833C5.33843 5.1358 5.39572 5.25806 5.49773 5.34831C5.54848 5.39379 5.60898 5.42991 5.6757 5.45457C5.74242 5.47922 5.81403 5.49192 5.88636 5.49192C5.9587 5.49192 6.03031 5.47922 6.09703 5.45457C6.16375 5.42991 6.22425 5.39379 6.275 5.34831L8.20455 3.64542V9.22343C8.20455 9.3511 8.26201 9.47354 8.36431 9.56381C8.4666 9.65409 8.60534 9.70481 8.75 9.70481C8.89466 9.70481 9.0334 9.65409 9.13569 9.56381C9.23799 9.47354 9.29545 9.3511 9.29545 9.22343V3.64542L11.225 5.34831C11.2758 5.39379 11.3363 5.42991 11.403 5.45457C11.4697 5.47922 11.5413 5.49192 11.6136 5.49192C11.686 5.49192 11.7576 5.47922 11.8243 5.45457C11.891 5.42991 11.9515 5.39379 12.0023 5.34831ZM3.84091 6.81652H5.47727C5.62194 6.81652 5.76067 6.86724 5.86297 6.95751C5.96526 7.04779 6.02273 7.17023 6.02273 7.2979C6.02273 7.42557 5.96526 7.54801 5.86297 7.63829C5.76067 7.72857 5.62194 7.77928 5.47727 7.77928H3.84091V14.0372H13.6591V7.77928H12.0227C11.8781 7.77928 11.7393 7.72857 11.637 7.63829C11.5347 7.54801 11.4773 7.42557 11.4773 7.2979C11.4773 7.17023 11.5347 7.04779 11.637 6.95751C11.7393 6.86724 11.8781 6.81652 12.0227 6.81652H13.6591C13.9484 6.81652 14.2259 6.91795 14.4305 7.09851C14.6351 7.27906 14.75 7.52394 14.75 7.77928V14.0372C14.75 14.2926 14.6351 14.5375 14.4305 14.718C14.2259 14.8986 13.9484 15 13.6591 15H3.84091C3.55158 15 3.27411 14.8986 3.06952 14.718C2.86493 14.5375 2.75 14.2926 2.75 14.0372V7.77928C2.75 7.52394 2.86493 7.27906 3.06952 7.09851C3.27411 6.91795 3.55158 6.81652 3.84091 6.81652Z"
          fill="white"
        />
      </Svg>
    </Icon>
  );
}
