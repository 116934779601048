export default {
  constant: {
    ChatGPT: {
      Serve: "Serve",
      Receive: "Receive",
      Forehand: "Forehand",
      Backhand: "Backhand",
      Footwork: "Footwork",
      Rules: "Rules",
      Spin: "Spin",
    },
    button: {
      Next: "Next",
      Approve: "Approve",
      "Quick Approve": "Quick Approve",
      "Bulk Approve": "Bulk Approve",
      Reject: "Reject",
      Edit: "Edit",
      Cancel: "Cancel",
      Yes: "Yes",
      No: "No",
      Confirm: "Confirm",
      Done: "Done",
      Delete: "Delete",
      Submit: "Submit",
      Process: "Process",
      Continue: "Continue",
      Create: "Create",
      Apply: "Apply",
      Update: "Update",
      Save: "Save",
      Accept: "Accept",
      View: "View",
      Add: "Add ",
      Manage: "Manage",
      "View Profile": "View Profile",
      Remove: "Remove",
      Skip: "Skip",
      Refund: "Refund",
      Success: "Success",
      "Re-apply": "Re-apply",
      Loading: "Loading",
      Verify: "Verify",
    },
    eventType: {
      Competition: "Competition",
      Others: "Others",
      OpenDay: "Open Day",
      GroupBallGame: "Group Ball Game",
      Single: "Singles",
      Double: "Doubles",
      Team: "Team",
    },
    tabType: {
      Existing: "Existing",
      Past: "Past",
      Pending: "Pending",
      Upcoming: "Upcoming",
      Completed: "Completed",
      All: "All",
    },
    area: {
      HK: "Hong Kong",
      KLN: "Kowloon",
      NT: "New Territories",
    },
    dummyUser: {
      "Trial Account": "Trial Account",
      "Click Verify to convert your trial account to a verified account":
        "Click “Verify” to convert your trial account to a verified account",
      "Getting Started": "Getting Started",
      "Update your profile to connect with other players, coaches and clubs":
        "Update your profile to connect with other players, coaches and clubs",
    },
    date: {
      h: "h",
      Now: "Now",
      m: "m",
      d: "d",
      w: "w",
      y: "y",
      now: "now",
      "an hour ago": "an hour ago",
      "a second ago": "a second ago",
      "# seconds ago": "# seconds ago",
      "a minute ago": "a minute ago",
      "in a second": "in a second",
      "in # seconds": "in # seconds",
      "# minutes ago": "# minutes ago",
      "An hour ago": "An hour ago",
      "# hours ago": "# hours ago",
      "in a minute": "in a minute",
      "in # minutes": "in # minutes",
      "in an hour": "in an hour",
      "in # hours": "in # hours",
      yesterday: "yesterday",
      "# days ago": "# days go",
      tomorrow: "tomorrow",
      "in # days": "in # days",
      "last week": "lastweek",
      "# weeks ago": "# weeks ago",
      "in a week": "in a week",
      "in # weeks": "in # weeks",
      "last month": "last month",
      "# months ago": "# months ago",
      "in a month": "in a month",
      "in # months": "in # months",
      "last year": "last year",
      "# years ago": "# years ago",
      "in a year": "in a year",
      "in # years": "in # years",
    },
    district: {
      HK: {
        CW: "Central and Western",
        EA: "Eastern",
        SO: "Southern",
        WC: "Wan Chai",
        KC: "Kowloon City",
        KU: "Kwun Tong",
        SS: "Sham Shui Po",
        WT: "Wong Tai Sin",
        YT: "Yau Tsim Mong",
        IS: "Islands",
        KI: "Kwai Tsing",
        NO: "North",
        SK: "Sai Kung",
        ST: "Sha Tin",
        TP: "Tai Po",
        TW: "Tsuen Wan",
        TM: "Tuen Mun",
        YL: "Yuen Long",
      },
    },
    cancellationPeriod: {
      "24": "24 hours",
      "48": "48 hours",
      "72": "72 hours",
    },
    week: {
      MON: "M",
      TUE: "T",
      WED: "W",
      THU: "T",
      FRI: "F",
      SAT: "S",
      SUN: "S",
      Mon: "M",
      Tue: "T",
      Wed: "W",
      Thu: "T",
      Fri: "F",
      Sat: "S",
      Sun: "S",
    },
    weekLong: {
      MON: "Mon",
      TUE: "Tue",
      WED: "Wed",
      THU: "Thu",
      FRI: "Fri",
      SAT: "Sat",
      SUN: "Sun",
      Mon: "Mon",
      Tue: "Tue",
      Wed: "Wed",
      Thu: "Thu",
      Fri: "Fri",
      Sat: "Sat",
      Sun: "Sun",
    },

    profile: {
      // Enum
      "Chinese Name": "Chinese Name",
      "View profile": "View Profile",
      "No Rank": "No Ranking",
      Female: "Female",
      Male: "Male",
      Left: "Left",
      "Left hand": "Left Hand",
      "Left Hand": "Left Hand",
      Right: "Right",
      "Right hand": "Right Hand",
      "Right Hand": "Right Hand",
      Attacker: "Attacker",
      Defender: "Defender",
      "All Rounded": "All Rounded",
      AllRounded: "All Rounded",
      Chopper: "Chopper",
      Shakehand: "Shakehand",
      Penhold: "Penhold",
      Inverted: "Inverted",
      "Short Pips": "Short Pips",
      "Medium Pips": "Medium Pips",
      "Long Pips": "Long Pips",
      ShortPips: "Short Pips",
      MediumPips: "Medium Pips",
      LongPips: "Long Pips",
      Antispin: "Antispin",
      Beginner: "Beginner",
      GroupE: "Group E",
      GroupD: "Group D",
      GroupC: "Group C",
      GroupB: "Group B",
      GroupA: "Group A",
      BeginnerExcellence: "Beginner Excellence",
      "Beginner Excellence": "Beginner Excellence",
      Intermediate: "Intermediate",
      IntermediateExcellence: "Intermediate Excellence",
      "Intermediate Excellence": "Intermediate Excellence",
      Advanced: "Advanced",
      HKTeam: "HK Team",
      "HK Team": "HK Team",
      // Terms
      "Profile name": "Profile Name",
      "First name": "English First Name",
      "Last name": "English Last Name",
      "First Name": "English First Name",
      "Last Name": "English Last Name",
      "Phone Number": "Phone number",
      License: "License",
      "License No": "License No",
      Districts: "Districts",
      "District(s)": "District(s)",
      Address: "Address",
      Description: "Description",
      "Hand Used": "Hand Used",
      Style: "Style",
      Blade: "Blade",
      Rubber: "Rubber",
      "Back Rubber": "Back Rubber",
      "Back rubber": "Back Rubber",
      Ranking: "Ranking",
      "HKTTA ID": "HKTTA ID",
      "Player Level": "Player Level",
      Level: "Level",
      "Coach Level": "Level",
      Fee: "Fee",
      Achievement: "Achievement",
      Achievements: "Achievements",
      Mobile: "Mobile",
      "Date of Birth": "Date of Birth",
      Sex: "Sex",
      Email: "Email",
      // Form Input
      "Edit Profile": "Edit Profile",
      "Select Blade": "Select Blade",
      "Select Rubber": "Select Rubber",
      "Select Back Rubber": "Select Back Rubber",
      "Select Level": "Select Level",
      "Add achievement": "Add Achievement",
      "Select at least 1 district": "Select at least 1 district",
      "Select date of birth": "Select date of birth",
      "Select Sex": "Select Sex",
    },
  },
  validation: {
    "is required": "is required",
    "Must be a number": "Must be a number",
    "Must be an 8-digit number": "Must be an 8-digit number",
    "Must be a 4-digit number": "Must be a 4-digit number",
    "Date should be in the future": "Date should be in the future",
    "Value should contains positive number only":
      "Value should contains positive number only",
    "Out of index of sessions": "Invalid date of first session",
    "from time must be earlier than the to time":
      "End time must be later than start time",
    "Email does not match": "Email does not match",
    "Please enter a valid email address": "Please enter a valid email address",
  },
  formInput: {
    CreateLeague: "Create League",
    CreateEvent: "Create Event",
    UpdateEvent: "Update Event",
    Event: "Event",
    Venue: "Venue",
    Course: "Course",
    Select: "Select ",
    "Start Time": "Start Time",
    "End Time": "End Time",
    Time: "Time",
    Date: "Date",
    District: "District",
    Type: "Type",
    Area: "Area",
    Name: "Name",
    Capacity: "Quota",
    Address: "Address",
    Description: "Description",
    Photo: "Photo",
    "Files smaller than 5mb": "Files smaller than 5mb",
    "Basic Info": "Basic Info",
    Price: "Price",
    Search: "Search",
    Filter: "Filter",
  },
  toastMessage: {
    Successful: " Successful",
    Successfully: " Successfully",
    Success: " Success",
  },
  leagueTerms: {
    "No Fixtures & Result": "No Fixtures & Result",
    Statistics: "Statistics",
    League: "League",
    Season: "Season",
    Tier: "Tier",
    Division: "Division",
    Round: "Round",
    Import: "Import",
    Export: "Export",
    Table: "Team",
    Fixture: "Fixture",
    Result: "Result",
    Team: "Team",
    Match: "Match",
    "Home Team": "Home Team",
    "Away Team": "Away Team",
    SubmitResult: "Submit Result",
    EditTeam: "Edit Team",
    Player: "Player",
    Teams: "Teams",
    Audience: "Audience",
    "View the fixture, results and statistics for different leagues":
      "View the fixture, results and statistics for different leagues",
    "Join your team to view your schedule and manage the results":
      "Join your team to view your schedule and manage the results",
    "Fixtures & Results": "Fixtures & Results",
    "Player List": "Player List",
    "View Profile": "View Profile",
    "Matches Played": "Matches Played",
    "Matches Won": "Matches Won",
    "Winning Rate": "Winning Rate",
    Ranking: "Ranking",
    "Total Points": "Total Points",
    "Recent Matches": "Recent Matches",
    Point: "Point",
    Set: "Set",
    "Team name": "Team name",
    "Team List": "Team List",
    "Fixture and Result Filter": "Fixture and Result Filter",
    "Fixture and Result": "Fixture and Result",
    "Filter Results": "Filter Results",
    "Upcoming Fixtures": "Upcoming Fixtures",
    "View All": "View All",
    "No Upcoming Fixture": "No Upcoming Fixture",
    "Team Management": "Team Management",
    "Submit Results": "Submit Results",
    "Review Results": "Review Results",
    "Join Team": "Join Team",
    "Confirm to remove": "Confirm to remove",
    Players: "Players",
    Draft: "Draft",
    Pending: "Pending",
    Published: "Published",
    "Click to Input": "Click to Input",
    "Pending Approval": "Pending Approval",
    "Organizer Reviewing": "Organizer Reviewing",
    "Click to Review": "Click to Review",
    "Canceled to Join Team": "Cancelled to Join Team",
    "You will not be able to manage the results before you joined a team":
      "You will not be able to manage the results before you joined a team",
    "Applied to Join Team": "Applied to Join Team",
    "Organizers Team members will review your application":
      "Organizers / Team members will review your application",
    Join: "Join",
    Cancel: "Cancel",
  },
  screen: {
    ClubHomeV2: {
      Course: "Course",
      Event: "Event",
      "Course Applications": "Course Applications",
      Details: "Details",
      Applications: "Applications",
      "Leave Applications": "Leave Applications",
      "Payment Evidences": "Payment Evidences",
      "Date of First Session": "Date of First Session",
      "Number of applied sessions": "Number of applied sessions",
      "Date & Time": "Date & Time",
      Address: "Address",
      Applicant: "Applicant",
      "Applied Course": "Applied Course",
      "Course Leave Applications": "Course Leave Applications",
      "Course Payment Evidence": "Course Payment Evidence",
      "View evidence": "View Evidence",
      "Event Applications": "Event Applications",
      "Event Payment Evidence": "Event Payment Evidence",
      "No Payment Evidence": "No Payment Evidence",
      "There is no payment evidence pending for review":
        "There is no payment evidence pending for review",
      "No Applications": "No Applications",
      "There is no application pending for approval":
        "There is no application pending for approval",
      "No Leave Applications": "No Leave Applications",
      "There is no leave applications pending for approval":
        "There is no leave applications pending for approval",
      "Hosted by": "Hosted by",
      "Upcoming Session": "Upcoming Session",
      "Your club does not have right to create event yet Please contact us for access":
        "Your club does not have right to create event yet. Please contact us for access",
    },
    LeaguePlayerDivision: {
      "View your recent matches and manage applications for your team":
        "View your recent matches and manage applications for your team",
      "As a home team player, you have to submit the results and pass to away team players to confirm":
        "As a home team player, you have to submit the results and pass to away team players to confirm",
      "As a away team player, you have to acknowledge the results inputted by home team players":
        "As an away team player, you have to acknowledge the results inputted by home team players",
      "Switch to Audience View": "Switch to Audience View",
      "You have joined team in other division, please use audience view to check all fixtures and results in this division":
        "You have joined team in other division, please use audience view to check all fixtures and results in this division",
      "Join your team to view your recent matches and gain access to manage results":
        "Join your team to view your recent matches and gain access to manage results",
    },
    ReviewCoursePaymentEvidence: {
      "Payment evidence": "Payment evidence",
    },
    ManageCourseSessionPlayer: {
      "Move Session": "Move Session",
      "Sessions left": "Sessions left",
      "Manage Player": "Manage Players",
      "Upcoming Session": "Upcoming Session",
      Moved: "Moved",
    },
    NewUpdateAvailable: {
      "We added new features":
        "We added new features and enhanced the app for you. Check it out now",
      "DISCOVER NEW VERSION": "DISCOVER NEW VERSION",
      Update: "Update",
    },
    PreviewCourseSession: {
      Group: "Group",
      "Preview Session": "Preview Session",
      "Is there a fee for the course": "Is there a fee for the course?",
      "Add payment method": "Add payment method",
    },
    AddCourseSession: {
      Removed: "Removed",
      "Tap here to manage players that applied to this removed session":
        "Tap here to manage players that applied to this removed session",
      Edited: "Edited",
      Group: "Group",
      "Add sessions": "Add sessions",
      "Add Session": "Add Session",
      "Confirm to save changes?": "Confirm to save changes?",
      "Leave Page?": "Leave Page?",
      "Changes you made may not be saved": "Changes you made may not be saved.",
      "Saved successfully": "Saved successfully",
      "Remove successfully": "Removed successfully",
      "Confirm to remove session": "Confirm to remove session",
      From: "From",
      To: "To",
      Date: "Date",
      "players applied this session": "players applied this session",
      "All players that applied to this session has been managed, click continue to proceed":
        "All players that applied to this session has been managed, click continue to proceed",
    },
    ChatGPT: {
      ChatPingPong: "ChatPingPong",
      "Select a topic": "Select a topic",
      "ChatPingpong covers different topic related to table tennis, select one of them and explore with ChatPingPong":
        "ChatPingpong covers different topic related to table tennis, select one of them and explore with ChatPingPong.",
    },
    ChatGPTWithSelectedTopic: {
      Select: "Select",
      Topic: "Topic",
      "Start typing": "Start typing ...",
      Youtube: "Youtube",
      "You have reached the maximum limit with ChatPingPong":
        "You have reached the maximum limit with ChatPingPong",
      "Watch our latest video now": "Watch our latest video now!",
      "Look for coach now": "Look for coach now!",
      "Check for availability now": "Check for availability now!",
      "Apply to an event now": "Apply to an event now!",
      "Something usual has happened and it takes longer than expected to load Please enter the question again":
        "Something unusual has happened and it takes longer than expected to load. Please enter the question again",
    },
    OrganizerScreens: {
      LeagueHome: {
        "Tap here to create a new league and division for player to join teams":
          "Tap here to create a new league and division for player to join teams",
        "No league now": "No league now",
        "Please select a league": "Please select a league",
        "Created league": "Created league",
        Logout: "Logout",
        Statistic: "Statistic",
      },
      League: {
        "Basic info": "Basic info",
        "League name": "League name",
        "You can now proceed to add teams into their divisions":
          "You can now proceed to add teams into their divisions",
      },
      ManageDivision: {
        "No match result found for this division":
          "No match result found for this division",
        "Approve results that have been submitted by the home team and acknowledged by the away team":
          "Approve results that have been submitted by the home team and acknowledged by the away team",
        "Upload fixtures from time-to-time to keep the fixtures up-to-date or you can simply click Edit below":
          "Upload fixtures from time-to-time to keep the fixtures up-to-date or you can simply click ‘Edit’ below",
        "Team name": "Team name",
        "Finish editing": "Finish editing",
        "Are you sure to delete this team": "Are you sure to delete this team?",
        "Pending approval": "Pending approval",
        "Warning Deleting the team may result in loss of results":
          "Warning! Deleting the team may result in loss of results!",
        "Deleted successfully": "Deleted successfully",
        "You have successfully delete the team":
          "You have successfully delete the team",
        "Approve Request": "Approve Request",
        "You may click to the team and check all application":
          "You may click to the team and check all application",
        "No fixture now": "No fixture now",
        "Please upload the fixtures through a csv file":
          "Please upload the fixtures through a .csv file",
        "Browse file": "Browse file",
        "You have successfully add teams to the division":
          "You have successfully add teams to the division",
        "You have successfully edit teams to the division":
          "You have successfully edit teams to the division",
      },
      UpdateMatchResult: {
        Other: "Other",
        "Match Result": "Match Result",
        "Basic Info": "Basic Info",
        "Game result": "Game result",
        "Total point": "Total point",
        "Match results are successfully updated":
          "Match results are successfully updated",
        Game: "Game",
        "Match result": "Match Result",
        "Reject reason": "Reject reason",
        "Are you sure to approve the match results":
          "Are you sure to approve the match results?",
        "Points from each match": "Points from each match",
        "Points from no of players": "Points from no of players",
        "Additional points": "Additional points",
        Sets: "Sets",
        "Match results will be posted on Leaderboard":
          "Match results will be posted on Leaderboard.",
        Set: "Set",
        "Are you sure to accept the match results?":
          "Are you sure to accept the match results?",
        "The result will be reviewed by the Organizer":
          "The result will be reviewed by the Organizer",
        "onboarding as a MatchResult": "",
      },
      ResultApproval: {
        ResultApproval: "Result Approval",
        Rejection: "Rejection",
        Agreement: "Agreement",
        "No pending approval": "No pending approval",
        "No rejection": "No rejection",
        "You can approve all the result in one go":
          "You can approve all the result in one go",
        "Back to Result page": "Back to Result page",
        "Are you sure to bulk approve the match results":
          "Are you sure to bulk approve the match results?",
        "Match results will be posted on Leaderboard":
          "Match results will be posted on Leaderboard.",
      },
      TeamRequest: {
        Request: "Request",
        Confirmed: "Confirmed",
        "Reject request": "Reject request",
        "Please provide reason for rejecting":
          "Please provide reason for rejecting",
        "There is no applying request": "There is no applying request",
        "There is no member in this team": "There is no member in this team",
      },
      EditFixture: {
        Venue: "Venue",
      },
    },

    Event: {
      "The event date must be greater than now at least %{number} hour":
        "The event date must be greater than now at least %{number} hour",
      "Is there a fee for the event":
        "	Charge the player who applied for this event?",
      "Yes, add payment method": "Add payment method",
      "No, it is free": "Skip",
      "Deadline for application (Optional)":
        "Deadline for application (Optional)",
      "You can check the created event in Manage tab you can edit delete the event afterward":
        "You can check the created event in Manage tab you can edit delete the event afterward",
      "Latest cancellation period (Optional)":
        "Latest cancellation period (Optional)",
      "Event creating": "Create Event",
      From: "From",
      To: "To",
      "Create another Event": "Create another Event",
      "Manage created event": "Manage created event",
      "Event capacity must be greater than 0 and an integer":
        "Quota must be greater than 0 and an integer",
      "Does it require approval": "Does it require approval?",
      "No permission": "No permission",
      "Competition type": "Competition type",
      hours: "hours",
      hrs: "hrs",
      Deleted: "Deleted",
      "Edit payment method": "Edit payment method",
      Updated: "Updated",
      "Updated successfully": "Updated successfully",
      "Once the event delete participants are not longer to reach this event":
        "Once the event delete participants are not longer to reach this event",
      "Are you sure to delete this event you created":
        "Are you sure to delete this event you created?",
      "Once the event delete, participants are not longer to reach this event":
        "Once the event delete, participants are not longer to reach this event",
      "Yes, delete": "Yes, delete",
      "Create content for this event?": "Create content for this event?",
      "Add Linkage": "Add Linkage",
      Skip: "Skip",
      "Sync with club venue": "Sync with club venue",
      "Club Venue": "Club Venue",
      "No Venue Created": "No Venue Created",
      "There is no venue created under your club Go to venue tab and add a venue":
        "There is no venue created under your club. Go to venue tab and add a venue.",
      OK: "OK",
    },
    AddPaymentMethod: {
      "Payment method": "Payment method",
      "Fee (HKD)": "Fee (HKD)",
      "Are you sure to leave this page without saving":
        "Are you sure to leave this page without saving?",
      "Back to edit event": "Back to edit event",
      "Add Linkage": "Add Linkage",
      Skip: "Skip",
      Bank: "Bank",
      Others: "Others",
      "Create content for this event?": "Create content for this event?",
      "Changes you made may not be saved": "Changes you made may not be saved",
      "Leave page": "Leave page?",
      "Payment Method(s)": "Payment Method(s)",
    },
    PaymentEvidence: {
      "Payment Evidence": "Payment Evidence",
      Reject: "Reject",
      "Please provide reason for rejecting the payment":
        "Please provide reason for rejecting the payment",
      "Approve and mark as paid": "Approve and mark as Paid",
      "Reject and provide reason": "Reject and provide reason",
      Success: "Success",
      Failed: "Failed",
      "No Evidence fetched. Please try again":
        "No Evidence fetched. Please try again",
    },
    AddParticipant: {
      "Add Participant": "Add Player",
      "Player %{number} name": "Player %{number} Name",
      "Team name": "Team name",
      "Player name": "Player Name",
      "Add player": "Add player",
      "Add successfully": "Add successfully",
      "You have successfully add participants to the event":
        "You have successfully add participants to the event",
    },
    AddCoursePlayer: {
      "No Permissions": "No Permissions",
      "Add Participant": "Add Player",
      "Player %{number} name": "Player %{number} Name",
      "Team name": "Team name",
      "Player name": "Player Name",
      "Add player": "Add player",
      "Add successfully": "Add successfully",
      "You have successfully add participants to the event":
        "You have successfully add participants to the event",
      "Start Date": "Start Date",
      "Number of Sessions": "Number of Sessions",
    },
    PaymentStatus: {
      "Payment status": "Payment Status",
      "Check all the evidences and you can kick out player who didnt pay here":
        "Check all the evidences and you can kick out player who didnt pay here.",
      "Review evidence": "Review evidence",
      "Pending approval": "Pending approval",
      Paid: "Paid",
      "Not Paid": "Not paid",
      "No Evidence fetched": "No Evidence fetched. Please try again",
      "There is no payments with status": "There is no payments with status",
      "Check evidence": "Check evidence",
      "Kick out": "Kick out",
      "Review Evidence": "Review Evidence",
      "Kick out player successfully": "Kick out player successfully",
    },
    NotificationCentre: {
      Notification: "Notification",
      "No Notifications": "No Notifications",
      "Mark all as read": "Mark all as read",
    },
    AdminScreens: {
      AdminHome: {
        "Remarkable Sports": "GoPingPong",
        Logout: "Logout",
        refresh: "Refresh",
        firstName: "firstName",
        lastName: "lastName",
        gender: "gender",
        mobile: "mobile",
        hkttaid: "hkttaid",
        "No pending onboarding requests": "No pending onboarding requests",
      },
      AdminClub: {
        "Club Request": "Club Request",
        Refresh: "Refresh",
        ApprovalStatus: "ApprovalStatus",
        "Club Name": "Club Name",
        "Club Address": "Club Address",
        ClubCoaches: "ClubCoaches",
        District: "District",
        CreatedAt: "CreatedAt",
        "No pending onboarding requests": "No pending onboarding requests",
      },
      ApproveSuccess: {
        "Confirmed!": "Confirmed!",
        "Applicant will received the notice now":
          "Applicant will received the notice now.",
        Back: "Back",
      },
      CoachApproval: {
        "Coach details": "Coach details",
        "Reject request": "Reject request",
        "Please provide": "Please provide",
        error: "error",
      },
      ClubApproval: {
        "Club details": "Club Details",
        "Reject request": "Reject request",
        "Please provide": "Please provide",
        error: "error",
      },
      RejectSuccess: {
        "Rejected!": "Rejected!",
        "Applicant will receive the notice now":
          "Applicant will receive the notice now.",
        Back: "Back",
      },
      AdminPermission: {
        Player: "Player",
        Coach: "Coach",
        Clubstaff: "Clubstaff",
        GoPingPong: "GoPingPong",
        Refresh: "Refresh",
        Grant: "Grant",
        Revoke: "Revoke",
        "Are you sure to revoke this permission?":
          "Are you sure to revoke this permission?",
        Successful: "Successful",
        Content: "Content",
        Event: "Event",
      },
    },
    ClubScreens: {
      ChangeCourseSession: {
        "Change Sessions": "Change Sessions",
        "Move to Another Course": "Move to Another Course",
        "New Session Details": "New Session Details",
        "Date of Original Session": "Date of Original Session",
        "Date of Session": "Date of Session",
        "Course Name": "Course Name",
        "No permissions": "No Permissions",
        "Confirm to save changes": "Confirm to move sessions?",
        "No Permissions": "No Permissions",
        FULL: "FULL",
        Original: "Original",
        to: "To",
        New: "New",
      },
      EditCourseSession: {
        "Edit Sessions": "Edit Sessions",
        Change: "Change",
        "Confirm to remove session": "Confirm to remove session?",
      },
      ManageCoachSalary: {
        "Coach Salary": "Coach Salary",
        "Private ($/hr)": "Private ($/hr)",
        "Head Coach ($/hr)": "Head Coach ($/hr)",
        "Assistant Coach ($/hr)": "Assistant Coach ($/hr)",
        "Confirm to save changes": "Confirm to save changes",
        "Price should between $%{min} - $%{max}":
          "Price should be between $%{min} - $%{max}",
      },
      PlayerBreakdown: {
        "Player Breakdown": "Player Breakdown",
        "Player Name": "Player Name",
        PlayerActive: "Active",
        PlayerInactive: "Inactive",
        CourseActive: "Active",
        CoursePast: "Past",
        Expenditure: "Expenditure",
        Status: "Status",
        Unpaid: "Unpaid",
        Past: "Past",
      },
      CoachBreakdown: {
        Coach: "Coach",
        "Working Hours": "Working Hours",
        Salary: "Salary",
        "Club Profit": "Club Profit",
        "Coach Performance": "Coach Performance",
        Individual: "Individual",
        "Sub Total": "Sub Total",
        Month: "Month",
        "Select date": "Select date",
      },
      ManageVenue: {
        "Manage Venue": "Manage Venue",
        Applications: "Applications",
        "There is no application for this venue":
          "There is no application for this venue",
      },
      Calendar: {
        Calendar: "Calendar",
        "Confirm to delete this activity?": "Confirm to delete this activity?",
        "The activity will be deleted permanently in the calendar":
          "The activity will be deleted permanently in the calendar.",
        "Yes, confirm": "Yes, confirm",
        "+ add": "+ add",
        Club: "Club",
        Personal: "Personal",
      },
      ManageEvent: {
        "Please provide reason for rejecting the payment":
          "Please provide reason for rejecting the payment",
        "Approve and mark as paid": "Approve and mark as paid",
        "Reject and provide reason": "Reject and provide reason",
        Price: "Price",
        "Day %{number}": "Day %{number}",
        "Event day": "Event day",
        "There is no event days found": "There is no event days found",
        "Remove participant Success": "Remove participant Success",

        "Select event session": "Select event session",
        "Please provide reason for rejecting the application":
          "Please provide reason for rejecting the application",
        Success: "Success",
        Failed: "Failed",
        "Are you sure to kick out this player":
          "Are you sure to kick out this player?",
        "Select one player to kick out": "Select one player to kick out",
        Register: "Application",
        Payment: "Payment",
        "Roll call": "Roll call",
        "Online application Payment evidence":
          "Online application's Payment evidence",
        Online: "Online",
        Offline: "Offline",
        "Kick out a participant": "Kick out a participant",
        "Add participants": "Add participants",
        "Manage Event": "Manage Event",
        "Expand event details": "Expand event details",
        ApprovedPlayers: "Approved Players",
        "Approved Teams": "Approved Teams",
        "Payment evidence": "Payment evidence",
        "Collapse event details": "Collapse event details",
        "Check all the evidences":
          "Check all the evidences from online application here",
        Check: "Check",
        "Manual change payment status": "Manual change payment status",
        "Not yet paid": "Not yet paid",
        "Pending approval": "Pending approval",
        Paid: "Paid",
        "Payment status": "Payment status",
        Day: "Day",
        "Please select event session": "Please select event session",
        "There is no player applied for this session":
          "There is no player applied for this session",
        "hkd/person": "hkd/session",
        "Update rollcall success": "Update rollcall success",
        Unpaid: "Unpaid",
        free: "free",
        to: "to",
        "Payment receipts": "Payment receipts",
        "View the payment receipts submitted by registered users":
          "View the payment receipts submitted by registered users",
        "Manage Players": "Manage Players",
        "Edit Payment Status": "Edit Payment Status",
        "No Evidence fetched": "No evidence is submitted by this player",
        "Review evidence": "Review evidence",
        "Save Changes": "Save Changes",
      },
      ManageCourse: {
        "Application approved": "Application approved",
        "Application rejected": "Application rejected",
        "Manual add make up session": "Manual add make up session?",
        "You may move this player to another course as a make up session":
          "You may move this player to another course as a make up session",
        "No Access Right": "No Access Right",
        "You do not have the right to access this function":
          "You do not have the right to access this function",
        "students applied leave": "students applied leave",
        absents: "absents",
        Session: "Session",
        Register: "Application",
        "Applied players": "Roll Call",
        "Are you sure to reject the leave request?":
          "Are you sure to reject the leave request?",
        "There is no applications": "There is no applications",
        Accepted: "Accepted",
        Applied: "Applied",
        "Course details": "Course details",
        to: "to",
        "hkd/person": "hkd/session",
        Description: "Description",
        Started: "Started",
        Sessions: "Sessions",
        "Course session": "Course session",
        "Select course": "Select course",
        "Minimum session to apply": "Minimum number of sessions required",
        "Participants at least ": "Participants at least ",
        sessions: "sessions",
        "Collapse course details": "Collapse course details",
        Applicant: "Applicant",
        "Course Detail": "Course Detail",
        hkd: "hkd",
        "Expand course details": "Expand course details",
        "View all registrations": "View sessions",
        "View absents": "View absents",
        Manage: "Manage Course",
        Check: "Check",
        "Session information": "Session information",
        "All leave requests": "All leave requests",
        "Please select course session": "Please select course session",
        "There is no player applied for this session":
          "There is no player applied for this session",
        "There is no course session found": "There is no course session found",
        "Update rollcall success": "Update rollcall success",
        "Update leave request success": "Update leave request success",
        "There is no leave request": "There is no leave request",
        "Manage Sessions": "Manage Sessions",
        "Manage Players": "Manage Players",
        Payment: "Payment",
        "Payment receipts": "Payment receipts",
        "View the payment receipts submitted by registered users":
          "View the payment receipts submitted by registered users",
        "Save Changes": "Save Changes",
        "Edit Payment Status": "Edit Payment Status",
        Paid: "Paid",
        Unpaid: "Unpaid",
        "Payment status": "Payment status",
        "Not Paid": "Not Paid",
        "No Evidence fetched. Please try again":
          "No Evidence fetched, Please try again",
        "Review evidence": "Review evidence",
        "Approve and mark as paid": "Approve and mark as paid",
        "Reject and provide reason": "Reject and provide reason",
        "Please provide reason for rejecting the payment":
          "Please provide reason for rejecting the payment",
      },
      VenueDetails: {
        "Venue details": "Venue details",
        Date: "Date",
        Time: "Time",
        "Please select at least 1 time slot":
          "Please select at least 1 time slot",
        "hkd/hour": "hkd/hour",
        Description: "Description",
        "Phone Number": "Phone Number",
        "Number of tables": "Number of tables",
        "Opening Time From To": "From :from to :to",
      },
      ClubUpdateProfile: {
        "Update Profile": "Update Profile",
        "Club name": "Club name",
        "Update profile success": "Update profile success",
      },
      VenueList: {
        "Venue list": "Venue list",
        Created: "Created",
        Happening: "Happening",
        "No venue": "No venue",
        "There is a problem delete your venue":
          "There is a problem when deleting your venue",
        "Your venue is deleted": "Your venue is deleted",
        "Yes, delete": "Yes, delete",
        "Are you sure to delete this venue you created?":
          "Are you sure to delete this venue you created?",
        "Once the venue delete participants are not longer to reach this venue":
          "Once the venue delete participants are not longer to reach this venue",
        "No Venue": "No Venue",
        "Create Venue": "Create Venue",
        "Tap here to create a new venue": "Tap here to create a new venue",
      },
      // Add Club & Update Club
      Club: {
        "Club Creating": "Club Creating",
        Created: "Created",
        "Submitted successfully": "Submitted successfully",
        Updated: "Updated",
        Deleted: "Deleted",
        "Club Updating": "Edit Club",
        "Club Name": "Club Name",
        "Club Profile Image": "Club Profile Image",
      },
      // Add Venue & Update Venue
      Venue: {
        "Phone number must be an 8-digit number":
          "Phone number must be an 8-digit number",
        "Enquiry Phone no": "Enquiry Phone No.",
        "Opening hours": "Opening hours",
        From: "From",
        To: "To",
        "Venue creating": "Venue creating",
        "Basic info": "Basic info",
        "Venue name": "Venue Name",
        "Venue district": "District",
        "Venue address": "Address",
        "Number of tables": "Number of tables",
        "Rate ($/hr)": "Rate ($/hr)",
        "Ball provided?": "Ball provided?",
        "Files smaller than 5mb": "File smaller than 5mb",
        "Same time every day": "Same time every day",
        AddVenue: "Create Venue",
        UpdateVenue: "Edit Venue",
        "Venue description": "Venue description",
        "Venue photo": "Venue photo",
        "Cancellation Period": "Cancellation Period",
        "For confirmed bookings": "For confirmed bookings",
        "Venue Area": "Area",
        "All areas": "All areas",
        "All districts": "All districts",
        "Venue Image": "Venue Image",
      },
      AddVenueSuccess: {
        "Created a venue": "Created a venue",
        Back: "Back",
      },
      UpdateVenueSuccess: {
        "Updated a venue": "Updated a venue",
        Back: "Back",
      },
      ApproveSuccessRequestVenue: {
        Approved: "Approved",
        "Insert VenueCard Here": "Insert VenueCard Here",
        Back: "Back",
      },
      RejectedVenueBooking: {
        Rejected: "Rejected",
        "Applicant will recieved the notice now":
          "Applicant will recieved the notice now.",
        Back: "Back",
      },
      ClubApprovedCourseBooking: {
        Approved: "Approved",
        "Applicant will received the notice now":
          "Applicant will received the notice now.",
        Back: "Back",
      },
      ClubRejectedCourseBooking: {
        Rejected: "Rejected",
        "Applicant will recieved the notice now":
          "Applicant will recieved the notice now.",
        Back: "Back",
      },
      ClubCourseBookingDetails: {
        "Request Course": "Request Course",
        Applicant: "Applicant",
        "Course Detail": "Course Detail",
        Description: "Description",
        hkd: "hkd",
      },
      ClubRequestVenueDetails: {},
      VenueBookingDetails: {
        "Request Venue": "Venue Application",
        Application: "Application",
        "View Profile": "View Profile",
        "Applied Venue": "Applied Venue",
        "Venue Photo": "Venue Photo",
        "Selected date": "Selected date",
        "Selected time": "Selected time",
        "Venue booking is approved": "Venue booking is approved",
      },
      // Add Course & Update Course
      Course: {
        "Assigned Player(s)": "Assigned Player(s)",
        Player: "Player",
        Type: "Type",
        Private: "Private",
        Public: "Public",
        "Course photo": "Upload Image",
        "Files smaller than 5mb": "Files smaller than 5mb",
        "Course name": "Course name",
        "Course type": "Course Type",
        "Course capacity": "Quota",
        "Course price": "Price per session (HKD)",
        "Course time": "Course time",
        "Course duration": "Course date",
        "Course level": "Course level",
        "Course district": "Course district",
        "Phone number": "Phone number",
        "Course creating": "Course creating",
        "Basic info": "Basic info",
        "Course price (HKD)": "Price per session (HKD)",
        "Level required": "Level required",
        "Course address": "Course address",
        "Enquiry phone no": "Enquiry phone no.",
        Mobile: "Mobile",
        "Phone Number be 8-digit number": "Phone Number be 8-digit number",
        "Create successful": "Create successful!",
        "You can check the created event in Manage tab you can edit delete the event afterward":
          "You can check the created event in Manage tab, you can edit, delete the event afterward.",
        "Create another course": "Create another course",
        "Manage create course": "Manage created courses",
        Level: "Level",
        "Select Level": "Select Level",
        "Course price must be greater than 0 and an integer":
          "Course price must be greater than 0 and an integer",
        "Course capacity must be greater than 0 and an integer":
          "Quota must be greater than 0 and an integer",
        "Minimum session to apply": "Minimum number of sessions required",
        "Minimum session to apply must be greater than 0 and an integer":
          "Minimum session to apply must be greater than 0 and an integer",
        "Update Course": "Update Course",
        "Are you sure to delete this course you created?":
          "Are you sure to delete this course you created?",
        "Once the course delete, participants are not longer to reach this course":
          "Once the course delete, participants are not longer to reach this course.",
        "Yes, delete": "Yes, delete",
        Deleted: "Deleted",
        "UpdateCourse successful": "Update Course Successful",
        "You have successfully edit the course details You can check it in the details page":
          "You have successfully edit the course details. You can check it in the details page.",
        Saved: "Saved!",
        "Edit payment method": "Edit payment method",
        "Sync with club venue": "Sync with club venue",
        "Club Venue": "Club Venue",
        "No Venue Created": "No Venue Created",
        "There is no venue created under your club Go to venue tab and add a venue":
          "There is no venue created under your club. Go to venue tab and add a venue.",
        OK: "OK",
      },
      Home: {
        "Cancel successfuly": "Cancel successfully",
        "Delete successfuly": "Delete successfully",
        "Confirm to delete club?": "Confirm to delete club?",
        "Confirm to cancel join request?": "Confirm to cancel join request?",
        "Talk with \nChatPingPong": "Talk with \nChatPingPong",
        Date: "Date",
        Time: "Time",
        to: "to",
        Manage: "Manage",
        Applications: "Applications",
        "Course Name": "Course Name",
        "Start Date": "Start Date",
        Sessions: "Sessions",
        "Course Details": "Course Details",
        "Latest Content": "Latest Content",
        "There is no content": "There is no content",
        "Remarkable Sports": "GoPingPong",
        Requests: "Requests",
        "View all": "View all",
        "Create Venue": "Create Venue",
        "Create Course": "Create Course",
        "Tap here to create course": "Tap here to create course",
        "Tap here to create venue": "Tap here to create venue",
        "Tap here to create courses, events and games":
          "Tap here to create courses, events and games",
        Course: "Course",
        Venue: "Venue",
        "There is no pending course request":
          "There is no pending course request",
        "There is no pending venue request":
          "There is no pending venue request",
        "Join Club": "Join Club",
        "Create Club": "Create Club",
        Pending: "Pending",
        "My club": "My club",
        Game: "Game",
        Event: "Event",
        "I want to create": "I want to create",
        "Courses, events and venues": "Courses, Events and Venues",
        "Venue booking is approved": "Venue booking is approved",
        "Check for availability now": "Check for availability now!",
      },
      Manage: {
        "Remarkable Sports": "GoPingPong",
        Request: "Request",
        Course: "Course",
        Venue: "Venue",
        Event: "Event",
        "Course List": "Course List",
        "Venue List": "Venue List",
        "Tap here to create a new event and have fun with other player":
          "Tap here to create a new event and have fun with other player.",
        "Create Course": "Create Course",
        "Venue booking is approved": "Venue booking is approved",
      },
      CourseList: {
        Finished: "Finished",
        Created: "Created",
        CourseList: "Course",
        "Yes, delete": "Yes, delete",
        "Are you sure to delete this course you created?":
          "Are you sure to delete this course you created?",
        "Once the course delete participants are not longer to reach this course":
          "Once the course delete, participants are not longer to reach this course.",
        "You successfully deleted the course":
          "You successfully deleted the course.",
        "Tap here to create a new event and have fun with other player":
          "Tap here to create a new event and have fun with other player.",
        "Create Course": "Create Course",
        Deleted: "Deleted!",
        "Roll Call": "Roll Call",
        "Data Request Error": "Data Request Error",
      },
      ClubProcess: {
        "Manage Salary": "Manage Salary",
        "Manage Club": "Manage Club",
        "Yes,Approve": "Yes, Approve",
        "Are you sure to Approve this apply?":
          "Are you sure to approve this application?",
        "Approve Successful": "Approve Successful",
        "Reject Successful": "Reject Successful",
        "Are you sure to Reject this apply?":
          "Are you sure to Reject this application?",
        "Yes,Reject": "Yes, Reject",
        Coach: "Coach",
        Staff: "Staff",
        Player: "Player",
        Applications: "Applications",
        Existing: "Existing",
        "Confirm to reject application?": "Confirm to reject application?",
        "Confirm to approve application?": "Confirm to approve\napplication?",
        "Coached for": "Coached for",
        years: "years",
        Admin: "Admin",
        "Confirm to remove coach?": "Confirm to remove coach?",
        "Confirm to remove staff?": "Confirm to remove staff?",
        "Remove Successful": "Remove Successful",
        Payment: "Payment",
        "New Payment Method": "New Payment Method",
        Bank: "Bank",
        "Bank Name": "Bank Name",
        "Account Number": "Account Number",
        "Account Name": "Account Name",
        "Phone Number": "Phone Number",
        ID: "ID",
        "Confirm to remove \n%{paymentType}":
          "Confirm to remove? \n%{paymentType}",
        "Removed successfully": "Removed successfully",
      },
      ClubAddPaymentMethod: {
        "Add Payment Method": "Add Payment Method",
        "Method Type": "Method Type",
        "Please select 1 information to show":
          "Please select 1 information to show",
        "Both of them": "Both of them",
        Identifier: "Identifier",
        "Phone number": "Phone number",
        "Account name": "Account name",
        Bank: "Bank",
        Others: "Others",
        FPS: "FPS",
        Payme: "Payme",
        "Bank account": "Bank account",
        Name: "Name",
        "Free text": "Free text",
        "Select payment type": "Select payment type",
        "Added New Payment Method\n%{paymentType}":
          "Added New Payment Method\n%{paymentType}",
        "Edit Payment Method": "Edit Payment Method",
        "Saved successfully": "Saved successfully",
      },
      ClubReportScreen: {
        Report: "Report",
        "Monthly Summary": "Monthly\nSummary",
        "Coach Performance": "Coach\nPerformance",
        "Player Breakdown": "Player\nBreakdown",
        "Course Performance": "Course\nPerformance",
        "No permissions": "No permissions",
      },
      ClubMonthlySummary: {
        "Monthly Summary": "Monthly Summary",
        Month: "Month",
        Income: "Income",
        Expenditure: "Expenditure",
        Profit: "Profit",
      },
      ClubCoursePerformance: {
        "Course Performance": "Course Performance",
        Course: "Course",
        Cost: "Cost",
        Income: "Income",
        Expenditure: "Expenditure",
        Profit: "Profit",
        Status: "Status",
        Active: "Active",
        Past: "Past",
        "Player Name": "Player Name",
        "Attended Sessions": "Attended Sessions",
        "Remaining Sessions": "Remaining Sessions",
      },
    },
    CoachScreens: {
      O3RequestDetails: {
        "Request details": "Request details",
      },
      CoachUpdateProfile: {
        "Submit for Admin Approval": "Submit for Admin Approval",
        "You will not be able to edit your profile until the submitted changes approved by our admin":
          "You will not be able to edit your profile until the submitted changes approved by our admin",
        "Your previous update is pending for approval":
          "Your previous update is pending for approval",
        "Wait for approval": "Wait for approval",
        OK: "OK",
        "When you start": "When you start",
        "When you start as a player": "Year as player",
        "When you start as a coach": "Year as coach",
        "Start Year": "Start Year",
      },
      Home: {
        "Talk with \nChatPingPong": "Talk with \nChatPingPong",
        "There is no related meetup": "There is no related meetup",
        "On-going": "On-going",
        "to-be-confirmed": "to-be-confirmed",
        Requests: "Requests",
        "View all": "View all",
        "Remarkable Sports": "GoPingPong",
        "Courses and events": "Courses and events",
        "There is no requests found": "There is no requests found",
        Course: "Course",
        Game: "Game",
        Event: "Event",
        "I want to create": "I want to create",
        "Latest Content": "Latest Content",
        "There is no content": "There is no content",
        Other: "Other",
        Recommended: "Recommended",
        View: "View",
        "Recommended by AI": "Recommended by AI",
      },
      O3ApplyRequest: {
        "Applied request": "Applied request",
        "You have successfully applied the request":
          "You have successfully applied the request",
        "Request accepted": "Request accepted",
        "You have successfully accept the request":
          "You have successfully accept the request",
        "Confirm to accept request": "Confirm to accept request?",
        "Request rejected": "Request rejected",
        "You have rejected the request": "You have rejected the request",
        "Confirm to reject request": "Confirm to reject request?",
        HK$: "HK$",
        "No Rank": "No Ranking",
        Information: "Information",
        "View profile": "View Profile",
        "Looking for a venue": "Looking for a venue?",
        "Explore and check availability of venue now":
          "Explore and check availability of venue now",
        "Request details": "Request details",
        Applicant: "Applicant",
        "Provide meet up details": "Provide meet up details",
        Address: "Address",
        Rate: "Rate",
        "Rate must be positive number": "Rate must be positive number",
        "Rate must with in user's request $%{min} to $%{max}":
          "Rate must with in user's request $%{min} to $%{max}",
        "Do you have a venue?": "Do you have a venue?",
        "System will requests player to book a venue if you don't have one":
          "System will requests player to book a venue if you don't have one",
        Successful: "Successful",
        "the meet up details already sent to your applicant":
          "the meet up details already sent to your applicant",
        OK: "OK",
        Hide: "Hide",
        Failure: "Failure",
        "There is an error for your request":
          "There is an error for your request",
        "Successfully hide player": "Successfully hide player",
        "Error hide player": "Error hide player",
      },
      RequestList: {
        "Meetup Records": "Meetup Records",
        Requesting: "Requesting",
        Confirmed: "Confirmed",
        Applying: "Applying",
        Recent: "Recent",
        Contacts: "Contacts",
        Applied: "Applied",
        Finished: "Finished",
      },
      O3Details: {
        "Request details": "Request details",
        "Provide meet up details": "Provide meet up details",
        "Cancel meetup": "Cancel meetup",
        "Are you sure to cancel the meetup":
          "Are you sure to cancel the meetup",
        "There is an error canceling meetup":
          "There is an error cancelling the meetup",
        Error: "Error",
        OK: "OK",
      },
      ContanctsDetails: {
        "Meetup with": "Meetup with",
        "Meetup Record": "Meetup Records",
      },
    },
    ForgotPassword: {
      ForgotPassword: {
        "Forgot Password": "Forgot password",
        Email: "Email",
        "Send reset password email": "Send reset password email",
        ResetSuccess: "A reset password email has ben sent. Please check.",
        "There is an error resetting your password":
          "There is an error resetting your password",
        Error: "Error",
        Success: "Success",
        OK: "OK",
      },
    },
    ChangePassword: {
      "Change password": "Change password",
      "Please consider the rules for your new password":
        "Your password must use:",
      "at least 1 Uppercase, 1 Lowercase, 1 number, at least 8 characters":
        "both upper and lower case characters, numbers, at least 8 characters",
      "Confirm password": "Confirm password",
      "New password": "New password",
      "Current password": "Current password",
      "Password changed": "Password changed!",
      "The Password and Confirm Password do not match":
        "The Password and Confirm Password do not match.",
    },
    ChangeEmail: {
      "Change Email": "Change Email",
      "Current Email Address:": "Current Email Address:",
      "New Email Address:": "New Email Address:",
      "Email address": "Email address",
      "Confirm Email address": "Confirm Email address",
      "Confirm to change email": "Confirm to change email?",
      "Email Changed": "Email Changed",
    },
    JoinClubScreen: {
      "Club List": "Club List",
      "Are you sure to join this club?": "Confirm to join this club?",
      "Successful application": "Successful application",
      Join: "Join",
      "Yes, Join": "Confirm",
      "join this club": "join this club",
      "There is no Club": "There is no Club",
      Search: "Search",
      "No result found": "No result found",
      "We cannot find any club matching your search yet":
        "We cannot find any club matching your search yet",
    },
    LeagueScreen: {
      "Remarkable Sports": "GoPingPong",
      "Select division > choose team > click apply":
        "Select division > choose team > click\napply",
      "Join the team now": "Join the team now",
      "Please select League": "Please select League",
      "No data": "No data",
      Statistic: "Statistic",
      "Select Division": "Select Division",
      "Please select a division": "Please select a division",
      leaderboard: "leaderboard",
      Filter: "Filter",
      Season: "Season",
    },
    DivisionLeaderboard: {
      Statistic: "Statistics",
      "Please select division": "Please select division",
      division: "division",
      "No data": "No data",
      Team: "Team ",
      Individual: "Individual",
      P: "P",
      W: "W",
      "%": "%",
      Pts: "Pts",
      Player: "Player",
      L: "L",
      D: "D",
    },
    IndividualStatistic: {
      "Individual Match Record": "Individual Match Record",
      Player: "Player",
      P: "P",
      W: "W",
      L: "L",
      D: "D",
      "%": "%",
      Pts: "Pts",
      "No Data": "No Data",
    },
    TeamStatistic: {
      WIN: "WIN",
      LOSE: "LOSE",
      DRAW: "DRAW",
      "Points from each match": "Points from each match",
      "Points from no of players": "Points from no of players",
      "Additional points": "Additional points",
      set: "set",
      "Team Results Table": "Team Results Table",
      Player: "Player",
      P: "P",
      W: "W",
      "%": "%",
    },
    SubmitMatchResult: {
      "Basic Info": "Basic Info",
      "Game Result": "Game Result",
      "Match result": "Match Result",
      Game: "Game",
      "Team name": "Team name",
      "Total point": "Total point",
      "Your opponent will verify the results":
        "Your opponent will verify the results",
      "Remember to submit after you update the results later":
        "Remember to submit after you update the results later",
      "Saved Successfully": "Saved Successfully",
      "Submitted Successfully": "Submitted Successfully",
      "Please select a game": "Please select a game",
      "Are you sure to submit the match result?":
        "Are you sure to submit the match result?",
      "Please select 10 players on the basic info page":
        "Please select 10 players on the basic info page",
      Other: "Other",
    },
    DivisionScreen: {
      "All round": "All round",
      Filter: "Filter",
      "There is no Team": "There is no Team",
      Manage: "Manage",
      "Cancel request": "Cancel request",
      "Approve request": "Approve request",
      "You may click to your team and check all application":
        "You may click to your team and check all application",
      "My team": "My team",
      "There is no Fixture": "There is no Fixture",
      "There is no Result": "There is no Result",
      "%{homeTeamName} has submitted the results, please verify the results as soon as possible":
        "%{homeTeamName} has submitted the results, please verify the results as soon as possible.",
      "Pending Approval": "Pending Approval",
      "Match Result": "Match Result",
    },
    LeagueFiltering: {
      Search: "Search",
      "DD-MM-YYYY": "DD-MM-YYYY",
    },
    FilterFixtureResult: {
      "Filter Result": "Filter Result",
    },
    PendingApproval: {
      "Pending Approval": "Pending Approval",
    },
    ManageTeam: {
      Request: "Request",
      "Confirmed list": "Confirmed list",
      "My team request": "My team request",
      "There is no Player": "There is no Player",
      Successfully: "Successfully",
      "Approve Successfully": "Approve Successfully",
      "Reject Successfully": "Reject Successfully",
      "You have successfully remove player":
        "You have successfully remove player",
      "Please provide reason for rejecting the application":
        "Please provide reason for rejecting the application",
      "Are you sure to remove %{playerName}?":
        "Are you sure to remove %{playerName}?",
    },
    ManageEventPlayer: {
      "Manage Player": "Manage Player",
      Existing: "Existing",
      Rejected: "Rejected",
      Remove: "Remove",
      "Add Player": "Add Player",
      "Confirm to remove “%{name}”?": "Confirm to remove “%{name}”?",
      "You have successfully kick participants out to this event":
        "You have successfully kick participants out to this event",
      "Kick out successfully": "Kick out successfully",
      "Select one player to kick out": "Select one player to kick out",
      "Player details": "Player details",
    },
    ManageCoursePlayer: {
      "Edit Sessions": "View Sessions",
      "Manage Player": "Manage Player",
      Existing: "Existing",
      Rejected: "Rejected",
      Remove: "Remove",
      "Add Player": "Add Player",
      "Confirm to remove “%{name}”?": "Confirm to remove “%{name}”?",
      "You have successfully kick participants out to this event":
        "You have successfully kick participants out to this event",
      "Kick out successfully": "Kick out successfully",
      "Select one player to kick out": "Select one player to kick out",
    },
    LogIn: {
      "Don’t have an account? Register Now":
        "Don’t have an account? Register Now",
      Login: "Login",
      Password: "Password",
      Email: "Email",
      "Professional table tennis training\ncourses throughout Hong Kong":
        "Professional table tennis training\ncourses throughout Hong Kong",
      "Dream bigger": "Dream bigger",
      "Unleash\nyour potential": "Unleash\nyour potential",
      "Invalid email and/or password": "Invalid email and/or password",
      "There is an error logging user in Please try again":
        "There is an error logging user in. Please try again",
      "Forgot your password?": "Forgot your password?",
      "Biometric Login": "Biometric Login",
      'You need to add biometric login in "Settings" of the local system before you can use this function':
        'You need to add biometric login in "Settings" of the local system before you can use this function',
    },
    MainScreens: {},
    MeetCoach: {},
    PlayerScreens: {
      PlayerPendingRequests: {
        "Pending Requests": "Pending Requests",
      },
      PlayerMeetupRecords: {
        "There is no related meetup": "There is no related meetup",
        All: "All",
        Completed: "Completed",
        Upcoming: "Upcoming",
        Cancelled: "Cancelled",
        Rejected: "Rejected",
        "Meetup Records": "Meetup Records",
      },
      PlayerUpdateProfile: {
        "When you start": "When you start",
        "When you start as a player": "Year as player",
        "When you start as a coach": "Year as coach",
        "Start Year": "Start Year",
      },
      Calendar: {
        Calendar: "Calendar",
        "Confirm to delete this activity?": "Confirm to delete this activity?",
        "The activity will be deleted permanently in the calendar":
          "The activity will be deleted permanently in the calendar.",
        "Yes, confirm": "Yes, confirm",
        "+ add": "+ add",
      },
      CalendarAddActivity: {},
      CourseApplySuccess: {
        "Successful Applied": "Successful\n Applied",
        OK: "OK",
        "Applied details": "Applied details",
        "Upcomming session": "Upcomming session",
        "Number of applied sessions": "Number of applied sessions",
      },
      AskForLeave: {
        "Ask for leave": "Ask for leave",
        "Select absent session": "Select absent session",
        "Make up session": "Make up session",
        "No make up sessions available": "No make up sessions available",
        "Apply leave success": "Apply leave success",
        Session: "Session",
      },
      CourseDetails: {
        "Payment evidence": "Payment evidence",
        "Please submit the payment evidence":
          "Please submit the payment evidence",
        "Submit now": "Submit now",
        HK$: "HK$",
        "Date & Time": "Date & Time",
        "Date of First Session": "Date of First Session",
        "Number of Sessions": "Number of Sessions",
        "Need to apply for at least ": "Need to apply for at least ",
        "View all sessions": "View all sessions",
        Session: "Session",
        Total: "Total",
        Accepted: "Accepted",
        Applied: "Applied",
        "Course details": "Course details",
        to: "to",
        "hkd/person": "hkd/session",
        Description: "Description",
        "Ask for leave": "Ask for leave",
        Started: "Started",
        Sessions: "Sessions",
        "Course session": "Course session",
        "Select sessions": "Select sessions",
        "Minimum session to apply": "Minimum number of sessions required",
        "Participants at least ": "Participants at least ",
        sessions: "sessions",
        End: "End",
        "Next session": "Next session",
        "Remained sessions": "Remained sessions",
        "View Sessions": "View Sessions",
        PayPal: "Pay with PayPal",
      },
      CourseRejectSuccess: {
        "Successful Rejected": "Successfully\n Rejected",
        OK: "OK",
        "Reject details": "Reject details",
      },
      CourseFiltering: {
        "All areas": "All areas",
        "All districts": "All districts",
        "All level": "All level",
        "Filter course": "Filter course",
        Location: "Address",
        "Club Name": "Club Name",
        From: "From",
        To: "To",
        HKD: "HKD",
        Level: "Level",
        "Select level": "Select level",
        "Min price (HKD)": "Min price (HKD)",
        "Min price must be less than Max price and an integer":
          "Min price must be less than Max price and an integer",
        "Max price (HKD)": "Max price (HKD)",
        "Max price must be greater than Min price and an integer":
          "Max price must be greater than Min price and an integer",
        "Day of week": "Day of week",
      },
      CourseList: {
        "All available courses": "All Available Courses",
        "Recommended Course": "Recommended Course",
        "No course available": "No course available",
        "Please search again": "Please search again",
        Cost: "Cost",
        Level: "Level",
        Search: "Search",
        From: "From",
        To: "To",
        Applied: "Applied",
        Confirmed: "Confirmed",
        Finished: "Finished",
        "No recommended course": "No recommended course",
        "View all": "View all",
        Collapse: "Collapse",
        "No result found": "No result found",
        "We cannot find any course matching your search yet":
          "We cannot find any course matching your search yet",
        "There is no %{type} course": "There is no %{type} course",
        "No course recommended": "No course recommended",
      },
      O3AppliedCoach: {
        "Pending Direct Request": "Pending Direct Request",
        "Pending Requests": "Pending Requests",
        "View Status": "View Status",
        "Meet coach": "Find Coach",
        "Applied coach": "Applied coach",
        "On-going Coaching Request": "On-going Coaching Request",
        "Matched Coaching": "Matched Coaching",
        "No coach applied yet": "No coach applied yet",
        "Please wait for a moment": "Please wait for a moment.",
        "hkd/hr": "hkd/hr",
        "Venue is missing": "Venue is missing",
        "Are you sure to cancel the meetup":
          "Are you sure to cancel the meetup",
        "Recommended Coaches": "Recommended Coaches",
        Search: "Search",
        "Look for the right coach to meet up":
          "Look for the right coach to meet up",
        Recommendation: "Recommendation",
        Favourite: "Favourite",
        "Coached for": "Coached for",
        years: "years",
        "No favourite coach yet": "No favourite coach yet",
        "You can bookmark your favourite coaches":
          "You can bookmark your favourite coaches.",
        Rank: "Rank",
      },
      O3SubmitRequest: {
        Submited: "Submited",
        "You have submited a meetup request":
          "You have submited a meetup request",
        "Find coaches": "Send Request",
        Location: "Location",
        "Meetup date": "Meetup date",
        Time: "Time",
        From: "From",
        To: "To",
        HKD: "HKD",
        Price: "Price",
        "Do vou have a venue?": "Do you have a venue?",
        "Provide venue address here": "Provide venue address here",
        "Suggest you to book the venue beforehead You ultimatelv need to do so if the coach did book the venue neither":
          "Suggest you to book the venue beforehead. You ultimately need to do so if the coach did book the venue neither.",
        "Date should not be in the past": "Invalid start date",
        "from time should not be in the past": "invalid start time",
        "Min price (HKD)": "Min price (HKD)",
        "Min price must be less than Max price and an integer":
          "Min price must be less than Max price and an integer",
        "Max price (HKD)": "Max price (HKD)",
        "Max price must be greater than Min price and an integer":
          "Max price must be greater than Min price and an integer",
      },
      O3AppliedCoachDetails: {
        "Your meetup is ready": "Your meetup is ready",
        "Meet up details": "Meetup Details",
        Hide: "Hide",
        "There is an error hiding the coach":
          "There is an error hiding the coach",
        "Successfully hide coach": "Successfully hide coach",
        "Error hide coach": "Error hide coach",
        Feedback: "Feedback",
        "Leave your feedback": "Leave your feedback",
        "Please leave your feed back here": "Please leave your feedback here",
        "Show ?": "Show ?",
        "Provide feedback successfully": "Provide feedback successfully",
        "Feedback from player": "Feedback from player",
        "Feedback from coach": "Feedback from coach",
        Cancel: "Cancel Meetup",
        "Delete success": "Delete success",
        "Are you sure to cancel the meetup":
          "Are you sure to cancel the meetup",
        "There is an error canceling meetup":
          "There is an error cancelling meetup",
        Error: "Error",
        OK: "OK",
      },
      Home: {
        "Talk with \nChatPingPong": "Talk with \nChatPingPong",
        "Remarkable Sports": "GoPingPong",
        "On-going": "On-going",
        "To-be-confirmed": "To-be-confirmed",
        Course: "Course",
        "View all": "View all",
        Requests: "Requests",
        "hkd/hr": "hkd/hr",
        "Venue is missing": "Venue is missing",
        "Latest Content": "Latest Content",
        "There is no content": "There is no content",
        "Check for availability now": "Check for availability now",
        "Looking for the right coach": "Looking for the right coach?",
      },
      Profile: {},
      VenueApplySuccess: {
        "Successful Applied": "Successful\nApplied",
        OK: "OK",
        "Applied details": "Applied details",
        "Selected date": "Selected date",
        "Selected time": "Selected time",
        "hkd/hour": "hkd/hour",
      },
      VenueDetails: {
        "Venue details": "Venue details",
        "Please select at least 1 time slot":
          "Please select at least 1 time slot",
        "hkd/hour": "hkd/hour",
      },
      VenueBookingDetails: {
        "Request Venue": "Venue Application",
        "Delete success": "Delete success",
      },
      VenueFiltering: {
        "Filter venue": "Filter venue",
        Location: "Location",
        "Venue date": "Venue date",
        "All areas": "All areas",
        "All districts": "All districts",
        From: "From",
        To: "To",
        HKD: "HKD",
        "Min price (HKD)": "Min price (HKD)",
        "Min price must be less than Max price and an integer":
          "Min price must be less than Max price and an integer",
        "Max price (HKD)": "Max price (HKD)",
        "Max price must be greater than Min price and an integer":
          "Max price must be greater than Min price and an integer",
      },
      VenueItemCard: {
        hkd: "hkd",
      },
      VenueList: {
        "Recommended Venue": "Recommended Venue",
        Search: "Search",
        "Available Venues": "Available Venues",
        "No venue available": "No venue available",
        "Please search again": "Please search again",
        Cost: "Cost",
        hkd: "hkd",
        From: "From",
        To: "To",
      },
      MeetupRecords: {
        "Meetup Records": "Meetup Records",
        All: "All",
        Coaching: "Coaching",
        Course: "Course",
        Venue: "Venue",
        Recent: "Recent",
      },
      EventDetails: {
        "Event details": "Event details",
        days: "Days",
        Day: "Day",
        "Day %{number}": "Day %{number}",
        "hkd/person": "hkd/person",
        "Opening remain": "Opening remain",
        "See participant list": "See participant list",
        "Easy apply %{type} competition NOW!":
          "Easy apply %{type} competition NOW!",
        "Player name": "Player name",
        "Team name": "Team name",
        Joiner: "Joiner",
        name: "name",
        "Event is full": "Event is full",
        "You can still apply to be waiting list":
          "You can still apply to be waiting list",
        "Submit now": "Submit now",
        "You can add up to 10 members": "You can add up to 10 members",
        "After quick apply organiser will approve your application shortly Then you have to submit payment after Final confirmation will be done after organiser approve your payment evidence":
          "After quick apply, organiser will approve your application shortly.Then, you have to submit payment after. Final confirmation will be done after organiser approve your payment evidence.",
        "Payment evidence": "Payment evidence",
        "Please submit the payment evidence":
          "Please submit the payment evidence",
        "Are you sure to cancel joining this event?":
          "Are you sure to cancel joining this event?",
        "Cancel successfully": "Cancel successfully",
        "You have successfully cancel joining this event":
          "You have successfully cancel joining this event.",
        free: "free",
        "Add Player": "Add Player",
        "Remove Player": "Remove Player",
      },
      JoinEventSuccess: {
        "Join successful!": "Join successful!",
        "Join another event": "Join another event",
        "view join event": "view join event",
        "Please upload the payment evidence once your application is approved":
          "Please upload the payment evidence once your application is approved",
      },
      PaymentEvidence: {
        Total: "Total",
        "Payment evidence": "Payment evidence",
        "Add payment evidence": "Add payment evidence",
        "Files smaller than 5mb": "Files smaller than 5mb",
        "Payment method": "Payment method",
        "Fast payment identification code": "Fast payment identification code",
        "Submit successfully": "Submit successfully",
        "You have successfully paid this the event":
          "You have successfully paid this event",
        "You have successfully paid this the course":
          "You have successfully uploaded payment evidence for the course",
      },
      SearchCourse: {
        "Search Course": "Search Course",
        "No result found": "No result found",
        "We cannot find any course matching your search yet":
          "We cannot find any course matching your search yet",
      },
    },
    ContentScreen: {
      Content: "Content",
      "Create Content": "Create Content",
      "Create a new content to encourage player to join your activity":
        "Create a new content to encourage player to join your activity",
      Created: "Created",
      Expired: "Expired",
      "Other content": "Other content",
      "No record now": "No Content",
      "View all": "View all",
      "No content yet": "No content yet",
      "Please wait for a moment": "Please wait for a moment.",
      "Event Deleted": "Event Deleted",
    },
    // Add Content & Update Content
    Content: {
      Created: "Created",
      "Created  successfully": "Created successfully",
      "Create content": "Create content",
      Title: "Title",
      Description: "Description",
      "Content photo (Optional)": "Content photo (Optional)",
      "Files smaller than 5mb": "Files smaller than 5mb",
      "Content photo": "Content Photo",
      "Content Name": "Content Name",
      "From Date": "From Date",
      "End Date": "End Date",
      "Post duration (DD-MM-YYYY)(Optional)":
        "Post duration (DD-MM-YYYY)(Optional)",
      From: "From",
      To: "To",
      "from date must be earlier than the to date":
        "from date must be earlier than the to date",
      Saved: "Saved",
      "Saved  successfully": "Saved successfully",
      "Edit content": "Edit content",
      "Are you sure to delete this content you created?":
        "Are you sure to delete this content you created?",
      "Once the content delete participants are not longer to reach this content":
        "Once the content delete, participants are not longer to reach this content.",
      "Yes, delete": "Yes, delete",
      Deleted: "Deleted",
      "Delete  successfully": "Delete  successfully",
      "Video Link": "Video Link",
      Name: "Name",
      "(Optional)": "(Optional)",
      Image: "Image",
      "Only support YouTube URL": "Only support YouTube URL",
      "Image must be less than 5MB": "Image must be less than 5MB",
      "Add Image": "Add Image",
    },
    ContentList: {
      "Content list": "Content list",
    },
    ContentDetails: {
      "Content details": "Content Details",
      "Event Deleted": "Event Deleted",
    },
    EventList: {
      "Your club does not have right to create event yet":
        "Your club does not have right to create event yet. Please contact us for access.",
      "View all": "View all",
      "Recommended Event": "Recommended Event",
      Applied: "Applying",
      Confirmed: "Confirmed",
      Finished: "Finished",
      Search: "Search",
      Before: "Before",
      After: "After",
      "There is no event": "There is no event",
      Created: "Created",
      OnGoing: "In Progress",
      "There is no %{type} event": "There is no %{type} event",
      "You dont have access to event creator permission":
        "You don't have access to event creator permission",
      "Created events": "Created events",
      "On-going events": "On-going events",
      "Finished events": "Finished events",
      "Tap here to create a new event and have fun with other player":
        "Tap here to create a new event and have fun with other player.",
      Host: "Host",
      Participant: "Participant",
      "No Event": "No Event",
    },
    AllEvent: {
      "All event": "All Available Events",
      Search: "Search",
      Before: "Before",
      After: "After",
      "No Event available": "No Event available",
      "Please search again": "Please search again",
      Filter: "Filter",
    },
    EventFiltering: {
      "Filter event": "Filter event",
      Location: "Location",
      "All areas": "All areas",
      "All districts": "All districts",
      "All types": "All types",
      "After a certain starting date": "After",
      "Before a certain starting date": "Before",
    },
    Profile: { Profile: {} },
    SuccessfullSignUp: {},
    RequestDetails: { RequestDetails: {} },
    CreateAccount: {},
    EmailVerification: {},
    SignUp: {
      CreateAccount: {
        "You will recieve an confirmation email later":
          "You will recieve an confirmation email soon",
        Password: "Password",
        Email: "Email",
        Register: "Register",
        "Ok, got it": "Ok, got it",
        Error: "Error",
        "Your email has already been used": "Your email has already been used",
        "There is an error creating your account":
          "There is an error creating your account",
        "Please consider the rules for your new password":
          "Your password must use:",
        "at least 1 Uppercase, 1 Lowercase, 1 number, at least 8 characters":
          "both upper and lower case characters, numbers, at least 8 characters",
      },
      BasicInfo: {
        "Getting Started": "Getting Started",
        "Phone Number be 8-digit number":
          "Phone Number must be a 8-digit number",
        "Invalid date": "Invalid date",
      },
      UserType: {
        "I'm player": "I am a player",
        "I'm coach": "I am a coach",
        "I'm club stuff": "I am a club staff",
        "Getting Started": "Getting Started",
        "What is your role?": "What is your role?",
      },
      PlayerInfo: {
        "Start year as player must greater than date of birth":
          "Start year as player must greater than date of birth",
        "Player's Information": "Player's Information",
        "When you start": "When you start",
        "Start Year": "Start Year",
      },
      CoachInfo: {
        "Start year as a player should be after your date of birth":
          "Start year as a player should be after your date of birth",
        "Start year as a coach should be after your start year as a player":
          "Start year as a coach should be after your start year as a player",
        "Coach's Information": "Coach's Information",
        "When you start": "When you start",
        "When you start as a player": "When you start as a player",
        "When you start as a coach": "When you start as a coach",
        "Start Year": "Start Year",
      },
      ClubStaffInfo: {
        "Club Staff's Information": "Club Staff's Information",
        "Club name": "Club name",
      },
      EmailVerification: {
        "Invalid email and/or password": "Invalid email and/or password",
        "Email not confirmed": "Email not confirmed",
        "Registration confirmed": "Registration\nconfirmed",
        "Thank you for registration": "Thank you for\nregistration",
        "Thank you for your patient Please continue the onboarding process":
          "Thank you for your patience. Please continue the onboarding process.",
        "You will soon receive a confirmation email then you can continue the onboarding process":
          "You will soon receive a confirmation email, then you can continue the onboarding process.",
        "Not yet receive any email? ": "Not yet receive any email? ",
        "Resend email": "Resend email",
      },
      SignUpSuccess: {
        Successful: "Successful",
        "Admin will soon to approve your application Thank you":
          "Admin will approve your application soon. Thank you.",
        OK: "OK",
      },
      Splash: { Splash: {} },
    },
    ProfileScreen: {
      ProfileScreen: {
        "Edit profile": "Edit profile",
        Profile: "Profile",
        "Join Club": "Join Club",
        "Will be updated later": "Will be updated later",
      },
    },
    SettingsScreen: {
      SettingsScreen: {
        Account: "Account",
        Language: "Language",
        Password: "Password",
        "Biometric Login": "Biometric Login",
        Logout: "Logout",
        Setting: "Setting",
        "Are you sure to log out?": "Do you want to logout?",
        "Delete Account": "Delete Account",
        "Are you sure to delete your account?":
          "Are you sure to delete your account?",
        Deleted: "Deleted",
        "Deleted successfully": "Deleted successfully",
        "Deleted failure": "Deleted failure",
        Failure: "Failure",
        "Terms and Conditions": "Terms and Conditions",
        "Rate GoPingPong": "Rate GoPingPong",
        "Change password": "Change Password",
        Version: "Version",
        "You cannot open this url": "You cannot open this url",
        "Change Email": "Change Email",
      },
    },
    UserProfileViewerScreen: {
      Profile: "Profile",
      "Coach Profile": "Coach Profile",
      "Save as Favourite": "Save as Favourite",
      "Send Request": "Send Request",
      "Remove from Favourite": "Remove from Favourite",
    },
    //  Manage Sessions & Edit Sessions
    ManageSessions: {
      "View Session": "View Session",
      Group: "Group",
      Edited: "Edited",
      Session: "Session",
      "Leave Page": "Leave Page?",
      "Changes you made may not be saved": "Changes you made may not be saved",
      "Confirm to save changes?": "Confirm to save changes?",
      "Saved successfully": "Saved successfully",
      "Removed successfully": "Removed successfully",
      "%{number} players applied this session":
        "%{number} players applied this session",
      "End Time": "End Time",
      "Start Time": "Start Time",
      "End Date": "End Date",
      "Start Date": "Start Date",
      Date: "Date",
      "Day of Week": "Day of Week",
      Time: "Time",
      Type: "Type",
      Single: "Single",
      Loop: "Loop",
      "Days of week must be selected at least 1 value":
        "Days of week must be selected at least 1 value",
      "Assigned Coach": "Assigned Coach",
      Applied: "Applied",
      "Edit Session": "Edit Session",
    },
    CourseRefund: {
      "Refund Summary": "Refund Summary",
      "Number of Sessions": "Number of Sessions",
      Session: "Session",
      "Refund Reason (Optional)": "Refund Reason (Optional)",
      "Refund Amount": "Refund Amount",
      "Confirm to refund": "Confirm to refund ?",
      "Date repetition": "Date repetition",
      "Date should be in the future": "Date should be in the future",
    },
    ApplySessions: {
      "Date of First Session": "Date of First Session",
      "Course session": "Course session",
      "Minimum session to apply": "Minimum number of sessions required",
      "Number of Sessions": "Number of Sessions",
      "Select sessions": "Select sessions",
      "Out of index of sessions": "Out of index of sessions",
      "Apply Sessions": "Apply Sessions",
    },
  },
  component: {
    AddSessionComponent: {
      "Head coach name": "Head coach name",
      "Head Coach": "Head Coach",
      "Customized Price": "Customized Price",
      "Assistant Coach(es)": "Assistant Coach(es)",
      Name: "Name",
      "Assistant Coach": "Assistant Coach",
      "Select coach": "Select coach",
      Date: "Date",
      "Start Date": "Start Date",
      "End Date": "End Date",
      "Day of Week": "Day of Week",
      Time: "Time",
      "Start Time": "Start Time",
      "End Time": "End Time",
      "Assigned coach": "Assigned coach",
      Type: "Type",
      Loop: "Loop",
      Single: "Single",
      "Select start time": "Select start time",
      "Select end time": "Select end time",
      "Select Start Date": "Select Start Date",
      "Select End Date": "Select End Date",
      "Select Date": "Select Date",
      Group: "Group",
      "Preview Session": "Preview Session",
      "Is there a fee for the course": "Is there a fee for the course?",
      "Add payment method": "Add payment method",
      "start date must be earlier than the end date":
        "start date must be earlier than the end date",
      "start time must be earlier than the end time":
        "start time must be earlier than the end time",
    },
    SliderInput: {
      "Max must be greater than Min and an integer":
        "Max must be greater than Min and an integer",
      "Min must be less than Max and an integer":
        "Min must be less than Max and an integer",
    },
    ArrayEditMatch: {
      Match: "Match",
    },
    ArrayAddTeamInput: {
      "Add team": "Add team",
    },
    DateTimePicker: {
      Date: "Date",
      Jan: "Jan",
      Feb: "Feb",
      Mar: "Mar",
      Apr: "Apr",
      May: "May",
      Jun: "Jun",
      Jul: "Jul",
      Aug: "Aug",
      Sep: "Sep",
      Oct: "Oct",
      Nov: "Nov",
      Dec: "Dec",
      Sun: "Sun",
      Mon: "Mon",
      Tue: "Tue",
      Wed: "Wed",
      Thu: "Thu",
      Fri: "Fri",
      Sat: "Sat",
    },
    MatchGameResultItem: {
      Game: "Game",
    },
    FixtureItem: {
      Session: "Session",
      Round: "Round",
      WIN: "WIN",
      LOSE: "LOSE",
      DRAW: "DRAW",
    },
    TeamRequestCard: {
      "yrs exp": "yrs exp",
      "Player details": "Player details",
      "Thumb nail": "Thumb nail",
      Approved: "Approved",
      Rejected: "Rejected",
      Pending: "Pending",
      WaitingList: "Waiting List",
    },
    ArrayDivisionsInput: {
      A: "A",
      B: "B",
      C: "C",
      D: "D",
      "Add division": "Add division",
      "New Division can be created afterward":
        "New Division can be created afterward",
      Name: "Name",
      Tier: "Tier",
      Division: "Division",
      "Select tier": "Select tier",
    },
    OrganizerBottomTabNavigator: {
      League: "League",
    },
    ApiToastError: {
      "Error please try again later": "Error please try again later",
      Error: "Error",
      "Invalid email": "Invalid email",
      "Email already registered": "Email already registered",
      "Invalid password": "Invalid password",
      "Please check and enter a valid email address to register account":
        "Please check and enter a valid email address to register account.",
      "Please enter a different email address for registration or login to existing email":
        "Please enter a different email address for registration or login to existing email.",
      "Please follow the password format listed below to register account":
        "Please follow the password format listed below to register account.",
      "The date and time for this course is already past, please edit the session and retry":
        "The date and time for this course is already past, please edit the session and retry",
      "Create Course Failed": "Create Course Failed",
    },
    ArrayPaymentInput: {
      "Phone number": "Phone number",
      "Account name": "Account name",
      Identifier: "Identifier",
      "Both of them": "Both of them",
      Bank: "Bank",
      "Bank account": "Bank account",
      "Free text": "Free text",
      "Payment method": "Payment method",
      Method: "Method",
      "Select payment type": "Select payment type",
      "Please select 1 information to show":
        "Please select 1 information to show",
      Others: "Others",
      "New Payment Method": "New Payment Method",
      "Other Payment Method": "Other Payment Method",
    },
    ArrayDateInput: {
      From: "From",
      To: "To",
      "Start time must be ealier than end time":
        "Start time must be ealier than end time",
      Venue: "Address",
      "Add date with same address": "Add date with same address",
      "Add date with different address": "Add date with different address",
      Day: "Day",
      "Day %{number}": "Day %{number}",
      "Date should be in the future": "Date should be in the future",
      Group: "Group",
      "players applied this session": "players applied this session",
    },
    AttendanceCard: {
      Leave: "Leave",
    },
    CourseApplicantDetailsCard: {
      "yrs exp": "yrs exp",
      "Player details": "Player details",
      Start: "Start date",
      "Number of sessions": "Sessions",
      Leave: "Leave",
      "Make up session": "Make up session",
      "Upcoming Session": "Upcoming Session",
      "Sessions left": "Sessions left",
      Reject: "Reject",
    },
    EventApplicantDetailsCard: {
      "yrs exp": "yrs exp",
      "Player details": "Player Details",
      Start: "Start",
      "Number of sessions": "Number of sessions",
      "Thumb nail": "Thumb nail",
      Approved: "Approved",
      Rejected: "Rejected",
      Pending: "Pending",
      WaitingList: "Waiting List",
      "Applicant details": "Applicant Details",
    },
    EventApplicantInfoCard: {
      Approved: "Approved",
      Rejected: "Rejected",
      Pending: "Pending",
      WaitingList: "Waiting List",
      Paid: "Paid",
      Unpaid: "Unpaid",
    },
    CourseApplicantInfoCard: {
      Approved: "Approved",
      Rejected: "Rejected",
      Pending: "Pending",
      WaitingList: "Waiting List",
      Paid: "Paid",
      Unpaid: "Unpaid",
      Refund: "Refund",
      "Start date": "Start date",
      Sessions: "Sessions",
      Ammount: "Amount",
    },
    TeamManageDetailsCard: {
      "Player details": "Player details",
      Approved: "Approved",
      Rejected: "Rejected",
      Pending: "Pending",
      Remove: "Remove",
    },
    CourseList: {
      "Roll call": "Roll call",
      Manage: "Manage",
    },
    EventList: {
      "Roll call": "Roll call",
      Manage: "Manage",
    },
    CreateJoinClub: {
      "Join Club": "Join Club",
      "Create Club": "Create Club",
      Pending: "Pending",
      "Rejected Reason": "Rejected Reason",
      "Waiting for approval": "Waiting for approval",
      "Check for availability now": "Check for availability now!",
      Rejected: "Rejected",
    },
    MeetupDetails: {
      "Meet up details": "Meet up details",
      "hkd/hr": "hkd/hr",
      "Venue is missing": "Venue is missing",
      "hkd/person": "hkd/person",
      Pending: "Pending",
      Matched: "Matched",
      Completed: "Completed",
      Cancelled: "Cancelled",
      LateCancelled: "Late Cancelled",
      NoShow: "No Show",
    },
    VenueDetails: {
      "hkd/hour": "hkd/hour",
      Date: "Date",
      Description: "Description",
      "Phone Number": "Phone Number",
      "Number of tables": "Number of tables",
    },
    ClubShortProfile: {
      ClubStaff: "Club Staff",
      "Number of Coach %{number}": "Number of Coach %{number}",
      "Club Name": "Club Name",
      District: "District",
      Address: "Address",
      Manage: "Manage",
      Edit: "Edit",
      Coaches: "Coaches",
    },
    CalenderListItem: {
      O3Coach: "Coaching",
      Course: "Course",
      Venue: "Venue",
      Event: "Event",
      Fixture: "League",
    },
    ApplicantListItem: {
      "Player details": "Player details",
      "yrs exp": "yrs exp",
      Rank: "Rank",
      Approved: "Approved",
      Rejected: "Rejected",
    },
    MeetupSmallCard: {
      Hide: "Hide",
    },
    Badge: { FilterBadge: {} },
    Calendar: { Calendar: {} },
    CalendarListItem: { CalendarListItem: {} },
    Card: {
      BadgeHeader: {},
      VenueBookingCard: {
        "Quick Approve": "Quick Approve",
      },
      AppliedCoachCard: {
        Details: "Details",
        "Venue is missing": "Venue is missing",
        "L/R": "L/R",
        Age: "Age",
        Sex: "Sex",
        "hkd/hr": "hkd/hr",
        "Not interested": "Not interested",
        "Matched for %{times} times": "Matched for %{times} times",
      },
      ContactsCard: {
        Details: "Details",
        "Venue is missing": "Venue is missing",
        "L/R": "L/R",
        Age: "Age",
        Sex: "Sex",
        "hkd/hr": "hkd/hr",
        "Not interested": "Not interested",
        "Matched for %{times} times": "Matched for %{times} times",
      },
      CourseBookingCard: {
        Accepted: "Accepted",
        Applied: "Applied",
        hkd: "hkd",
      },
      CourseCard: {
        Accepted: "Approved",
        Applied: "Waiting Approval",
        hkd: "hkd",
        Started: "Started",
        Completed: "Completed",
        to: "to",
        Available: "Available",
        Full: "Full",
      },
      CourseManageCard: {
        Accepted: "Accepted",
        Applied: "Applied",
        hkd: "hkd",
        Details: "Details",
        applications: "applications",
        Approved: "Approved",
        Pending: "Pending",
      },
      VenueManageCard: {
        Details: "Details",
        applications: "applications",
        Approved: "Approved",
        Pending: "Pending",
      },
      FindCoachCard: {
        "Find Coach": "Find Coach",
        "Enter your preference to find the most suitable coach to you !":
          "Enter your preference to find the most suitable coach to you !",
      },
      FindCoachTipCard: {
        "Finding Coach": "Finding Coach",
        "Contacting the coach Please wait for a while":
          "Contacting the coach. Please wait for a while.",
      },
      EventCard: {
        to: "to",
        hkd: "hkd",
        days: "Days",
        "Please check Day 2 or more details in details page":
          "Please check Day 2 or more details in details page",
        Day: "Day",
        "Day %{number}": "Day %{number}",
        Manage: "Manage",
        "Address is missing": "Address is missing",
      },
      highlightCard: {},
      FeaturePlaceholderCard: {
        "Enjoy matching coach anytime": "Enjoy matching coach anytime",
        "Encourage user to match coach, and this card will be direct user to find coach":
          "Find your coach pressing the blue card",
      },
      DivisionCard: {
        Tier: "Tier",
      },
      FixtureCard: {
        "My team up-coming match": "My team up-coming match",
      },
    },
    Coach: {
      CoachProfile: {
        "Join club": "Join club",
        Club: "Club",
        Years: "Years",
        "as coach": "as coach",
        "as player": "as player",
        Clubs: "Clubs",
        Experience: "Coach Experience",
        "Player Experience": "Player Experience",
        "Date of birth": "Date of Birth",
        Email: "Email",
        "Price / hour": "Price / hour",
        "Lisence No": "Lisence No.",
        Ranking: "Ranking",
        "Waiting for Approval": "Waiting for Approval",
      },
    },
    CoachShortProfile: {
      Rank: "Rank",
      Coach: "Coach",
      "View Profile": "View Profile",
      "Coached for": "Coached for",
      years: "years",
    },
    ErrorMessage: { "Error occured": "Error occured" },
    Modal: {
      OneColumnPickerModal: {},
      PickerModal: {},
      RejectWithReasonModal: {
        "Please provide reason for rejecting ":
          "Please provide reason for rejecting ",
        "to apply": " to apply ",
      },
      TextInputModal: {
        "Please provide reason for rejecting ":
          "Please provide reason for rejecting ",
        " to apply ": " to apply ",
      },
      ThreeColumnPickerModal: {},
      TwoColumnPickerModal: {},
      ParticipantListModal: {
        "Waiting list": "Waiting list",
        "participant list": "Participant list",
        "Member list": "Member list",
        "No participant": "No participant",
      },
    },
    Picker: { WheelPicker: {} },
    ClubStaff: {
      ClubStaffProfile: {
        Mobile: "Mobile",
      },
    },
    Player: {
      PlayerProfile: {
        hand: "hand",
        Years: "Years",
        "as player": "as player",
        PlayerLevel: "Level",
      },
    },
    PlayerShortProfile: {
      Hand: "hand",
      Player: "Player",
      Age: "Age",
      "View Profile": "View Profile",
      "Matched for %{times} times": "Matched for %{times} times",
    },
    RangeSlider: { RangeSlider: {} },
    Section: { Section: {} },
    TabBar: { TabBar: {} },
    TextButton: { TextButton: {} },
    Button: {
      Loading: "Loading",
    },
    ClubRejectVenueModal: {
      "Please provide reason for rejecting ":
        "Please provide reason for rejecting ",
      " to apply ": " to apply ",
      Submit: "Submit",
    },
    ImagePicker: {
      "Profile\npicture": "Profile\npicture",
    },
    FeatureCard: {
      "Meet up with": "Meet up with ",
    },
    O3Details: {
      "Meet up details": "Meet up details",
      "hkd/hr": "hkd/hr",
      "Venue is missing": "Venue is missing",
      "hkd/person": "hkd/person",
    },
    PlayerBottomTabNavigator: {
      Home: "Home",
      History: "History",
      Meetup: "Meetup",
      Calendar: "Calendar",
      Profile: "Profile",
      Content: "Content",
      WebPortal: "Web Portal",
    },
    PlayerMeetupButton: {
      Meet: "Meet",
      Event: "Event",
      Course: "Course",
      Coach: "Coach",
      Venue: "Venue",
      League: "League",
      ClubCalendar: "Calendar",
      Cancel: "Cancel",
      WebPortal: "Web Portal",
    },
    CoachBottomTabNavigator: {
      Home: "Home",
      History: "History",
      Meetup: "Meetup",
      Calendar: "Calendar",
      Profile: "Profile",
      Content: "Content",
      WebPortal: "Web Portal",
    },
    CoachMeetupButton: {
      Meet: "Meet",
      Event: "Event",
      Course: "Course",
      Coach: "Coach",
      Venue: "Venue",
      League: "League",
      Cancel: "Cancel",
      WebPortal: "Web Portal",
    },
    FeatureCardSlider: {
      Event: "Event",
      Course: "Course",
      Meetup: "Meetup",
    },
    StaffMeetupButton: {
      Meet: "Meet",
      Event: "Event",
      Course: "Course",
      Coach: "Coach",
      Venue: "Venue",
      League: "League",
      ClubCalendar: "Calendar",
      Report: "Report",
      Cancel: "Cancel",
      WebPortal: "Web Portal",
    },
    ClubBottomTabNavigator: {
      Home: "Home",
      Manage: "Manage",
      Calendar: "Calendar",
      Profile: "Profile",
      "Please join or create a club before further actions":
        "Please join or create a club before further actions",
      Reminder: "Reminder",
      Content: "Content",
      WebPortal: "Web Portal",
    },
    AdminBottomTabNavigator: {
      User: "User",
      Club: "Club",
      Content: "Content",
      Permission: "Permission",
    },
    MeetupSummaryCounter: {
      Pending: "Pending",
      "On-going": "On-going",
      Past: "Past",
      "No show": "No show",
    },
    VenueBookingDetails: {
      "Applied Venue": "Applied Venue",
      "Venue Photo": "Venue Photo",
      "Selected date": "Selected date",
      "Selected time": "Selected time",
      hkd: "hkd",
    },
    Toast: {
      MesssageToast: {
        "See details": "See details",
      },
    },
    ClubDetails: {
      ApprovalStatus: "Approval Status",
      "Club Name": "Club Name",
      "Club Address": "Club Address",
      ClubCoaches: "Club Coaches",
      District: "District",
      CreatedAt: "Created At",
      "Club Admin": "Club Admin",
    },
    ContentDetailsComponent: {
      Views: "Views",
      "View Event Detail": "View Event Detail",
      "You can not open, please download the corresponding application":
        "Link cannot be opened, please download the corresponding application",
    },
    ArrayTeamMemberInput: {
      "Select player": "Select player",
      "Foreign Player": "Foreign Player",
      Other: "Other",
      Female: "Female",
      Player: "Player",
      name: "name",
    },
    NoDataComponent: {
      "No Data": "No Data",
    },
    NoAccessRight: {
      "No Access Right": "No Access Right",
      "You do not have the right to access this function":
        "You do not have the right to access this function",
    },
    MatchCard: {
      WIN: "WIN",
      LOSE: "LOSE",
      DRAW: "DRAW",
      set: "set",
      Approval: "Approval",
    },
    meetupCard: {
      "Recommended by AI": "Recommended by AI",
      Matched: "Upcoming",
      Completed: "Completed",
      LateCancelled: "Cancelled",
      Rejected: "Rejected",
      Cancelled: "Cancelled",
      NoShow: "NoShow",
      MON: "MON",
      TUE: "TUE",
      WED: "WED",
      THU: "THU",
      FRI: "FRI",
      SAT: "SAT",
      SUN: "SUN",
      "Not Available": "Not Available",
    },
    ComingSoon: {
      "Coming Soon": "COMING SOON",
      "We’re developing the report feature, stay tuned for updates":
        "We’re developing the report feature, stay tuned for updates",
    },
  },
};
