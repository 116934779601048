import React from "react";
import { Icon } from "native-base";
import Svg, { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function ClockArrowIcon({
  color = "black",
  ...otherProps
}: Omit<InterfaceIconProps, "color"> & { color?: "black" | "white" }) {
  return (
    <Icon size="sm" viewBox="0 0 34 34" alignSelf="center" {...otherProps}>
      <Svg width="34" height="34" viewBox="0 0 34 34" fill="none">
        <Path
          d="M11.2824 21.1673L17.5858 17.5267C17.6936 17.4641 17.7823 17.3115 17.7823 17.186V9.90613C17.7823 9.78138 17.6807 9.67896 17.5557 9.67896H16.4949C16.3701 9.67896 16.2677 9.78138 16.2677 9.90613V16.3117C16.2677 16.4365 16.1789 16.5899 16.0712 16.652L10.5251 19.855C10.4168 19.9176 10.3795 20.0573 10.4422 20.1655L10.9715 21.0836C11.0347 21.1919 11.1741 21.2292 11.2824 21.1671L11.2824 21.1673Z"
          fill="black"
        />
        <Path
          d="M24.9963 17.2683C24.8489 17.121 24.8991 17.0004 25.1081 17.0004H26.3446C26.3446 11.8221 22.1414 7.60849 16.9748 7.60849C11.8082 7.60849 7.605 11.8218 7.605 17.0004C7.605 22.1789 11.8084 26.3922 16.9748 26.3922C20.3793 26.3922 23.3609 24.5576 25.001 21.8258C25.0796 21.6944 25.1182 21.624 25.1182 21.624C25.179 21.5144 25.2926 21.4903 25.372 21.5694L25.5162 21.7137L26.2847 22.4834L26.538 22.7377C26.5966 22.7963 26.609 22.9028 26.5646 22.9738C26.5646 22.9738 26.4417 23.1725 26.3339 23.3318C24.3005 26.3453 20.8694 28.3343 16.9751 28.3343C10.7399 28.3343 5.66661 23.2501 5.66661 17.0005C5.66661 10.7508 10.7396 5.66663 16.9751 5.66663C23.2106 5.66663 28.283 10.7506 28.283 17.0005H29.5195C29.7285 17.0005 29.7787 17.1212 29.6308 17.2685L27.4745 19.4306C27.3857 19.5193 27.2415 19.5193 27.1532 19.4306L24.9962 17.2685"
          fill="black"
        />
      </Svg>
    </Icon>
  );
}
