/* eslint-disable prefer-destructuring */
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import axios from "axios";
import {
  VStack,
  Button,
  Toast,
  HStack,
  Avatar,
  Heading,
  Badge,
  Text,
  Pressable,
} from "native-base";
import React, { useState } from "react";
import useSWR from "swr";
import ErrorMessage from "../../../components/ErrorMessage";
import HeaderLayout from "../../../components/Layout/HeaderLayout";
import Loading from "../../../components/Loading";
import RejectWithReasonModal from "../../../components/Modal/RejectWithReasonModal";
import PlayerShortProfile from "../../../components/PlayerShortProfile";
import { VenueBooking, VenueBookingStatus } from "../../../models/Booking";
import {
  ApproveVenueBookingRequest,
  RejectVenueBookingRequest,
} from "../../../models/Request";
import { MainStackNavigatorParamList } from "../../../routers/Types";
import {
  formatFileUrl,
  formatMeetupApiUrl,
} from "../../../services/ServiceUtil";
import {
  approveVenueBooking,
  rejectVenueBooking,
} from "../../../services/VenueBooking";
import VenueBookingDetailsComponent from "../../../components/VenueBookingDetails";

import { getTranslation } from "../../../utils/translation";
import MessageToast, {
  MesssageToastType,
} from "../../../components/Toast/MessageToast";
import { formatName, getUserName } from "../../../utils/name";
import { Player, UserType } from "../../../models/User";
import {
  APPROVE_BUTTON_COLOR,
  REJECT_BUTTON_COLOR,
} from "../../../constants/constants";

const t = getTranslation([
  "screen.ClubScreens.VenueBookingDetails",
  "constant.district",
  "constant.button",
  "validation",
  "constant.profile",
]);

const approveBooking = (booking: VenueBooking) => {
  const payload: ApproveVenueBookingRequest = {
    venueId: booking.id,
  };
  return approveVenueBooking(payload);
};

const rejectBooking = (booking: VenueBooking, rejectReason: string) => {
  const payload: RejectVenueBookingRequest = {
    venueId: booking.id,
    rejectReason,
  };
  return rejectVenueBooking(payload);
};

export type VenueRequestDetailsNavigationProp = NativeStackNavigationProp<
  MainStackNavigatorParamList,
  "ClubVenueBookingDetails"
>;

export type VenueRequestDetailsRouteProp = RouteProp<
  MainStackNavigatorParamList,
  "ClubVenueBookingDetails"
>;

export interface VenueRequestDetailsNavigationProps {
  navigation: VenueRequestDetailsNavigationProp;
  route: VenueRequestDetailsRouteProp;
}

export default function VenueBookingDetails({
  navigation,
  route,
}: VenueRequestDetailsNavigationProps) {
  const { venueBooking, venueBookingId } = route.params;
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);

  const {
    data: venueFetched,
    isValidating,
    error,
  } = useSWR<VenueBooking>(
    venueBookingId && !venueBooking
      ? formatMeetupApiUrl(`/venue/${venueBookingId}`)
      : null,
    (path) => axios.get(path).then((res) => res.data)
  );

  const venueResult = venueBooking || venueFetched;

  const userProfile = (playerInfo: Player) => {
    const handUsed = playerInfo.handUsed;

    const level = playerInfo.playerLevel ? t(playerInfo.playerLevel) : "-";
    const hand = t(`${handUsed} Hand`);
    const rank = playerInfo.ranking || t("No Rank");
    const levelRanking =
      `${level} (${rank})` === "- (-)" ? "-" : `${level} (${rank})`;
    return (
      <HStack space="3" alignItems="flex-start" flexWrap="wrap">
        <Avatar
          size="sm"
          source={
            playerInfo.profilePicture
              ? {
                  uri: formatFileUrl(playerInfo.profilePicture),
                }
              : undefined
          }
        >
          {playerInfo.firstName}
        </Avatar>
        <VStack space="2" flex="1">
          <HStack space="2" alignItems="center" flexWrap="wrap">
            {playerInfo.firstName && playerInfo.lastName && (
              <Heading>{getUserName(playerInfo)}</Heading>
            )}
            <Badge
              px="2"
              py="2"
              borderRadius="full"
              bg="#66CEE133"
              alignSelf="flex-start"
            >
              <Text>{`${hand} (${t(playerInfo.blade)})`}</Text>
            </Badge>
          </HStack>
          <Text>{levelRanking}</Text>

          <Pressable
            onPress={() =>
              navigation.navigate("UserProfileViewer", {
                user: {
                  ...playerInfo,
                  userType: UserType.Player,
                },
              })
            }
          >
            <Text fontWeight="bold" fontSize="md" color="rs.primary_purple">
              {t("View profile")}
            </Text>
          </Pressable>
        </VStack>
      </HStack>
    );
  };

  return (
    <HeaderLayout
      isSticky
      headerProps={{
        title: t("Request Venue"),
        onPress: () => {
          navigation?.goBack();
        },
      }}
    >
      {isValidating && <Loading />}
      {!isValidating && error && <ErrorMessage />}
      {!isValidating && !error && venueResult && (
        <VStack mx="defaultLayoutSpacing">
          {/* <PlayerShortProfile
            heading={t("Application")}
            player={venueResult.bookerInfo}
          /> */}
          {userProfile(venueResult.bookerInfo)}
          <VenueBookingDetailsComponent data={venueResult} />
          {venueResult.status === VenueBookingStatus.Pending && (
            <>
              <Button
                mt="10"
                onPress={() => {
                  approveBooking(venueResult).then(() => {
                    Toast.show({
                      id: "venueApproved",
                      duration: 2000,
                      placement: "top",
                      render: () => {
                        return (
                          <MessageToast
                            type={MesssageToastType.Success}
                            title={t("Venue booking is approved")}
                          />
                        );
                      },
                    });
                    navigation.goBack();
                  });
                }}
                bg={APPROVE_BUTTON_COLOR}
              >
                {t("Approve")}
              </Button>
              <Button
                my="5"
                variant="outline"
                onPress={() => setRejectModalVisible(true)}
                borderColor={REJECT_BUTTON_COLOR}
                _text={{ color: REJECT_BUTTON_COLOR }}
              >
                {t("Reject")}
              </Button>
            </>
          )}
          <RejectWithReasonModal
            isOpen={rejectModalVisible}
            onClose={() => setRejectModalVisible(false)}
            onPressSubmit={(msg) => {
              rejectBooking(venueResult, msg).then(() => {
                setRejectModalVisible(false);
                navigation.navigate("ClubRejectedVenueBooking");
              });
            }}
            user={venueResult.bookerInfo}
            rejectObject={venueResult.venue}
          />
        </VStack>
      )}
    </HeaderLayout>
  );
}
