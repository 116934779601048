import axios from "axios";
import { VenueBooking } from "../models/Booking";
import { CreateVenueBookingRequest } from "../models/requests/VenueBooking";
import { formatMeetupApiUrl } from "./ServiceUtil";

const VENUE_BOOKING_PATH = "/venue";

export async function getAllVenueBookings() {
  return axios
    .get<VenueBooking[]>(formatMeetupApiUrl(VENUE_BOOKING_PATH))
    .then((res) => res.data);
}

export async function getVenueBookingById(venueMeetupId: number | string) {
  return axios
    .get<VenueBooking>(
      formatMeetupApiUrl(`${VENUE_BOOKING_PATH}/${venueMeetupId}`)
    )
    .then((res) => res.data);
}

export async function createVenueBooking(payload: CreateVenueBookingRequest) {
  return axios.post(formatMeetupApiUrl(VENUE_BOOKING_PATH), payload);
}

export async function deleteVenueBooking(venueBookingId: number) {
  return axios.delete(
    formatMeetupApiUrl(`${VENUE_BOOKING_PATH}/${venueBookingId}`)
  );
}
