import React from "react";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function BoxIcon(props: InterfaceIconProps) {
  return (
    <Icon size="lg" viewBox="0 0 20 23" {...props}>
      <G fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.50214 0.402641C8.95768 0.140138 9.47422 0.00195312 10 0.00195312C10.5258 0.00195312 11.0424 0.140149 11.4979 0.402673L11.5 0.403877L18.5 4.40387C18.87 4.61748 19.1891 4.90704 19.4369 5.25224C19.4973 5.31262 19.5508 5.38178 19.5956 5.45919C19.6342 5.52588 19.6642 5.59517 19.686 5.66579C19.8917 6.07924 19.9995 6.53541 20 6.99888V15.0009C19.9995 15.527 19.8606 16.0437 19.5973 16.4992C19.334 16.9547 18.9556 17.3329 18.5 17.5959L18.4961 17.5981L11.5 21.5959L11.4982 21.597C11.181 21.7798 10.8342 21.9024 10.4753 21.96C10.3339 22.0365 10.172 22.0799 10 22.0799C9.82797 22.0799 9.66611 22.0365 9.52474 21.96C9.16584 21.9024 8.81905 21.7798 8.50185 21.597L8.5 21.5959L1.50386 17.5981L1.5 17.5959C1.04439 17.3329 0.665971 16.9547 0.402693 16.4992C0.139415 16.0437 0.000540137 15.527 0 15.0009V6.99888C0.000475916 6.53534 0.108347 6.07909 0.314083 5.66559C0.335874 5.59504 0.365871 5.52582 0.404412 5.45919C0.44916 5.38183 0.502684 5.31272 0.562998 5.25237C0.810866 4.90712 1.12998 4.61751 1.5 4.40388L1.50386 4.40165L8.50214 0.402641ZM11 19.5782L17.5 15.8639L17.5016 15.863C17.6527 15.7753 17.7783 15.6496 17.8658 15.4983C17.9535 15.3465 17.9998 15.1742 18 14.9989V7.53746L11 11.5867V19.5782ZM9 11.5867V19.5782L2.5 15.8639L2.49842 15.863C2.34726 15.7753 2.22169 15.6496 2.13423 15.4983C2.04654 15.3466 2.00026 15.1745 2 14.9993V7.53744L9 11.5867ZM10.5039 2.13815L16.961 5.82795L10 9.85466L3.03898 5.82794L9.49614 2.13815L9.5 2.13593C9.65202 2.04816 9.82446 2.00195 10 2.00195C10.1755 2.00195 10.348 2.04816 10.5 2.13593L10.5039 2.13815Z"
          fill="#6D6D6D"
        />
      </G>
    </Icon>
  );
}
