import React from "react";
import { Icon, useTheme } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function ManageIcon({
  props,
  isFocus = false,
  color,
}: {
  props?: InterfaceIconProps;
  isFocus?: boolean;
  color?: string;
}) {
  const { colors: themeColors } = useTheme();
  return (
    <Icon size="lg" viewBox="0 0 21 18" {...props}>
      <G fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={
            color ??
            (isFocus ? themeColors.rs.black : themeColors.rs_secondary.grey)
          }
          d="M11.0211 10.4984L11.0211 10.4984L19.1 8.34491V14.8359C19.1 14.9619 19.05 15.0827 18.9609 15.1718C18.8718 15.2609 18.751 15.3109 18.625 15.3109H2.375C2.24902 15.3109 2.1282 15.2609 2.03912 15.1718C1.95004 15.0827 1.9 14.9619 1.9 14.8359V8.34369L9.97884 10.4984L9.9789 10.4984C10.3203 10.5893 10.6797 10.5893 11.0211 10.4984ZM10.6226 9.001L10.6225 9.00102C10.5423 9.02246 10.4577 9.02246 10.3774 9.00102L10.3774 9.001L1.9 6.74069V4.83594C1.9 4.70996 1.95004 4.58914 2.03912 4.50006C2.1282 4.41098 2.24902 4.36094 2.375 4.36094H18.625C18.751 4.36094 18.8718 4.41098 18.9609 4.50006C19.05 4.58914 19.1 4.70996 19.1 4.83594V6.74069L10.6226 9.001ZM8.625 0.310937C8.08794 0.310937 7.57287 0.524285 7.19311 0.904046C6.81335 1.28381 6.6 1.79887 6.6 2.33594V2.81094H2.375C1.83794 2.81094 1.32287 3.02429 0.943109 3.40405C0.563348 3.78381 0.35 4.29887 0.35 4.83594V14.8359C0.35 15.373 0.563348 15.8881 0.943109 16.2678C1.32287 16.6476 1.83794 16.8609 2.375 16.8609H18.625C19.1621 16.8609 19.6771 16.6476 20.0569 16.2678C20.4367 15.8881 20.65 15.373 20.65 14.8359V4.83594C20.65 4.29887 20.4367 3.78381 20.0569 3.40405C19.6771 3.02429 19.1621 2.81094 18.625 2.81094H14.4V2.33594C14.4 1.79887 14.1867 1.28381 13.8069 0.904046C13.4271 0.524285 12.9121 0.310937 12.375 0.310937H8.625ZM8.625 1.86094H12.375C12.501 1.86094 12.6218 1.91098 12.7109 2.00006C12.8 2.08914 12.85 2.20996 12.85 2.33594V2.81094H8.15V2.33594C8.15 2.20996 8.20004 2.08914 8.28912 2.00006C8.3782 1.91098 8.49902 1.86094 8.625 1.86094Z"
        />
      </G>
    </Icon>
  );
}
