import { Player } from "./User";
import { Venue } from "./requests/Venue";

export enum VenueBookingStatus {
  Approved = "Approve",
  Rejected = "Reject",
  Pending = "Pending",
  Cancelled = "Cancelled",
}

export interface VenueBooking {
  id: number;
  venue: Venue;
  venueId: number;
  playerId: string;
  fromTime: Date;
  toTime: Date;
  status: string;
  rejectReason: string;
  createdAt: Date;
  bookerId: string;
  bookerInfo: Player;
}
