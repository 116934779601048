import React from "react";
import { Icon } from "native-base";
import { G, Svg, Path, Defs, ClipPath, Rect } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function RedTrashIcon(props: InterfaceIconProps) {
  return (
    <Icon size="2xl" viewBox="0 0 30 26" {...props}>
      <Svg width="30" height="26" viewBox="0 0 30 26" fill="none">
        <G clip-path="url(#clip0_184_6369)">
          <Path
            d="M20.4786 4.73573H15.835V3.62684C15.835 3.02621 15.2574 2.54102 14.5413 2.54102H9.45866C8.71929 2.54102 8.16494 3.02626 8.16494 3.62684V4.73573H3.52129C3.15161 4.73573 2.85139 5.03612 2.85139 5.40563C2.85139 5.77531 3.15178 6.07553 3.52129 6.07553L4.60712 6.07569L5.62368 18.505C5.62368 19.868 6.75576 21.0001 8.14184 21.0001H15.8582C17.2444 21.0001 18.3763 19.8912 18.3763 18.528L19.3929 6.05251H20.4787C20.8484 6.05251 21.1486 5.75212 21.1486 5.38261C21.1488 5.01277 20.8484 4.73557 20.4787 4.73557L20.4786 4.73573ZM9.48172 4.75878V3.88083H14.5181V4.75878H9.48172ZM18.0528 6.05266L17.0595 18.4357V18.482C17.0595 19.1289 16.505 19.6834 15.8581 19.6834L8.1187 19.6832C7.47182 19.6832 6.91731 19.1287 6.91731 18.4588L5.90075 6.05258L18.0528 6.05266Z"
            fill="#E71010"
          />
          <Path
            d="M9.2507 16.1254C9.2507 16.4719 9.55109 16.7723 9.9206 16.7723C10.2903 16.7723 10.5905 16.4719 10.5905 16.0792L10.2901 9.61043C10.2901 9.42559 10.2208 9.26395 10.0822 9.12519C9.94363 9.00964 9.78184 8.94035 9.59698 8.96339C9.41214 8.96339 9.25051 9.05573 9.13497 9.17128C9.01942 9.30987 8.95013 9.49471 8.97317 9.65652L9.2507 16.1254Z"
            fill="#E71010"
          />
          <Path
            d="M14.1023 16.7727C14.4719 16.7727 14.7491 16.5185 14.7954 16.1489L15.0958 9.65699C15.0958 9.31052 14.8416 9.01011 14.4951 8.96387H14.4721C14.1256 8.96387 13.8252 9.21801 13.779 9.58768L13.4554 16.0564C13.4323 16.2413 13.4784 16.4261 13.6172 16.5647C13.7326 16.7033 13.8944 16.7728 14.1023 16.7728L14.1023 16.7727Z"
            fill="#E71010"
          />
        </G>
        <Defs>
          <ClipPath id="clip0_184_6369">
            <Rect
              width="18"
              height="18"
              fill="white"
              transform="translate(3 3)"
            />
          </ClipPath>
        </Defs>
      </Svg>
    </Icon>
  );
}
