/* eslint-disable import/prefer-default-export */
import axios from "axios";
import {
  CreateVenueRequest,
  UpdateVenueRequest,
  Venue,
} from "../models/requests/Venue";
import { formatCoreUrl } from "./ServiceUtil";

export async function createVenue(payload: CreateVenueRequest) {
  const formData = new FormData();
  formData.append("Address", payload.address);
  formData.append("Area", payload.area);
  formData.append("BallsProvided", payload.ballsProvided ? "true" : "false");
  formData.append("District", payload.district);
  formData.append("Fee", payload.fee.toString());
  formData.append("Name", payload.name);
  formData.append("NumberOfTables", payload.numberOfTables.toString());
  formData.append("PhoneNo", payload.phoneNo);
  formData.append("OpeningTime", payload.openingTime);
  formData.append("ClosingTime", payload.closingTime);
  if (payload.venueImage?.fileContent) {
    formData.append("VenueImage", {
      uri: payload.venueImage.fileContent,
      type: "image/jpeg",
      name: payload.venueImage.fileName,
    });
  }

  return axios.post(formatCoreUrl("/venue"), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function updateVenue(payload: UpdateVenueRequest, id: number) {
  const formData = new FormData();
  formData.append("Address", payload.address);
  formData.append("Area", payload.area);
  formData.append("BallsProvided", payload.ballsProvided ? "true" : "false");
  formData.append("District", payload.district);
  formData.append("Fee", payload.fee.toString());
  formData.append("Name", payload.name);
  formData.append("NumberOfTables", payload.numberOfTables.toString());
  formData.append("PhoneNo", payload.phoneNo);
  formData.append("OpeningTime", payload.openingTime);
  formData.append("ClosingTime", payload.closingTime);
  formData.append("Status", payload.status);

  if (payload.clubStaffId) {
    formData.append("ClubStaffId", payload.clubStaffId);
  }

  if (payload.venueImage?.fileContent) {
    formData.append("VenueImage", {
      uri: payload.venueImage.fileContent,
      type: "image/jpeg",
      name: payload.venueImage.fileName,
    });
  }

  return axios.post(formatCoreUrl(`/venue/${id}`), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function deleteVenue(id: number) {
  await axios.delete(formatCoreUrl(`/venue/${id}`));
}

export async function getClubVenues(clubId: number) {
  const res = await axios.get<Venue[]>(formatCoreUrl(`/venue/club/${clubId}`));
  return res.data;
}
