import React from "react";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function EmailIcon(props: InterfaceIconProps) {
  return (
    <Icon size="md" viewBox="0 0 22 16" {...props}>
      <Path
        d="M2.3667 1C1.34066 1 0.5 1.84066 0.5 2.8667V13.1333C0.5 14.1593 1.34066 15 2.3667 15H19.6333C20.6593 15 21.5 14.1593 21.5 13.1333V2.8667C21.5 1.84066 20.6593 1 19.6333 1H2.3667ZM2.3667 1.93324H19.6333C20.1584 1.93324 20.5665 2.34145 20.5665 2.86649V13.1331C20.5665 13.6582 20.1583 14.0663 19.6333 14.0663H2.3667C1.84162 14.0663 1.43345 13.6581 1.43345 13.1331V2.86649C1.43345 2.34141 1.84166 1.93324 2.3667 1.93324ZM18.4521 3.77794C18.3414 3.78803 18.238 3.83719 18.1604 3.91661L11.7 9.59658C11.292 9.95563 10.6925 9.95546 10.2855 9.59658L3.83965 3.91661C3.74704 3.83051 3.62318 3.78575 3.49688 3.79259C3.30629 3.80463 3.14223 3.93175 3.08282 4.11322C3.02325 4.2947 3.08054 4.49408 3.22702 4.61664L7.16451 8.09482L3.23436 11.3687C3.138 11.4478 3.07713 11.5619 3.06525 11.6861C3.05337 11.8101 3.09129 11.9338 3.17088 12.0299C3.25031 12.1259 3.36473 12.1863 3.48891 12.1977C3.6131 12.209 3.73663 12.1705 3.83234 12.0906L7.8792 8.72191L9.66565 10.2969C10.4196 10.9618 11.5584 10.9605 12.3124 10.2969L14.1135 8.71458L18.1677 12.0905C18.2634 12.1704 18.3868 12.209 18.511 12.1976C18.6352 12.1862 18.7496 12.1258 18.829 12.0298C18.9086 11.9338 18.9465 11.8101 18.9347 11.6861C18.9228 11.5619 18.8619 11.4478 18.7656 11.3687L14.8281 8.08745L18.7729 4.6166C18.9399 4.4916 19.0037 4.27089 18.9291 4.07606C18.8546 3.88123 18.6597 3.75955 18.4521 3.77794Z"
        stroke="black"
        strokeWidth="0.5"
      />
    </Icon>
  );
}
