import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Heading,
  VStack,
  Text,
  Image,
  Center,
  Circle,
} from "native-base";
import { useForm } from "react-hook-form";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { CompositeNavigationProp, RouteProp } from "@react-navigation/native";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { AxiosError } from "axios";
import HeaderLayout from "../../../components/Layout/HeaderLayout";
import FormInput from "../../../components/FormInput/FormInput";
import OneColumnPickerModal from "../../../components/Modal/OneColumnPickerModal";
import ThreeColumnPickerModal from "../../../components/Modal/ThreeColumnPickerModal";
import getDistricts from "../../../constants/Districts";
import { hourList, minuteList, getPeriod } from "../../../constants/Time";
import DownArrowIcon from "../../../components/Icons/DownArrowIcon";
import {
  MainStackNavigatorParamList,
  PlayerBottomTabNavigatorParamList,
} from "../../../routers/Types";
import { getTranslation } from "../../../utils/translation";
import FormSwitch from "../../../components/Switch/FormSwitch";
import RectangleImagePicker from "../../../components/ImagePicker/RectangleImagePicker";
import {
  format12HTo24H,
  format24HTo12H,
  validateTimeRange,
} from "../../../utils/date";
import {
  CreateVenueRequest,
  VenueStatus,
} from "../../../models/requests/Venue";
import getArea from "../../../constants/Area";
import { formatFileUrl } from "../../../services/ServiceUtil";
import { deleteVenue, updateVenue } from "../../../services/VenueServices";
import { useAuth } from "../../../hooks/UseAuth";
import { showApiToastError } from "../../../components/ApiToastError";
import SingleSelectModal from "../../../components/Modal/SingleSelectModal";
import TimePicker from "../../../components/v2/TimePicker";
import { showApiToastSuccess } from "../../../components/ApiToastSuccess";
import PencilIcon from "../../../components/Icons/PencilIcon";
import ImageDirectory from "../../../assets";

export type UpdateVenueNavigationProp = CompositeNavigationProp<
  NativeStackNavigationProp<MainStackNavigatorParamList, "ClubUpdateVenue">,
  BottomTabNavigationProp<PlayerBottomTabNavigatorParamList>
>;

export type UpdateVenueRouteProp = RouteProp<
  MainStackNavigatorParamList,
  "ClubUpdateVenue"
>;

interface UpdateVenueScreenProps {
  navigation: UpdateVenueNavigationProp;
  route: UpdateVenueRouteProp;
}

const t = getTranslation([
  "constant.area",
  "constant.district",
  "screen.ClubScreens.Venue",
  "constant.button",
  "validation",
  "formInput",
  "screen.ClubScreens.VenueList",
]);

interface FormData extends CreateVenueRequest {
  districtText: string;
  areaText: string;
  from: string;
  isProvideBall: boolean;
  to: string;
}

export default function UpdateVenue({
  route,
  navigation,
}: UpdateVenueScreenProps) {
  const [isOpen, setIsOpen] = useState({
    area: false,
    district: false,
    fromDate: false,
    toDate: false,
    from: false,
    to: false,
    courseType: false,
    level: false,
  });

  const { venue } = route.params;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    resetField,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      name: venue.name,
      phoneNo: venue.phoneNo,
      area: venue.area,
      areaText: venue.area.toString(),
      district: venue.district,
      districtText: venue.district.toString(),
      address: venue.address,
      numberOfTables: venue.numberOfTables.toString(),
      fee: venue.fee.toString(),
      from: format24HTo12H(venue.openingTime),
      to: format24HTo12H(venue.closingTime),
      isProvideBall: venue.ballsProvided,
    },
  });

  const districts = useMemo(() => {
    return getDistricts();
  }, []);

  const period = useMemo(() => {
    return getPeriod();
  }, []);

  const area = useMemo(() => {
    return getArea();
  }, []);

  const fromTime = watch("from");
  const toTime = watch("to");
  const areaValue = watch("area");
  const districtValue = watch("district");
  const [districtList, setDistrictList] = useState<
    { label: string; value: string }[]
  >([]);
  const areaPlaceholder = t("All areas");
  const districtPlaceholder = t("All districts");
  const setArea = useCallback(
    (newArea: string) => {
      if (newArea === areaPlaceholder) {
        resetField("district");
        resetField("districtText");
        resetField("area");
        return resetField("areaText");
      }
      if (newArea && newArea !== areaPlaceholder) {
        const newLabel = area.reduce<string | null>((ret, val) => {
          if (val.value === newArea) {
            return val.label;
          }
          return ret;
        }, null);
        setValue("areaText", newLabel, { shouldDirty: !!newLabel });
        setValue("area", newArea, { shouldDirty: !!newLabel });
      }
    },
    [area, areaPlaceholder, resetField, setValue]
  );

  const setDistrict = useCallback(
    (newDistrict: string) => {
      if (newDistrict === districtPlaceholder) {
        resetField("district");
        return resetField("districtText");
      }
      if (areaValue && areaValue !== areaPlaceholder) {
        const newLabel = districts[areaValue].reduce<string | null>(
          (ret, val) => {
            if (val.value === newDistrict) {
              return val.label;
            }
            return ret;
          },
          null
        );
        setValue("districtText", newLabel, {
          shouldDirty: !!newLabel,
        });
        setValue("district", newDistrict, {
          shouldDirty: !!newLabel,
        });
      }
    },
    [
      districts,
      areaPlaceholder,
      areaValue,
      districtPlaceholder,
      resetField,
      setValue,
    ]
  );

  useEffect(() => {
    setArea(areaValue);
    setDistrictList(areaValue in districts ? districts[areaValue] : []);
  }, [districts, areaValue, setArea]);

  useEffect(() => {
    setDistrict(districtValue);
  }, [districtValue, setDistrict]);

  const { user } = useAuth();

  const submitForm = async (value: FormData) => {
    try {
      await updateVenue(
        {
          name: value.name,
          area: value.area,
          district: value.district,
          address: value.address,
          phoneNo: value.phoneNo,
          numberOfTables: value.numberOfTables,
          fee: value.fee,
          ballsProvided: value.isProvideBall ? value.isProvideBall : false,
          openingTime: format12HTo24H(value.from),
          closingTime: format12HTo24H(value.to),
          venueImage: value.venueImage,
          status: VenueStatus.Open,
          clubStaffId: user?.id,
        },
        venue.id
      );
      navigation.reset({
        index: 0,
        routes: [
          {
            name: "ClubUpdateVenueSuccess",
            params: {
              destination: "ClubNavigator",
            },
          },
        ],
      });
    } catch (error) {
      showApiToastError(error);
    }
  };

  return (
    <HeaderLayout
      isSticky
      headerProps={{
        title: t("UpdateVenue"),
      }}
    >
      <VStack mx="defaultLayoutSpacing" space="4">
        <RectangleImagePicker
          defaultImageUrl={
            venue.imageUrl ? formatFileUrl(venue.imageUrl) : undefined
          }
          placeholderText={t("Venue Image")}
          controllerProps={{
            name: "venueImage",
            control,
          }}
          manipulateSaveOptions={{
            base64: false,
          }}
          imageProps={{
            width: "100%",
            height: 220,
            borderRadius: 0,
            marginBottom: 8,
          }}
          showClose={false}
          noPhotoComponent={
            <VStack>
              <Image
                source={ImageDirectory.VENUE}
                style={{ width: "100%", height: 220 }}
              />
              <Center
                p="5"
                position="absolute"
                bottom="2"
                right="2"
                bgColor="rs.primary_purple"
                borderRadius="full"
                w="6"
                h="6"
              >
                <Circle position="absolute">
                  <PencilIcon innterFill="white" size="lg" />
                </Circle>
              </Center>
            </VStack>
          }
        />
        <FormInput
          label={t("Venue name")}
          controllerProps={{
            control,
            name: "name",
          }}
        />

        <FormInput
          label={t("Enquiry Phone no")}
          controllerProps={{
            control,
            rules: {
              pattern: {
                value: /^\d{8}$/,
                message: t("Phone number must be an 8-digit number"),
              },
            },
            name: "phoneNo",
          }}
          inputProps={{ keyboardType: "numeric" }}
        />
        <FormInput
          label={t("Venue Area")}
          controllerProps={{
            name: "areaText",
            control,
            rules: {},
          }}
          inputProps={{
            editable: false,
            InputRightElement: <DownArrowIcon mr="4" />,
          }}
          onPress={() => {
            setIsOpen((prev) => ({ ...prev, area: true }));
          }}
        />
        <FormInput
          label={t("Venue district")}
          controllerProps={{
            name: "districtText",
            control,
          }}
          inputProps={{
            editable: false,
            InputRightElement: <DownArrowIcon mr="4" />,
          }}
          onPress={() => {
            setIsOpen((prev) => ({ ...prev, district: true }));
          }}
        />
        <FormInput
          label={t("Venue address")}
          controllerProps={{
            control,
            name: "address",
          }}
          inputProps={{ multiline: true }}
        />

        <FormInput
          label={t("Number of table")}
          controllerProps={{
            name: "numberOfTables",
            control,
            rules: {
              pattern: {
                value: /^\d*[1-9]\d*$/,
                message: t("Value should contains positive number only"),
              },
            },
          }}
          inputProps={{ keyboardType: "numeric" }}
        />

        <FormInput
          label={t("Rate ($/hr)")}
          controllerProps={{
            control,
            rules: {
              pattern: {
                value: /^\d*[1-9]\d*$/,
                message: t("Value should contains positive number only"),
              },
            },
            name: "fee",
          }}
          inputProps={{ keyboardType: "numeric" }}
        />

        <Heading>{t("Opening hours")}</Heading>
        <FormInput
          label={t("From")}
          controllerProps={{
            name: "from",
            control,
            rules: {},
          }}
          inputProps={{
            editable: false,
            InputRightElement: <DownArrowIcon mr="4" />,
          }}
          onPress={() => {
            setIsOpen((prev) => ({ ...prev, from: true }));
          }}
        />
        <FormInput
          label={t("To")}
          controllerProps={{
            name: "to",
            control,
            rules: {},
          }}
          inputProps={{
            editable: false,
            InputRightElement: <DownArrowIcon mr="4" />,
          }}
          onPress={() => {
            setIsOpen((prev) => ({ ...prev, to: true }));
          }}
        />
        <FormSwitch
          title={t("Ball provided?")}
          onText={t("Yes")}
          offText={t("No")}
          controllerProps={{
            name: "isProvideBall",
            control,
          }}
        />
        <Button
          isLoading={isSubmitting}
          isLoadingText={t("Loading")}
          onPress={handleSubmit(submitForm)}
        >
          {t("Save")}
        </Button>
        <Button
          mt="3"
          variant="outline"
          onPress={async () => {
            try {
              await deleteVenue(venue.id);
              showApiToastSuccess({
                title: t("Your venue is deleted"),
                body: "",
              });
              navigation.goBack();
            } catch (error) {
              showApiToastError(error);
            }
          }}
        >
          {t("Delete")}
        </Button>

        <SingleSelectModal
          isOpen={isOpen.area}
          onClose={() => {
            setIsOpen((prev) => ({ ...prev, area: false }));
          }}
          title={`${t("Select")}${t("Area")}`}
          options={area}
          controllerProps={{
            name: "area",
            control,
          }}
          confirmButtonText={t("Confirm")}
        />

        {/* <OneColumnPickerModal
          isOpen={isOpen.district}
          onClose={() => {
            setIsOpen((prev) => ({ ...prev, district: false }));
          }}
          headerLabel={`${t("Select")}${t("District")}`}
          buttonLabel={t("Confirm")}
          options={districtList}
          placeholder={districtPlaceholder}
          controllerProps={{
            name: "district",
            control,
          }}
        /> */}
        <SingleSelectModal
          isOpen={isOpen.district}
          onClose={() => {
            setIsOpen((prev) => ({ ...prev, district: false }));
          }}
          title={`${t("Select")}${t("District")}`}
          confirmButtonText={t("Confirm")}
          options={districtList}
          controllerProps={{
            name: "district",
            control,
          }}
        />

        <TimePicker
          isOpen={isOpen.from}
          onClose={() => {
            setIsOpen((prev) => ({ ...prev, from: false }));
          }}
          headerLabel={`${t("Select")}${t("Start Time")}`}
          options={[hourList, minuteList, period]}
          concatenator={[":", " "]}
          controllerProps={{
            name: "from",
            control,
            rules: {
              validate: {
                withInRange: (v) => {
                  if (v && toTime) {
                    return (
                      validateTimeRange(v, toTime) ||
                      t("from time must be earlier than the to time")
                    );
                  }
                },
              },
            },
          }}
        />

        <TimePicker
          isOpen={isOpen.to}
          onClose={() => {
            setIsOpen((prev) => ({ ...prev, to: false }));
          }}
          headerLabel={`${t("Select")}${t("End Time")}`}
          options={[hourList, minuteList, period]}
          concatenator={[":", " "]}
          controllerProps={{
            name: "to",
            control,
            rules: {
              validate: {
                withInRange: (v) => {
                  if (v && fromTime) {
                    return (
                      validateTimeRange(fromTime, v) ||
                      t("from time must be earlier than the to time")
                    );
                  }
                },
              },
            },
          }}
        />
      </VStack>
    </HeaderLayout>
  );
}
