import React from "react";
import { Heading, HStack, VStack, Text, Image } from "native-base";
import { SCREEN_WIDTH } from "../../constants/constants";
import { VenueBooking } from "../../models/Booking";
import { formatFileUrl } from "../../services/ServiceUtil";
import { formatUtcToLocalDate, formatUtcToLocalTime } from "../../utils/date";
import { getTranslation } from "../../utils/translation";
import CalendarIcon from "../Icons/CalendarIcon";
import ClockIcon from "../Icons/ClockIcon";
import LocationIcon from "../Icons/LocationIcon";
import MoneyIcon from "../Icons/MoneyIcon";
import ImageDirectory from "../../assets";

const t = getTranslation([
  "constant.district",
  "component.VenueBookingDetails",
]);

export default function VenueBookingDetails({ data }: { data: VenueBooking }) {
  const { venue, fromTime, toTime } = data;
  return (
    <VStack mt="5" space="3">
      <VStack>
        <Heading mb="5">{t("Applied Venue")}</Heading>
        {venue && (
          <Image
            source={
              venue.imageUrl
                ? { uri: formatFileUrl(venue.imageUrl) }
                : ImageDirectory.VENUE
            }
            width={SCREEN_WIDTH}
            height={170}
            resizeMode="cover"
            alt={t("Venue Photo")}
            style={{ borderRadius: 10 }}
          />
        )}
      </VStack>

      <Heading>{venue.name}</Heading>

      <HStack alignItems="flex-start" space="3">
        <HStack mt="1">
          <LocationIcon />
        </HStack>
        <VStack alignItems="flex-start">
          <Text fontSize="md">{t(venue.district)}</Text>
          <Text fontSize="md">{venue.address}</Text>
        </VStack>
      </HStack>

      <HStack alignItems="center" space="3">
        <MoneyIcon />
        <Text fontSize="md">
          {venue.fee} {t("hkd")}
        </Text>
      </HStack>

      <Heading fontSize="md" color="rs.primary_purple" mt="3">
        {t("Selected date")}
      </Heading>
      <HStack alignItems="center" space="3">
        <CalendarIcon />
        <Text fontSize="md">{formatUtcToLocalDate(fromTime)}</Text>
      </HStack>

      <Heading fontSize="md" color="rs.primary_purple" mt="3">
        {t("Selected time")}
      </Heading>
      <HStack alignItems="center" space="3">
        <ClockIcon />
        <Text fontSize="md">
          {formatUtcToLocalTime(fromTime)} - {formatUtcToLocalTime(toTime)}
        </Text>
      </HStack>
    </VStack>
  );
}
