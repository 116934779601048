import React, { useState } from "react";
import useSWR from "swr";
import { useTheme, VStack, Button, Toast } from "native-base";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { MainStackNavigatorParamList } from "../../routers/Types";
import { formatMeetupApiUrl } from "../../services/ServiceUtil";
import {
  deleteVenueBooking,
  getVenueBookingById,
} from "../../services/VenueBookingServices";
import HeaderLayout from "../../components/Layout/HeaderLayout";
import VenueBookingDetails from "../../components/VenueBookingDetails";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import { getTranslation } from "../../utils/translation";
import { VenueBookingStatus } from "../../models/Booking";
import MessageToast, {
  MesssageToastType,
} from "../../components/Toast/MessageToast";
import { showApiToastError } from "../../components/ApiToastError";

type VenueBookingDetailScreenProps = NativeStackScreenProps<
  MainStackNavigatorParamList,
  "VenueBookingDetail"
>;

const t = getTranslation([
  "constant.district",
  "screen.PlayerScreens.VenueBookingDetails",
  "constant.button",
]);

export default function VenueBookingDetail({
  route,
  navigation,
}: VenueBookingDetailScreenProps) {
  const { space } = useTheme();
  const { venueBooking, venueBookingId } = route.params;
  const { data, isValidating, error } = useSWR(
    venueBookingId ? formatMeetupApiUrl("/venue/{id}") : null,
    () => getVenueBookingById(venueBookingId!)
  );

  const [isCanceling, setIsCanceling] = useState(false);

  const val = venueBooking || data;
  const shouldShowCancel =
    val?.status !== VenueBookingStatus.Rejected &&
    val?.status !== VenueBookingStatus.Cancelled;

  return (
    <HeaderLayout
      isSticky
      headerProps={{
        title: t("Request Venue"),
      }}
      containerProps={{
        marginHorizontal: space.defaultLayoutSpacing,
      }}
    >
      {isValidating && <Loading />}
      {!isValidating && error && <ErrorMessage />}
      {!isValidating && val && (
        <VStack space="4">
          <VenueBookingDetails data={val} />
          {shouldShowCancel && (
            <Button
              isDisabled={isCanceling}
              variant="outline"
              mt="auto"
              onPress={async () => {
                try {
                  setIsCanceling(true);
                  await deleteVenueBooking(val.id);
                  setIsCanceling(false);
                  Toast.show({
                    id: "playerDeleteVenueSuccess",
                    duration: 2000,
                    placement: "top",
                    render: () => {
                      return (
                        <MessageToast
                          type={MesssageToastType.Success}
                          title={t("Delete success")}
                        />
                      );
                    },
                  });
                  navigation.goBack();
                } catch (e) {
                  setIsCanceling(false);
                  console.log(e);
                  showApiToastError(e);
                }
              }}
            >
              {t("Cancel")}
            </Button>
          )}
        </VStack>
      )}
    </HeaderLayout>
  );
}
