import WebView from "react-native-webview";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";

export default function WebPortal() {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <WebView
        source={{
          uri: "https://webportal.remarkable-sports.com/mobile-competition-list",
        }}
      />
    </SafeAreaView>
  );
}
