export default {
  constant: {
    ChatGPT: {
      Serve: "發球",
      Receive: "接發球",
      Forehand: "正手",
      Backhand: "反手",
      Footwork: "步法",
      Rules: "規則",
      Spin: "旋轉",
    },
    // Actions
    button: {
      Next: "下一頁",
      Approve: "批准",
      "Quick Approve": "快速確認",
      "Bulk Approve": "批量審核",
      Reject: "拒絕",
      Edit: "更改",
      Cancel: "取消",
      Yes: "是",
      No: "不是",
      Confirm: "確認",
      Done: "完成",
      Delete: "刪除",
      Submit: "提交",
      Process: "處理",
      Continue: "繼續",
      Create: "新增",
      Add: "新增",
      Apply: "申請",
      Update: "更新",
      Save: "儲存",
      Accept: "確認",
      View: "查看",
      Manage: "管理",
      "View Profile": "查看簡介",
      Remove: "移除",
      Skip: "跳過",
      Refund: "退款",
      Success: "成功",
      "Re-apply": "加堂",
      Loading: "載入中",
      Verify: "驗證",
    },
    eventType: {
      Competition: "比賽",
      Others: "其他",
      OpenDay: "公開日",
      GroupBallGame: "球局",
      Single: "單打",
      Double: "雙打",
      Team: "團體",
    },
    tabType: {
      Existing: "現有",
      Past: "已結束",
      Pending: "待確認",
      Upcoming: "即將進行",
      Completed: "已完成",
      All: "所有",
    },
    area: {
      HK: "香港",
      KLN: "九龍",
      NT: "新界",
    },
    dummyUser: {
      "Trial Account": "試用賬戶",
      "Click Verify to convert your trial account to a verified account":
        "驗證你的試用賬戶",
      "Getting Started": "開始",
      "Update your profile to connect with other players, coaches and clubs":
        "更新你的個人資料",
    },
    date: {
      h: "h",
      Now: "Now",
      m: "m",
      d: "d",
      w: "w",
      y: "y",
      now: "現在",
      "an hour ago": "1小時前",
      "a second ago": "1秒前",
      "# seconds ago": "# 秒前",
      "a minute ago": "1分鐘前",
      "in a second": "1秒內",
      "in # seconds": "# 秒內",
      "# minutes ago": "# 分鐘前",
      "An hour ago": "1小時前",
      "# hours ago": "# 小時前",
      "in a minute": "1分鐘內",
      "in # minutes": "# 分鐘內",
      "in an hour": "1小時內",
      "in # hours": "# 小時內",
      yesterday: "昨天",
      "# days ago": "# 天前",
      tomorrow: "明天",
      "in # days": "# 天內",
      "last week": "上星期",
      "# weeks ago": "# 星期前",
      "in a week": "1星期內",
      "in # weeks": "# 星期內",
      "last month": "上個月",
      "# months ago": "# 個月前",
      "in a month": "1個月內",
      "in # months": "# 個月內",
      "last year": "上年",
      "# years ago": "# 年前",
      "in a year": "1年內",
      "in # years": "# 年內",
    },
    district: {
      HK: {
        CW: "中西區",
        EA: "東區",
        SO: "南區",
        WC: "灣仔區",
        KC: "九龍城區",
        KU: "觀塘區",
        SS: "深水埗區",
        WT: "黃大仙區",
        YT: "油尖旺區",
        IS: "離島區",
        KI: "葵青區",
        NO: "北區",
        SK: "西貢區",
        ST: "沙田區",
        TP: "大埔區",
        TW: "荃灣區",
        TM: "屯門區",
        YL: "元朗區",
      },
    },
    cancellationPeriod: {
      "24": "24小時",
      "48": "48小時",
      "72": "72小時",
    },
    week: {
      MON: "一",
      TUE: "二",
      WED: "三",
      THU: "四",
      FRI: "五",
      SAT: "六",
      SUN: "日",
      Mon: "一",
      Tue: "二",
      Wed: "三",
      Thu: "四",
      Fri: "五",
      Sat: "六",
      Sun: "日",
    },
    weekLong: {
      MON: "一",
      TUE: "二",
      WED: "三",
      THU: "四",
      FRI: "五",
      SAT: "六",
      SUN: "日",
      Mon: "一",
      Tue: "二",
      Wed: "三",
      Thu: "四",
      Fri: "五",
      Sat: "六",
      Sun: "日",
    },
    profile: {
      // Enum
      Email: "電郵",
      "Chinese Name": "中文全名",
      Female: "女",
      "View profile": "查看簡介",
      "No Rank": "沒有排名",
      Male: "男",
      Left: "左手",
      "Left hand": "左手",
      "Left Hand": "左手",
      Right: "右手",
      "Right hand": "右手",
      "Right Hand": "右手",
      Attacker: "搶攻型",
      Defender: "防守型",
      "All Rounded": "全面型",
      AllRounded: "全面型",
      Chopper: "削球",
      Shakehand: "橫板",
      Penhold: "直板",
      Inverted: "反膠",
      "Short Pips": "正膠（粒仔）",
      "Medium Pips": "生膠",
      "Long Pips": "長膠",
      ShortPips: "正膠（粒仔）",
      MediumPips: "生膠",
      LongPips: "長膠",
      Antispin: "防弧",
      Beginner: "初級",
      GroupE: "戊組",
      GroupD: "丁組",
      GroupC: "丙組",
      GroupB: "乙組",
      GroupA: "甲組",
      BeginnerExcellence: "初級(優)",
      "Beginner Excellence": "初級(優)",
      Intermediate: "中級",
      IntermediateExcellence: "中級(優)",
      "Intermediate Excellence": "中級(優)",
      Advanced: "高級",
      HKTeam: "香港代表隊",
      "HK Team": "香港代表隊",
      // Terms
      "Profile name": "簡介名稱",
      "First name": "英文名字",
      "Last name": "英文姓氏",
      "First Name": "英文名字",
      "Last Name": "英文姓氏",
      "Phone Number": "電話號碼",
      License: "教練編號",
      "License No": "教練編號",
      "District(s)": "地區",
      Districts: "執教地區",
      Address: "地址",
      Description: "描述",
      "Hand Used": "握拍手",
      Style: "打法",
      Blade: "握拍法",
      Rubber: "正手膠皮類型",
      "Back Rubber": "反手膠皮類型",
      "Back rubber": "反手膠皮類型",
      Ranking: "排名",
      "HKTTA ID": "球員註冊編號",
      "Player Level": "球員水平",
      Level: "球員水平",
      "Coach Level": "教練級數",
      Fee: "收費 ($/小時)",
      Achievement: "成就",
      Achievements: "成就",
      Mobile: "電話號碼",
      "Date of Birth": "出生日期",
      Sex: "性別",
      // Form Input
      "Edit Profile": "編輯簡介",
      "Select Blade": "選擇握拍法",
      "Select Rubber": "選擇正手膠皮類型",
      "Select Back Rubber": "選擇反手膠皮類型",
      "Select Level": "選擇水平",
      "Add achievement": "添加成就",
      "Select at least 1 district": "請選擇至少一個地區",
      "Select date of birth": "選擇出生日期",
      "Select Sex": "選擇性別",
    },
  },
  validation: {
    "is required": "是必須的",
    "Must be a number": "必須是數字",
    "Must be an 8-digit number": "必須是 8 位數字",
    "Must be a 4-digit number": "必須是 4 位數字",
    "Date should be in the future": "請不要選擇過去的日子",
    "Value should contains positive number only": "請填寫正數",
    "Out of index of sessions": "日子錯誤",
    "from time must be earlier than the to time": "結束時間必須遲於開始時間",
    "Email does not match": "電郵不匹配",
    "Please enter a valid email address": "請輸入有效的電郵地址",
  },
  formInput: {
    CreateLeague: "新增聯賽",
    CreateEvent: "新增活動",
    UpdateEvent: "更新活動",
    Event: "活動",
    Venue: "場地",
    Course: "訓練班",
    Select: "選擇",
    "Start Time": "開始時間",
    "End Time": "結束時間",
    Time: "時間",
    Date: "日期",
    District: "地區",
    Type: "類型",
    Area: "區域",
    Name: "名稱",
    Capacity: "名額",
    Address: "地址",
    Description: "詳情",
    Photo: "圖片",
    "Files smaller than 5mb": "圖片需要小於 5mb",
    "Basic Info": "基礎資料",
    Price: "價錢",
    Search: "搜尋",
    Filter: "篩選",
  },
  toastMessage: {
    Successful: "成功",
    Successfully: "成功",
    Success: "成功",
  },
  leagueTerms: {
    "Fixture and Result": "賽程及賽果",
    "No Fixtures & Result": "沒有賽程及賽果",
    Statistics: "統計",
    League: "聯賽",
    Season: "賽季",
    Tier: "級別",
    Division: "組別",
    Round: "回合",
    Import: "匯入",
    Export: "匯出",
    Table: "隊伍",
    Fixture: "賽程",
    Result: "賽果",
    Team: "隊伍",
    Match: "比賽",
    "Home Team": "主隊",
    "Away Team": "客隊",
    SubmitResult: "提交賽果",
    EditTeam: "更改隊伍",
    Player: "球員",
    Teams: "隊伍",
    Audience: "觀眾",
    "View the fixture, results and statistics for different leagues":
      "查看各聯賽的賽程，賽果及統計",
    "Join your team to view your schedule and manage the results":
      "加入隊伍查看賽程及管理賽果",
    "Fixtures & Results": "賽程 & 賽果",
    "Player List": "球員名單",
    "View Profile": "查看簡介",
    "Matches Played": "參與場數",
    "Matches Won": "勝利場數",
    "Winning Rate": "勝率",
    Ranking: "排名",
    "Total Points": "總分",
    "Recent Matches": "近期比賽",
    Point: "分",
    Set: "局",
    "Team name": "隊伍名稱",
    "Team List": "隊伍名單",
    "Fixture and Result Filter": "篩選賽程及賽果",
    "Filter Results": "篩選賽果",
    "Upcoming Fixtures": "即將進行的賽事",
    "View All": "查看所有",
    "No Upcoming Fixture": "沒有賽程",
    "Team Management": "管理隊伍",
    "Submit Results": "提交賽果",
    "Review Results": "審核賽果",
    "Join Team": "加入隊伍",
    "Confirm to remove": "確認移除",
    Players: "球員",
    Draft: "草稿",
    Pending: "待審核",
    Published: "已發佈",
    "Click to Input": "按此輸入",
    "Pending Approval": "等待審核中",
    "Organizer Reviewing": "主辦方處理中",
    "Click to Review": "按此審核",
    "Canceled to Join Team": "取消加入隊伍申請",
    "You will not be able to manage the results before you joined a team":
      "請先加入隊伍，沒有隊伍的球員不能管理賽果",
    "Applied to Join Team": "成功申請加入隊伍",
    "Organizers Team members will review your application":
      "主辦方 / 其他隊員會審核你的申請",
    Join: "加入",
    Cancel: "取消",
  },
  screen: {
    ClubHomeV2: {
      Course: "訓練班",
      Event: "活動",
      "Course Applications": "訓練班申請",
      Details: "詳情",
      Applications: "申請",
      "Leave Applications": "請假申請",
      "Payment Evidences": "支付憑證",
      "Date of First Session": "第一堂日期",
      "Number of applied sessions": "報名堂數",
      "Date & Time": "日期與時間",
      Address: "地址",
      Applicant: "申請者",
      "Applied Course": "申請的訓練班",
      "Course Leave Applications": "請假申請",
      "Course Payment Evidence": "訓練班支付憑證",
      "View evidence": "查看憑證",
      "Event Applications": "活動申請",
      "Event Payment Evidence": "活動支付憑證",
      "No Payment Evidence": "沒有支付憑證",
      "There is no payment evidence pending for review": "沒有待審核的支付憑證",
      "No Applications": "沒有申請",
      "There is no application pending for approval": "沒有待審核的申請",
      "No Leave Applications": "沒有請假申請",
      "There is no leave applications pending for approval":
        "沒有待審核的請假申請",
      "Hosted by": "舉辦球會",
      "Upcoming Session": "下一堂",
      "Your club does not have right to create event yet Please contact us for access":
        "你的球會沒有創建活動的權限，如有需要請聯絡GoPingPong",
    },
    LeaguePlayerDivision: {
      "View your recent matches and manage applications for your team":
        "查看近期賽事及管理你的隊伍申請",
      "As a home team player, you have to submit the results and pass to away team players to confirm":
        "主隊球員需要負責提交賽果",
      "As a away team player, you have to acknowledge the results inputted by home team players":
        "客隊球員需要負責審核由主隊球員提交的賽果",
      "You have joined team in other division, please use audience view to check all fixtures and results in this division":
        "你已加入其他隊伍，請使用觀眾視角查看此分組的賽程及賽果",
      "Switch to Audience View": "進入觀眾視角",
      "Join your team to view your recent matches and gain access to manage results":
        "加入隊伍，你可以查看隊伍的近期賽事及管理賽果",
    },
    ReviewCoursePaymentEvidence: {
      "Payment evidence": "支付憑證",
    },
    ManageCourseSessionPlayer: {
      "Move Session": "調堂",
      "Sessions left": "餘下堂數",
      "Manage Player": "管理球員",
      "Upcoming Session": "下一堂",
      Moved: "已調堂",
    },
    NewUpdateAvailable: {
      "We added new features":
        "GoPingPong 添加了新的功能並優化了應用程式，立刻更新去體驗新功能！",
      "DISCOVER NEW VERSION": "查看新版本",
      Update: "更新",
    },
    PreviewCourseSession: {
      Group: "循環時段",
      "Preview Session": "預覽課堂",
      "Is there a fee for the course": "新增支付方式？",
      "Add payment method": "新增",
    },
    AddCourseSession: {
      Removed: "已移除",
      "Tap here to manage players that applied to this removed session":
        "按此管理已報名此課堂的球員",
      Edited: "已更改",
      Group: "循環時段",
      "Add sessions": "新增課堂",
      "Add Session": "新增課堂",
      "Confirm to save changes?": "確認儲存變更？",
      "Leave Page?": "離開頁面？",
      "Changes you made may not be saved": "你所做的變更可能不會儲存",
      "Saved successfully": "儲存成功",
      "Remove successfully": "移除成功",
      "Confirm to remove session": "確認取消課堂",
      From: "開始",
      To: "結束",
      Date: "日期",
      "players applied this session": "位球員報名此課堂",
      "All players that applied to this session has been managed, click continue to proceed":
        "所有報名此課堂的球員已成功處理，請點擊繼續",
    },
    ChatGPT: {
      ChatPingPong: "ChatPingPong",
      "Select a topic": "選擇一個話題",
      "ChatPingpong covers different topic related to table tennis, select one of them and explore with ChatPingPong":
        "選擇一個話題與 ChatPingPong 對話吧！",
    },
    ChatGPTWithSelectedTopic: {
      Select: "選擇",
      Topic: "話題",
      "Start typing": "開始輸入...",
      Youtube: "YouTube",
      "You have reached the maximum limit with ChatPingPong":
        "你已達到本日對話上限",
      "Watch our latest video now": "觀看我們最新的影片",
      "Look for coach now": "揾教練",
      "Check for availability now": "查看訓練班",
      "Apply to an event now": "參與活動",
      "Something usual has happened and it takes longer than expected to load Please enter the question again":
        "加載時間比預期長，請重新輸入問題",
    },
    OrganizerScreens: {
      LeagueHome: {
        "Tap here to create a new league and division for player to join teams":
          "按此新增聯賽及各組別",
        "No league now": "沒有聯賽",
        "Please select a league": "請選擇聯賽",
        "Created league": "已新增聯賽",
        Statistic: "統計",
      },
      // Add League & Update League
      League: {
        "Basic info": "基礎資料",
        "League name": "聯賽名稱",
        "You can now proceed to add teams into their divisions":
          "請前往增加隊伍到其組別",
        Logout: "登出",
      },
      ManageDivision: {
        "No match result found for this division": "沒有賽果",
        "Approve results that have been submitted by the home team and acknowledged by the away team":
          "請審核以下賽果",
        "Upload fixtures from time-to-time to keep the fixtures up-to-date or you can simply click Edit below":
          "你可以隨時匯入最新的賽程，或在下面更改賽程",
        "Team name": "隊伍名稱",
        "Finish editing": "完成更改",
        "Are you sure to delete this team": "你確定要刪除此隊伍？",
        "Pending approval": "待審核",
        "Warning Deleting the team may result in loss of results":
          "重要提示! 刪除隊伍可能會影響其他賽果!",
        "Approve Request": "審核申請",
        "You may click to the team and check all application": "查看隊伍的申請",
        "No fixture now": "沒有賽程",
        "Please upload the fixtures through a csv file":
          "請透過.csv 檔案匯入賽程",
        "Browse file": "瀏覽檔案",
        "You have successfully add teams to the division":
          "你已成功新增隊伍到此組別",
        "You have successfully edit teams to the division":
          "你已成功更改此組別的隊伍",
      },
      UpdateMatchResult: {
        Other: "其他",
        "Match result": "比賽結果",
        "Basic Info": "基礎資料",
        "Reject reason": "反對原因",
        "Game result": "比賽結果",
        "Are you sure to approve the match results": "確認批准比賽結果？",
        "Points from each match": "場次分",
        "Points from no of players": "人頭分",
        "Additional points": "額外分",
        Sets: "局分",
        "Match results are successfully updated": "比賽結果已成功更新",
        "Match results will be posted on Leaderboard": "賽果將於排行榜上顯示",
        Set: "局",
        "Are you sure to accept the match results?": "確認賽果？",
        "The result will be reviewed by the Organizer": "主辦方將會核對賽果",
        "onboarding as a MatchResult": "",
      },

      ResultApproval: {
        ResultApproval: "賽果審核",
        Rejection: "反對",
        Agreement: "同意",
        "No pending approval": "沒有待審核的賽果",
        "No rejection": "沒有反對",
        "You can approve all the result in one go": "你可以批量審核賽果",
        "Back to Result page": "回到賽果頁面",
        "Are you sure to bulk approve the match results": "確認批量審核賽果？",
        "Match results will be posted on Leaderboard": "賽果會在排行榜上更新",
      },
      TeamRequest: {
        Request: "申請",
        Confirmed: "已確認",
        "Reject request": "拒絕申請",
        "Please provide reason for rejecting": "請提供拒絕原因",
        "There is no applying request": "沒有隊伍申請",
        "There is no member in this team": "隊伍沒有成員",
      },
      EditFixture: {
        Venue: "地點",
      },
    },
    // Add Event & Update Event
    Event: {
      "The event date must be greater than now at least %{number} hour":
        "活動日期必須晚於現在至少 %{number} 小時",
      "Is there a fee for the event": "向參與活動者收取費用?",
      "Yes, add payment method": "增加付款方式",
      "No, it is free": "跳過",
      "Deadline for application (Optional)": "申請截止日(選填)",
      "You can check the created event in Manage tab you can edit delete the event afterward":
        "你可以在管理分頁中更改或刪除此活動",
      "Latest cancellation period (Optional)": "最後取消期(選填)",
      "Event creating": "新增活動",
      "Create another Event": "繼續新增活動",
      "Manage created event": "管理活動",
      "Event capacity must be greater than 0 and an integer":
        "名額必須是大於0的整數",
      "Does it require approval": "報名需要審批嗎?",
      "No permission": "沒有權限",
      "Competition type": "比賽類型",
      hours: "小時",
      From: "開始時間",
      To: "結束時間",
      hrs: "小時",
      Deleted: "已刪除",
      "Edit payment method": "編輯支付方式",
      Updated: "已更新",
      "Updated successfully": "更新成功",
      "Once the event delete participants are not longer to reach this event":
        "當你刪除此活動，其他用戶將無法再獲取到該活動",
      "Are you sure to delete this event you created": "你確定要刪除此活動嗎?",
      "Yes, delete": "是的, 刪除",
      "Create content for this event?": "新增活動資訊？",
      "Add Linkage": "新增資訊",
      Skip: "跳過",
      "Sync with club venue": "使用球會場地",
      "Club Venue": "球會場地",
      "No Venue Created": "沒有創建場地",
      "There is no venue created under your club Go to venue tab and add a venue":
        "你的球會沒有創建場地，請先去創建場地",
      OK: "好",
    },
    AddPaymentMethod: {
      "Payment method": "支付方式",
      "Fee (HKD)": "費用(港元)",
      "Are you sure to leave this page without saving":
        "你確定不保存就離開此頁面嗎?",
      "Back to edit event": "返回更改活動",
      "Add Linkage": "新增資訊",
      Skip: "跳過",
      Bank: "銀行",
      Others: "其他",
      "Create content for this event?": "新增活動資訊？",
      "Changes you made may not be saved": "你所做的變更可能不會儲存",
      "Leave page": "離開頁面？",
    },
    PaymentEvidence: {
      "Payment Evidence": "支付憑證",
      "Please provide reason for rejecting the payment": "請提供拒絕理由",
      "Approve and mark as paid": "同意並標記為已支付",
      "Reject and provide reason": "請提供拒絕理由",
      Success: "成功",
      Failed: "失敗",
      "No Evidence fetched. Please try again": "沒有獲取憑證，請再嘗試",
    },
    AddParticipant: {
      "Add Participant": "添加參與者",
      "Player %{number} name": "球員名字 %{number}",
      "Player name": "球員名字",
      "Team name": "隊伍名字",
      "Add player": "添加球員",
      "Add successfully": "添加成功",
      "You have successfully add participants to the event": "添加參與者成功",
    },
    AddCoursePlayer: {
      "No Permissions": "沒有權限",
      "Add Participant": "添加參與者",
      "Player %{number} name": "球員名字 %{number}",
      "Player name": "球員名字",
      "Team name": "隊伍名字",
      "Add player": "添加球員",
      "Add successfully": "添加成功",
      "You have successfully add participants to the event": "添加參與者成功",
      "Start Date": "開始日期",
      "Number of Sessions": "堂數",
    },
    PaymentStatus: {
      "Payment status": "支付狀態",
      "Check all the evidences and you can kick out player who didnt pay here":
        "檢查所有憑證，你可以把沒有支付的球員剔除出去.",
      "Review evidence": "檢視收據",
      "Pending approval": "等待批准",
      Paid: "已付款",
      "Not Paid": "尚未付款",
      "No Evidence fetched": "沒有獲取憑證，請再嘗試",
      "There is no payments with status": "沒有人",
      "Check evidence": "檢查憑證",
      "Kick out": "踢出",
      "Review Evidence": "檢視收據",
      "Kick out player successfully": "成功移除球員",
    },
    NotificationCentre: {
      Notification: "通知",
      "No Notifications": "沒有通知",
      "Mark all as read": "標記為已讀",
    },
    AdminScreens: {
      AdminHome: {
        "Remarkable Sports": "GoPingPong",
        Logout: "Logout",
        refresh: "Refresh",
        firstName: "firstName",
        lastName: "lastName",
        gender: "gender",
        mobile: "mobile",
        hkttaid: "hkttaid",
        "No pending onboarding requests": "No pending onboarding requests",
      },
      AdminClub: {
        "Club Request": "Club Request",
        Refresh: "Refresh",
        ApprovalStatus: "ApprovalStatus",
        "Club Name": "Club Name",
        "Club Address": "Club Address",
        ClubCoaches: "ClubCoaches",
        District: "District",
        CreatedAt: "CreatedAt",
        "No pending onboarding requests": "No pending onboarding requests",
      },
      ApproveSuccess: {
        "Confirmed!": "Confirmed!",
        "Applicant will received the notice now":
          "Applicant will received the notice now.",
        Back: "Back",
      },
      CoachApproval: {
        "Coach details": "Coach details",
        "Reject request": "Reject request",
        "Please provide": "Please provide",
        error: "error",
      },
      ClubApproval: {
        "Club details": "球會詳情",
        "Reject request": "Reject request",
        "Please provide": "Please provide",
        error: "error",
      },
      RejectSuccess: {
        "Rejected!": "Rejected!",
        "Applicant will receive the notice now":
          "Applicant will receive the notice now.",
        Back: "Back",
      },
      AdminPermission: {
        Player: "Player",
        Coach: "Coach",
        Clubstaff: "Clubstaff",
        GoPingPong: "GoPingPong",
        Refresh: "Refresh",
        Grant: "Grant",
        Revoke: "Revoke",
        "Are you sure to revoke this permission?":
          "Are you sure to revoke this permission?",
        Successful: "Successful",
        Content: "內容",
        Event: "活動",
      },
    },
    ClubScreens: {
      ChangeCourseSession: {
        "Change Sessions": "更換課堂",
        "Move to Another Course": "更換到其他訓練班",
        "New Session Details": "新課堂詳情",
        "Date of Original Session": "原有課堂的日期",
        "Date of Session": "課堂日期",
        "Course Name": "訓練班名稱",
        "Confirm to save changes": "確認儲存變更？",
        "No permissions": "沒有權限",
        FULL: "滿額",
        Original: "原有",
        to: "至",
        New: "最新",
      },
      EditCourseSession: {
        "Edit Sessions": "更改課堂",
        Change: "更改",
        "Confirm to remove session": "確認取消課堂?",
      },
      ManageCoachSalary: {
        "Coach Salary": "教練薪金",
        "Private ($/hr)": "私人 ($/小時)",
        "Head Coach ($/hr)": "主教練 ($/小時)",
        "Assistant Coach ($/hr)": "助教 ($/小時)",
        "Confirm to save changes": "確認儲存變更",
        "Price should between $%{min} - $%{max}": "薪金應是 $%{min} - $%{max}",
      },
      PlayerBreakdown: {
        "Player Breakdown": "球員報告",
        "Player Name": "球員姓名",
        PlayerActive: "活躍",
        PlayerInactive: "非活躍",
        CourseActive: "進行中",
        CoursePast: "過去",
        Expenditure: "消費金額",
        Status: "狀態",
        Unpaid: "未付金額",
        Past: "過去",
      },
      CoachBreakdown: {
        Coach: "教練",
        "Working Hours": "教學時數",
        Salary: "薪金",
        "Club Profit": "球會盈利",
        "Coach Performance": "教練表現",
        Individual: "個人",
        "Sub Total": "總共",
        Month: "月份",
        "Select date": "選擇日期",
      },
      ManageVenue: {
        "Manage Venue": "管理場地",
        Applications: "申請",
        "There is no application for this venue": "沒有此場地的申請",
      },
      Calendar: {
        Calendar: "日曆",
        "Confirm to delete this activity?": "確認取消活動嗎?",
        "The activity will be deleted permanently in the calendar":
          "這活動會被永久取消",
        "Yes, confirm": "是的，確認",
        "+ add": "+ 加",
        Club: "球會",
        Personal: "個人",
      },
      ManageEvent: {
        "Please provide reason for rejecting the payment": "請提供拒絕原因",
        "Approve and mark as paid": "批准，標記為已付款",
        "Reject and provide reason": "拒絕，提供原因",
        Price: "價錢",
        "Day %{number}": "第%{number}天",
        "Event day": "活動日",
        "There is no event days found": "沒有發現活動日",
        "Remove participant Success": "移除參與者成功",
        "Select event session": "選擇一場活動",
        "Please provide reason for rejecting the application":
          "請提供拒絕申請的理由",
        Success: "成功",
        Failed: "失敗",
        "Are you sure to kick out this player": "你確定要踢出該球員嗎?",
        "Select one player to kick out": "選擇要踢出的球員",
        Register: "註冊",
        Payment: "支付",
        "Roll call": "點名",
        "Online application Payment evidence": "線上申請的支付憑證",
        Online: "線上",
        Offline: "線下",
        ApprovedPlayers: "參加人數",
        "Approved Teams": "已確認隊伍",
        "Kick out a participant": "移除參與者",
        "Add participants": "添加參與者",
        "Manage Event": "管理活動",
        "Expand event details": "展開活動詳情",
        "Payment evidence": "支付憑證",
        "Collapse event details": "收起活動詳情",
        "Check all the evidences": "檢查所有憑證",
        Check: "檢查",
        "Manual change payment status": "手動更改支付狀態",
        "Not yet paid": "尚未付款",
        "Pending approval": "等待批准",
        Paid: "已付款",
        "Payment status": "支付狀態",
        Day: "日",
        "Please select event session": "請選擇一場活動",
        "There is no player applied for this session": "本場沒有球員申請",
        "hkd/person": "港元/人",
        "Update rollcall success": "更新點名成功",
        Unpaid: "未付款",
        free: "免費",
        to: "至",
        "Payment receipts": "支付憑證",
        "View the payment receipts submitted by registered users":
          "查看已提交的支付憑證",
        "Manage Players": "管理球員",
        "Edit Payment Status": "更改支付狀態",
        "No Evidence fetched": "沒有提交支付憑證",
        "Review evidence": "審核支付憑證",
        "Save Changes": "儲存變更",
      },
      ManageCourse: {
        "Application approved": "申請已確認",
        "Application rejected": "申請已拒絕",
        "Manual add make up session": "添加補堂",
        "You may move this player to another course as a make up session":
          "你可以用其他訓練班的課堂作爲此球員的補堂",
        "No Access Right": "沒有權限",
        "You do not have the right to access this function":
          "你沒有使用此功能的權限",
        "students applied leave": "球員申請請假",
        "Are you sure to reject the leave request?": "你確定要拒絕此請假申請?",
        "There is no applications": "目前沒有申請",
        Accepted: "已確認",
        Applied: "申請中",
        "Course details": "訓練班詳情",
        to: "至",
        "hkd/person": "港元/堂",
        Description: "詳情",
        Started: "已開始",
        Sessions: "堂數",
        "Course session": "課堂",
        "Select course": "選擇訓練班",
        "Minimum session to apply": "最少需要報名的堂數",
        "Participants at least ": "參加者最少上 ",
        sessions: "堂",
        "Collapse course details": "收起訓練班詳情",
        Applicant: "申請人",
        "Course Detail": "訓練班詳情",
        hkd: "港元",
        "Expand course details": "查看訓練班詳情",
        "View all registrations": "查看所有申請",
        "View absents": "查看請假",
        Manage: "管理",
        Check: "查看",
        "Session information": "課堂資料",
        View: "查看",
        absents: "請假",
        "All leave requests": "所有請假申請",
        "Please select course session": "請選擇課堂",
        "There is no player applied for this session": "沒有球員報名此課堂",
        "There is no course session found": "找不到課堂",
        "Update rollcall success": "點名成功",
        "Update leave request success": "更新請假申請成功",
        "There is no leave request": "沒有請假申請",
        Register: "報名情況",
        "Applied players": "點名",
        Session: "堂數",
        "Manage Sessions": "管理課堂",
        "Manage Players": "管理球員",
        Payment: "付款",
        "Payment receipts": "支付憑證",
        "View the payment receipts submitted by registered users":
          "查看已提交的支付憑證",
        "Save Changes": "儲存變更",
        "Edit Payment Status": "更改支付狀態",
        Paid: "已付款",
        Unpaid: "未付款",
        "Payment status": "支付狀態",
        "Not Paid": "未付款",
        "Please provide reason for rejecting the payment": "請提供拒絕原因",
        "Review evidence": "查看憑證",
        "Approve and mark as paid": "同意並標記為已支付",
        "Reject and provide reason": "請提供拒絕理由",
        "No Evidence fetched. Please try again": "沒有獲取憑證，請再嘗試",
      },
      VenueDetails: {
        "Venue details": "場地詳情",
        Date: "日期",
        Time: "時間",
        "Please select at least 1 time slot": "請至少選擇一個時段",
        "hkd/hour": "港元/小時",
        Description: "描述",
        "Phone Number": "電話號碼",
        "Number of tables": "乒乓球枱的數量",
        "Opening Time From To": ":from 到 :to",
      },
      ClubUpdateProfile: {
        "Update Profile": "更新簡介",
        "Club name": "球會名稱",
        "Update profile success": "成功更新簡介",
      },
      VenueList: {
        "Venue list": "場地清單",
        Created: "已創建",
        Happening: "進行中",
        "There is a problem delete your venue": "刪除場地時發生錯誤",
        "Your venue is deleted": "場地已刪除",
        "Yes, delete": "是，刪除",
        "Are you sure to delete this venue you created?":
          "你確定要刪除你所創建的場地嗎?",
        "Once the venue delete participants are not longer to reach this venue":
          "當你刪除此場地後，所有用戶將無法租用場地",
        "No Venue": "沒有場地",
        "Create Venue": "新增場地",
        "Tap here to create a new venue": "按此新增場地",
      },
      // Add Club & Update Club
      Club: {
        "Club Name": "球會名稱",
        "Club Creating": "新增球會",
        Created: "已新增",
        "Submitted successfully": "成功提交",
        Updated: "已更新",
        Deleted: "已刪除",
        "Deleted successfully": "成功刪除",
        "Club Updating": "更新球會",
        "Club Profile Image": "球會圖片",
      },
      // Add Venue & Update Venue
      Venue: {
        "Phone number must be an 8-digit number": "電話號碼必須是 8 位數字",
        "Enquiry Phone no": "查詢電話號碼",
        "Opening hours": "開放時間",
        From: "開始",
        To: "結束",
        "Venue creating": "場地創建中",
        "Basic info": "基本資料",
        "Venue name": "場地名稱",
        "Venue district": "場地地區",
        "Venue address": "球會地址",
        "Number of table": "乒乓球枱數量",
        "Rate ($/hr)": "收費（港元/小時）",
        "Ball provided?": "會否提供乒乓球?",
        "Files smaller than 5mb": "圖片需要小於5mb",
        "Same time every day": "每天同樣時段",
        AddVenue: "場地新增中",
        UpdateVenue: "場地更新中",
        "Venue description": "場地詳情",
        "Venue photo": "場地照片",
        "Cancellation Period": "取消期限",
        "For confirmed bookings": "只限已確認的預約",
        "Venue Area": "場地區域",
        "All areas": "所有區域",
        "All districts": "所有地區",
        "Venue Image": "場地照片",
      },
      AddVenueSuccess: {
        "Created a venue": "創建了場地",
        Back: "返回",
      },
      UpdateVenueSuccess: {
        "Updated a venue": "更新了場地",
        Back: "返回",
      },
      ApproveSuccessRequestVenue: {
        Approved: "已批准",
        "Insert VenueCard Here": "Insert VenueCard Here",
        Back: "返回",
      },
      RejectedVenueBooking: {
        Rejected: "已拒絕",
        "Applicant will recieved the notice now": "球員將會收到通知。",
        Back: "返回",
      },
      ClubApprovedCourseBooking: {
        Approved: "已批准",
        "Applicant will received the notice now": "球員將會收到通知。",
        Back: "返回",
        Rejected: "已拒絕",
      },
      ClubRejectedCourseBooking: {
        Rejected: "已拒絕",
        "Applicant will received the notice now": "球員將會收到通知。",
        Back: "返回",
      },
      ClubCourseBookingDetails: {
        "Request Course": "訓練班申請",
        Applicant: "申請球員",
        "Course Detail": "訓練班詳情",
        Description: "描述",
        hkd: "港元",
      },
      ClubRequestVenueDetails: {},
      VenueBookingDetails: {
        "Request Venue": "租用場地申請",
        Application: "申請",
        "View Profile": "查看簡介",
        "Applied Venue": "申請的場地",
        "Venue Photo": "場地照片",
        "Selected date": "所選日期",
        "Selected time": "所選時段",
        "Venue booking is approved": "已批准場地預約",
        hkd: "港元",
      },
      // Add Course & Update Course
      Course: {
        "Assigned Player(s)": "指定球員",
        Player: "球員",
        Type: "類型",
        Public: "恆常",
        Private: "私人",
        "Course name": "名稱",
        "Course type": "類型",
        "Course capacity": "名額",
        "Course price": "每堂價錢 (港元)",
        "Course time": "上課時間",
        "Course duration": "上課日期",
        "Course level": "訓練班水平",
        "Course district": "上課地區",
        "Phone number": "電話號碼",
        "Course creating": "訓練班創建中",
        "Basic info": "基本資料",
        "Course price (HKD)": "每堂價錢 (港元)",
        "Level required": "對象",
        "Course address": "上課地址",
        "Enquiry phone no": "查詢電話號碼",
        Mobile: "電話號碼",
        "Phone Number be 8-digit number": "電話號碼必須是 8 位數字",
        "Create successful": "成功創建！",
        "You can check the created event in Manage tab you can edit delete the event afterward":
          "你可以在管理分頁中更改或刪除此訓練班",
        "Create another course": "繼續創建訓練班",
        "Manage create course": "管理已創建的訓練班",
        "Course price must be greater than 0 and an integer":
          "訓練班價錢必須大於0及為整數",
        "Course capacity must be greater than 0 and an integer":
          "名額必須大於0及為整數",
        "Course photo": "上載圖片",
        "Files smaller than 5mb": "圖片需要少於 5mb",
        "Minimum session to apply": "最少需要報名的堂數",
        "Minimum session to apply must be greater than 0 and an integer":
          "此選項必須大於0",
        "Update Course": "更新訓練班",
        "Are you sure to delete this course you created?":
          "你確認要刪除訓練班嗎",
        "Once the course delete, participants are not longer to reach this course":
          "當你刪除訓練班，其他用戶將無法參與此訓練班",
        "Yes, delete": "是，刪除",
        Deleted: "已刪除",
        "Select Level": "選擇水平",
        "from date must be earlier than the to date":
          "結束日期不能早於開始日期",
        "to date must later than recurring day": "結束日期不能早於循環日期",
        "UpdateCourse successful": "更新訓練班成功",
        "You have successfully edit the course details You can check it in the details page":
          "你已成功編輯該訓練班的詳情，你可以在詳情頁面查看",
        Saved: "已儲存！",
        "Edit payment method": "編輯支付方式",
        "Sync with club venue": "使用球會場地",
        "Club Venue": "球會場地",
        "No Venue Created": " 沒有創建場地",
        "There is no venue created under your club Go to venue tab and add a venue":
          "你的球會沒有創建場地，請先去創建場地",
        OK: "好",
      },
      Home: {
        "Cancel successfuly": "取消成功",
        "Confirm to cancel join request?": "確認取消加入球會申請",
        "Delete successfuly": "刪除成功",
        "Confirm to delete club?": "確認刪除球會",
        "Talk with \nChatPingPong": "與ChatPingPong \n對話",
        Date: "日期",
        Time: "時間",
        to: "至",
        Manage: "管理",
        Applications: "申請",
        "Course Name": "訓練班名稱",
        "Start Date": "開始日期",
        Sessions: "課堂",
        "Course Details": "訓練班詳情",
        "Latest Content": "最新資訊",
        "There is no content": "沒有資訊",
        "Remarkable Sports": "GoPingPong",
        Requests: "申請",
        "View all": "查看全部",
        "Create Venue": "新增場地",
        "Create Course": "新增訓練班",
        "Tap here to create course": "按此新增訓練班",
        "Tap here to create venue": "按此新增場地",
        "Tap here to create courses, events and games": "按此新增訓練班，場地",
        Course: "訓練班",
        Venue: "場地",
        "There is no pending course request": "暫時沒有訓練班申請",
        "There is no pending venue request": "暫時沒有租用場地申請",
        "Join Club": "加入球會",
        "Create Club": "創建球會",
        Pending: "待確認",
        "My club": "我的球會",
        "Courses, events and games": "訓練班，場地",
        "There is no requests found": "暫時沒有任何申請",
        Game: "打波",
        Event: "活動",
        "I want to create": "我想新增訓練班",
        "Venue booking is approved": "已批准場地預約",
        "Courses, events and venues": "訓練班，活動，場地",
        "Check for availability now": "查看詳情",
      },
      Manage: {
        "Remarkable Sports": "GoPingPong",
        Request: "申請",
        Course: "訓練班",
        Venue: "場地",
        Event: "活動",
        "Course List": "課程例表",
        "Venue List": "場地例表",
        "Venue booking is approved": "已批准場地預約",
      },
      CourseList: {
        Finished: "已完成",
        Created: "已創建",
        CourseList: "訓練班清單",
        "Yes, delete": "是，刪除",
        "Are you sure to delete this course you created?":
          "你確定要刪除你所創建的訓練班？",
        "Once the course delete participants are not longer to reach this course":
          "當你刪除此訓練班，所有用戶將無法參與此訓練班",
        "You successfully deleted the course": "你已成功刪除此訓練班",
        "Tap here to create a new event and have fun with other player":
          "按此新增訓練班.",
        "Create Course": "新增訓練班",
        Deleted: "成功刪除！",
        "Roll Call": "點名",
        "Data Request Error": "數據請求失敗",
      },
      ClubProcess: {
        "Manage Salary": "管理薪金",
        Player: "球員",
        "Manage Club": "球會管理",
        "Yes,Approve": "是，確認",
        "Are you sure to Approve this apply?": "你確定要確認此申請？",
        "Approve Successful": "確認成功",
        "Reject Successful": "拒絕成功",
        "Are you sure to Reject this apply?": "你確定要拒絕此申請？",
        "Yes,Reject": "是，拒絕",
        Coach: "教練",
        Staff: "球會負責人",
        Applications: "申請",
        Existing: "球會人員",
        "Confirm to reject application?": "確認拒絕申請嗎?",
        "Confirm to approve application?": "確認批准申請嗎?",
        "Coached for": "已執教",
        years: "年",
        Admin: "管理",
        "Confirm to remove coach?": "確認移除教練？",
        "Confirm to remove staff?": "確認移除球會負責人？",
        "Remove Successful": "成功移除",
        Payment: "支付",
        "New Payment Method": "新支付方式",
        Bank: "銀行",
        "Bank Name": "銀行名稱",
        "Account Number": "賬號編號",
        "Account Name": "賬號名",
        "Phone Number": "電話號碼",
        ID: "ID",
        "Confirm to remove \n%{paymentType}": "確認移除? \n%{paymentType}",
        "Removed successfully": "移除成功",
      },
      ClubAddPaymentMethod: {
        "Add Payment Method": "新增支付方式",
        "Method Type": "方式種類",
        "Please select 1 information to show": "請選擇一條信息",
        "Both of them": "所有",
        Identifier: "識別碼",
        "Phone number": "電話號碼",
        "Account name": "賬號名",
        Bank: "銀行",
        Others: "其他",
        FPS: "FPS",
        Payme: "Payme",
        "Bank account": "銀行賬號",
        Name: "姓名",
        "Free text": "自由文本",
        "Select payment type": "選擇支付方式",
        "Added New Payment Method\n%{paymentType}":
          "新增了支付方式\n%{paymentType}",
        "Edit Payment Method": "更改支付方式",
        "Saved successfully": "儲存成功",
      },
      ClubReportScreen: {
        Report: "報告",
        "Monthly Summary": "每月總結",
        "Coach Performance": "教練表現",
        "Player Breakdown": "球員報告",
        "Course Performance": "訓練班報告",
        "No permissions": "沒有權限",
      },
      ClubMonthlySummary: {
        "Monthly Summary": "每月總結",
        Month: "月",
        Income: "收入",
        Expenditure: "支出",
        Profit: "盈利",
      },
      ClubCoursePerformance: {
        "Course Performance": "訓練班報告",
        Course: "訓練班",
        Cost: "支出",
        Income: "收入",
        Expenditure: "支出",
        Profit: "盈利",
        Status: "狀態",
        Active: "進行中",
        Past: "過去",
        "Player Name": "球員姓名",
        "Attended Sessions": "參與堂數",
        "Remaining Sessions": "餘下堂數",
      },
    },
    CoachScreens: {
      O3RequestDetails: {
        "Request details": "申請詳情",
      },
      CoachUpdateProfile: {
        "Submit for Admin Approval": "提交給管理員批准",
        "You will not be able to edit your profile until the submitted changes approved by our admin":
          "“在我們的管理員批准你的更改之前，您將無法更改您的個人資料",
        "Your previous update is pending for approval":
          "你上次的更改還未被批准",
        "Wait for approval": "請等待批准",
        OK: "好的",
        "When you start": "你什麼時候開始打乒乓球?",
        "When you start as a player": "你什麼時候開始打乒乓球?",
        "When you start as a coach": "你什麼時候成為乒乓球教練?",
        "Start Year": "開始年份",
      },
      Home: {
        "Talk with \nChatPingPong": "與ChatPingPong \n對話",
        "There is no related meetup": "沒有相關詳情",
        "On-going": "進行中",
        "to-be-confirmed": "待確認·",
        Requests: "申請",
        "View all": "查看全部",
        "Remarkable Sports": "GoPingPong",
        "Courses and events": "訓練班或活動",
        "There is no requests found": "暫時沒有任何申請",
        Course: "訓練班",
        Game: "打波",
        Event: "活動",
        "I want to create": "我想新增訓練班",
        "Latest Content": "最新資訊",
        "There is no content": "沒有資訊",
        Other: "其他",
        Recommended: "推薦",
        View: "查看",
        "Recommended by AI": "AI推薦",
      },
      O3ApplyRequest: {
        "Applied request": "申請中",
        "You have successfully applied the request": "你已成功作出申請",
        "Request accepted": "申請已確認",
        "You have successfully accept the request": "你已確認申請",
        "Confirm to accept request": "確認申請?",
        "Request rejected": "申請已拒絕",
        "You have rejected the request": "你已拒絕申請·",
        "Confirm to reject request": "拒絕申請?",
        HK$: "HK$",
        "No Rank": "沒有排名",
        Information: "詳情",
        "View profile": "查看簡介",
        "Looking for a venue": "需要場地?",
        "Explore and check availability of venue now": "立即查看可用場地",
        "Request details": "申請詳情",
        Applicant: "申請球員",
        "Provide meet up details": "請提供練球詳情",
        Address: "地址",
        Rate: "收費（每小時）",
        "Rate must be positive number": "收費必須爲正數",
        "Rate must with in user's request $%{min} to $%{max}":
          "收費必須在 $%{min} 至 $%{max}",
        "Do you have a venue?": "你會否提供場地?",
        "System will requests player to book a venue if you don't have one":
          "如果你無法提供場地，系統會要求球員租用場地",
        Successful: "成功",
        "the meet up details already sent to your applicant":
          "練球詳情已發送給該球員",
        OK: "好的",
        Hide: "隱藏",
        Failure: "失敗",
        "There is an error for your request": "申請有錯誤",
        "Successfully hide player": "成功隱藏球員",
        "Error hide player": "隱藏球員錯誤",
      },
      RequestList: {
        "Meetup Records": "開波記錄",
        Requesting: "可申請",
        Confirmed: "已確認",
        Applying: "申請中",
        Recent: "最近",
        Contacts: "聯繫人",
        Applied: "申請中",
        Finished: "已結束",
      },
      O3Details: {
        "Request details": "練球詳情",
        "Provide meet up details": "請提供練球詳情",
        "Cancel meetup": "取消練球",
        "Are you sure to cancel the meetup": "你是否要取消練球",
        "There is an error canceling meetup": "取消時遇到錯誤",
        Error: "錯誤",
        OK: "好的",
      },
      ContanctsDetails: {
        "Meetup with": "",
        "Meetup Record": "練波記錄",
      },
    },
    ForgotPassword: {
      ForgotPassword: {
        "Forgot Password": "忘記密碼",
        Email: "電郵",
        "Send reset password email": "發送重設密碼電郵",
        ResetSuccess: "重設密碼電郵已發送",
        "There is an error resetting your password": "重設密碼時遇到錯誤",
        Error: "錯誤",
        Success: "成功",
        OK: "確認",
      },
    },
    ChangePassword: {
      "Change password": "更改密碼",
      "Please consider the rules for your new password": "您的密碼必須使用：",
      "at least 1 Uppercase, 1 Lowercase, 1 number, at least 8 characters":
        "大寫和小寫字符, 數字, 至少8個字符",
      "Confirm password": "確認密碼",
      "New password": "新密碼",
      "Current password": "當前密碼",
      "Password changed": "密碼改變了",
      "The Password and Confirm Password do not match":
        "“密碼”和“確認密碼”不匹配.",
    },
    ChangeEmail: {
      "Change Email": "更改電郵",
      "Current Email Address:": "當前電郵:",
      "New Email Address:": "新電郵:",
      "Email address": "電郵",
      "Confirm Email address": "確認電郵",
      "Confirm to change email": "確認更改電郵?",
      "Email Changed": "電郵已更改",
    },
    JoinClubScreen: {
      Search: "搜尋",
      "Club List": "球會名單",
      "Are you sure to join this club?": "你確認要加入此球會?",
      "Successful application": "成功申請",
      Join: "加入",
      "Yes, Join": "是，加入",
      "join this club": "加入此球會",
      "There is no Club": "沒有球會",
      "No result found": "沒有結果",
      "We cannot find any club matching your search yet": "無法找到相應的球會",
    },
    LeagueScreen: {
      "Remarkable Sports": "GoPingPong",
      "Select division > choose team > click apply":
        "選擇分區 > 選擇球隊 > 點擊\n申請",
      "Join the team now": "立刻加入球隊",
      "Please select League": "請選擇聯賽",
      "No data": "沒有數據",
      Statistic: "統計",
      Filter: "篩選",
      Season: "賽季",
    },
    DivisionLeaderboard: {
      Statistic: "統計",
      "Please select division": "請選擇組別",
      division: "組別",
      "No data": "沒有數據",
      Team: "隊伍",
      Individual: "個人",
      Player: "球員",
      P: "P",
      W: "W",
      L: "L",
      D: "D",
      "%": "%",
      Pts: "Pts",
    },
    IndividualStatistic: {
      "Individual Match Record": "個人比賽紀錄",
      Player: "球員",
      P: "P",
      W: "W",
      L: "L",
      D: "D",
      "%": "%",
      Pts: "Pts",
      "No Data": "沒有數據",
    },
    TeamStatistic: {
      WIN: "贏",
      LOSE: "輸",
      DRAW: "和",
      "Points from each match": "場次分",
      "Points from no of players": "人頭分",
      "Additional points": "額外分",
      set: "局",
      "Team Results Table": "隊伍統計",
      Player: "球員",
      P: "P",
      W: "W",
      "%": "%",
    },
    SubmitMatchResult: {
      "Basic Info": "基礎資料",
      "Game Result": "比賽結果",
      "Match result": "比賽結果",
      Game: "比賽",
      "Team name": "隊伍名字",
      "Total point": "總分",
      "Your opponent will verify the results": "客隊將會檢查賽果",
      "Remember to submit after you update the results later":
        "再次更新後，請記得提交賽果",
      "Saved Successfully": "成功儲存",
      "Submitted Successfully": "成功提交",
      "Please select a game": "請選擇場次",
      "Are you sure to submit the match result?": "你確認要提交賽果？",
      "Please select 10 players on the basic info page":
        "請先選擇兩隊的出場名單",
      Other: "其他",
    },
    DivisionScreen: {
      "All round": "所有回合",
      Filter: "篩選",
      "There is no Team": "沒有隊伍",
      Manage: "管理",
      "Cancel request": "取消申請",
      "Approve request": "審核申請",
      "You may click to your team and check all application":
        "你可以點擊你的隊伍並查看所有申請",
      "My team": "我的隊伍",
      "There is no Fixture": "沒有賽程",
      "There is no Result": "沒有賽果",
      "%{homeTeamName} has submitted the results, please verify the results as soon as possible":
        "%{homeTeamName} 提交了賽果，請儘快審核",
      "Pending Approval": "待審核",
      "Match Result": "賽果",
    },
    LeagueFiltering: {
      Search: "搜尋",
      "DD-MM-YYYY": "日-月-年",
    },
    FilterFixtureResult: {
      "Filter Result": "查詢賽果",
    },
    PendingApproval: {
      "Pending Approval": "待審核",
    },
    ManageTeam: {
      Request: "申請名單",
      "Confirmed list": "確認名單",
      "My team request": "我的隊伍申請",
      "There is no Player": "沒有球員",
      Successfully: "成功",
      "Approve Successfully": "批准成功",
      "Reject Successfully": "拒絕成功",
      "You have successfully remove player": "已成功移除球員",
      "Please provide reason for rejecting the application":
        "請提供拒絕申請的理由",
      "Are you sure to remove %{playerName}?": "你確定要移除%{playerName}嗎?",
    },
    ManageEventPlayer: {
      "Manage Player": "管理球員",
      Existing: "現有球員",
      Rejected: "已拒絕球員",
      Remove: "移除",
      "Add Player": "新增球員",
      "Confirm to remove “%{name}”?": "確認移除 “%{name}”?",
      "You have successfully kick participants out to this event":
        "你已成功移除球員",
      "Kick out successfully": "移除成功",
      "Select one player to kick out": "請移除一位球員",
      "Player details": "球員詳情",
    },
    ManageCoursePlayer: {
      "Edit Sessions": "更改課堂",
      "Manage Player": "管理球員",
      Existing: "已批准申請",
      Rejected: "已拒絕申請",
      Remove: "移除",
      "Add Player": "新增申請",
      "Confirm to remove “%{name}”?": "確認移除 “%{name}”?",
      "You have successfully kick participants out to this event":
        "你已成功移除球員",
      "Kick out successfully": "移除成功",
      "Select one player to kick out": "請移除一位球員",
    },
    LogIn: {
      "Don’t have an account? Register Now": "沒有賬號？按此註冊",
      Login: "登入",
      Password: "密碼",
      Email: "電郵",
      "Professional table tennis training\ncourses throughout Hong Kong":
        "遍佈全港的乒乓球活動",
      "Dream bigger": "成就更大夢想",
      "Unleash\nyour potential": "發揮潛能",
      "Invalid email and/or password": "電郵或密碼錯誤",
      "There is an error logging user in Please try again":
        "登錄發生錯誤，請再試一次",
      "Forgot your password?": "忘記密碼？",
      "Biometric Login": "生物識別登錄",
    },
    MainScreens: {},
    MeetCoach: {},
    PlayerScreens: {
      PlayerPendingRequests: {
        "Pending Requests": "待確認申請",
      },
      PlayerMeetupRecords: {
        "There is no related meetup": "沒有相關詳情",
        All: "所有",
        Completed: "已完成",
        Upcoming: "即將進行",
        Cancelled: "已取消",
        Rejected: "已拒絕",
        "Meetup Records": "練波記錄",
      },
      PlayerUpdateProfile: {
        "When you start": "你什麼時候開始打乒乓球?",
        "When you start as a player": "你什麼時候開始打乒乓球?",
        "When you start as a coach": "你什麼時候成為乒乓球教練?",
        "Start Year": "開始年份",
      },
      Calendar: {
        Calendar: "日曆",
        "Confirm to delete this activity?": "確認取消活動嗎?",
        "The activity will be deleted permanently in the calendar":
          "這活動會被永久取消",
        "Yes, confirm": "是的，確認",
        "+ add": "+ 加",
      },
      CalendarAddActivity: {},
      CourseApplySuccess: {
        "Successful Applied": "成功提交申請",
        OK: "好的",
        "Applied details": "訓練班申請詳情",
        "Upcomming session": "下一堂",
        "Number of applied sessions": "報名堂數",
      },
      AskForLeave: {
        "Ask for leave": "申請請假",
        "Select absent session": "選擇請假的日期",
        "Make up session": "補堂日期",
        "No make up sessions available": "沒有適合的補堂日期",
        "Apply leave success": "申請成功",
        Session: "課堂",
      },
      CourseDetails: {
        "Payment evidence": "支付憑證",
        "Please submit the payment evidence": "請提交支付憑證",
        "Submit now": "提交",
        HK$: "港元",
        "Date & Time": "日期與時間",
        "Date of First Session": "第一堂日子",
        "Number of Sessions": "堂數",
        "Need to apply for at least ": "最少申請 ",
        "View all sessions": "查看所有堂數",
        Session: "課堂",
        Accepted: "已受理",
        Applied: "申請中",
        "Course details": "訓練班詳情",
        to: "到",
        "hkd/person": "港元/堂",
        Started: "已開始",
        Sessions: "請選擇第一堂的日子",
        "Course session": "第一堂日子",
        "Select sessions": "選擇日子",
        "Minimum session to apply": "堂次",
        "Participants at least ": "參加者最少上",
        sessions: "堂",
        "Ask for leave": "申請請假",
        "Next session": "下一堂",
        "Remained sessions": "餘下堂數",
        Total: "總數",
        Description: "詳情",
        "View Sessions": "查看課堂",
        PayPal: "使用PayPal付款",
      },
      CourseRejectSuccess: {
        "Successful Rejected": "成功拒絕",
        OK: "好的",
        "Reject details": "拒絕詳情",
      },
      CourseFiltering: {
        "Club Name": "球會名稱",
        "All areas": "所有區域",
        "All districts": "所有地區",
        "All level": "所有水平",
        "Filter course": "篩選訓練班",
        Location: "地點",
        From: "開始時間",
        To: "結束時間",
        HKD: "港元",
        Level: "水平",
        "Select level": "選擇水平",
        "Min price (HKD)": "最低(港元)",
        "Min price must be less than Max price and an integer":
          "最低不能大於最高價錢及必須為整數",
        "Max price (HKD)": "最高(港元)",
        "Max price must be greater than Min price and an integer":
          "最高必能小於最低價錢及必須為整數",
        "Day of week": "星期幾",
      },
      CourseList: {
        "All available courses": "所有訓練班",
        Collapse: "收起",
        "Recommended Course": "推薦的訓練班",
        "No course available": "暫時沒有合適的訓練班",
        "Please search again": "請重新搜尋",
        Cost: "費用",
        Level: "水平",
        Search: "搜尋",
        From: "由",
        To: "至",
        Created: "已創建",
        Happening: "進行中",
        CourseList: "訓練班清單",
        Applied: "申請中",
        Confirmed: "已確認",
        Finished: "已完成",
        "View all": "查看全部",
        "No result found": "沒有結果",
        "We cannot find any course matching your search yet":
          "無法找到相應的訓練班",
        "There is no %{type} course": "沒有%{type}的訓練班",
        "No course recommended": "沒有推薦的訓練班",
      },
      O3AppliedCoach: {
        "Pending Direct Request": "待確認的直接申請",
        "Meet coach": "搵教練",
        "Applied coach": "已申請教練",
        "On-going Coaching Request": "進行中的教練申請",
        "Matched Coaching": "已匹配的教練申請",
        "No coach applied yet": "暫時沒有教練申請",
        "Please wait for a moment": "請稍等片刻",
        "hkd/hr": "港元/小時",
        "Venue is missing": "沒有提供場地",
        "Are you sure to cancel the meetup": "你確定要取消這次的訓練",
        "Recommended Coaches": "推薦教練",
        Search: "搜尋",
        "Look for the right coach to meet up": "尋找適合你的教練",
        Recommendation: "推薦教練",
        Favourite: "最愛",
        "Coached for": "已執教",
        years: "年",
        "No favourite coach yet": "暫時沒有最愛的教練",
        "You can bookmark your favourite coaches": "你可以標記你喜愛的教練",
        "Pending Requests": "待確認申請",
        "View Status": "查看狀態",
        Rank: "排名",
      },
      O3SubmitRequest: {
        Submited: "已提交",
        "You have submited a meetup request": "你已提交一個練波申請",
        "Find coaches": "搵教練",
        Location: "地點",
        "Meetup date": "練球日期",
        Time: "時間",
        From: "開始時間",
        To: "結束時間",
        HKD: "港元",
        Price: "收費範圍",
        "Do vou have a venue?": "你是否會提供場地?",
        "Provide venue address here": "請提供場地地址",
        "Suggest you to book the venue beforehead You ultimatelv need to do so if the coach did book the venue neither":
          "請盡量提早租用場地，如教練不能提供場地，你仍需提供場地來確保成功匹配",
        "Date should not be in the past": "開始日期無效",
        "from time should not be in the past": "開始時間無效",
        "Min price (HKD)": "最低(港元)",
        "Min price must be less than Max price and an integer":
          "最低不能大於最高價錢及必須為整數",
        "Max price (HKD)": "最高(港元)",
        "Max price must be greater than Min price and an integer":
          "最高必能小於最低價錢及必須為整數",
      },
      O3AppliedCoachDetails: {
        "Your meetup is ready": "完成手續",
        "Meet up details": "練球詳情",
        Hide: "隱藏",
        "There is an error hiding the coach": "隱藏教練錯誤",
        "Successfully hide coach": "隱藏教練完成",
        "Error hide coach": "隱藏教練錯誤",
        Feedback: "反饋",
        "Leave your feedback": "提供反饋",
        "Please leave your feed back here": "請在這裏提供反饋",
        "Show ?": "有沒有出現?",
        "Provide feedback successfully": "反饋提供完成",
        "Feedback from player": "球員反饋",
        "Feedback from coach": "教練反饋",
        "Delete success": "成功刪除",
        "Are you sure to cancel the meetup": "你是否要取消練球",
        "There is an error canceling meetup": "取消時遇到錯誤",
        Error: "錯誤",
        OK: "好的",
        Cancel: "取消練球",
      },
      Home: {
        "Talk with \nChatPingPong": "與ChatPingPong \n對話",
        "Remarkable Sports": "GoPingPong",
        "On-going": "進行中",
        "To-be-confirmed": "待確認",
        Course: "訓練班",
        "View all": "查看全部",
        Requests: "申請",
        "hkd/hr": "港元/小時",
        "Venue is missing": "沒有提供場地",
        "Latest Content": "最新資訊",
        "There is no content": "沒有資訊",
        "Check for availability now": "查看詳情",
        "Looking for the right coach": "尋找適合的教練",
      },
      Profile: {},
      VenueApplySuccess: {
        "Successful Applied": "成功提交申請",
        OK: "好的",
        "Applied details": "租用場地申請詳情",
        "Selected date": "所選日期",
        "Selected time": "所選時段",
        "hkd/hour": "港元/小時",
      },
      VenueDetails: {
        "Venue details": "場地資料",
        "Please select at least 1 time slot": "請至少選擇一個時段",
        "hkd/hour": "港元/小時",
      },
      VenueBookingDetails: {
        "Request Venue": "租用場地申請",
        "Delete success": "成功刪除",
      },
      VenueFiltering: {
        "Filter venue": "篩選場地",
        Location: "地點",
        "Venue date": "場地日期",
        "All areas": "所有區域",
        "All districts": "所有地區",
        From: "開始時間",
        To: "結束時間",
        HKD: "港元",
        "Min price (HKD)": "最低(港元)",
        "Min price must be less than Max price and an integer":
          "最低不能大於最高價錢及必須為整數",
        "Max price (HKD)": "最高(港元)",
        "Max price must be greater than Min price and an integer":
          "最高必能小於最低價錢及必須為整數",
      },
      VenueItemCard: {
        hkd: "港元",
      },
      VenueList: {
        "Recommended Venue": "推薦的場地",
        Search: "搜尋",
        "Available Venues": "可租用的場地",
        "No venue available": "暫時沒有合適的場地",
        "Please search again": "請重新搜尋",
        Cost: "費用",
        hkd: "港元",
        From: "由",
        To: "至",
      },
      MeetupRecords: {
        "Meetup Records": "開波記錄",
        All: "所有",
        Coaching: "揾教練",
        Course: "訓練班",
        Venue: "場地",
        Recent: "最近",
      },
      EventDetails: {
        "Event details": "活動詳情",
        days: "天",
        Day: "天",
        "Day %{number}": "第%{number}天",
        "hkd/person": "港元/人",
        "Opening remain": "報名人數",
        "See participant list": "查看參與者列表",
        "Easy apply %{type} competition NOW!": "現在立即申請%{type}比賽!",
        "Player name": "球員名字",
        "Team name": "隊名",
        Joiner: "參與者",
        name: "名字",
        "Event is full": "活動人數已滿",
        "You can still apply to be waiting list": "您仍然可以申請進入候補名單",
        "Submit now": "現在提交",
        "You can add up to 10 members": "你最多可以添加10個成員",
        "After quick apply organiser will approve your application shortly Then you have to submit payment after Final confirmation will be done after organiser approve your payment evidence":
          "經快速申請後，主辦方將於短期內批准您的申請。然後，您須提交支付憑證；最後主辦方將批准您的支付憑證後確認。",
        "Payment evidence": "支付憑證",
        "Please submit the payment evidence": "請提交支付憑證",
        "Are you sure to cancel joining this event?": "你確認要取消參加活動嗎?",
        "Cancel successfully": "取消成功",
        "You have successfully cancel joining this event":
          "你成功取消了參與該活動.",
        free: "免費",
        "Add Player": "添加球員",
        "Remove Player": "移除球員",
      },
      JoinEventSuccess: {
        "Join successful!": "申請成功!",
        "Join another event": "加入其他活動",
        "view join event": "查看活動",
        "Please upload the payment evidence once your application is approved":
          "再申請通過後請上傳支付憑證",
      },
      PaymentEvidence: {
        Total: "總數",
        "Payment evidence": "支付憑證",
        "Add payment evidence": "添加支付憑證",
        "Files smaller than 5mb": "圖片需要小於5mb",
        "Payment method": "支付方式",
        "Fast payment identification code": "快速支付識別碼",
        "Submit successfully": "提交成功",
        "You have successfully paid this the event": "你已支付成功",
        "You have successfully paid this the course": "你已成功提交支付憑證",
      },
      SearchCourse: {
        "Search Course": "搜尋訓練班",
        "No result found": "沒有結果",
        "We cannot find any course matching your search yet":
          "無法找到相應的訓練班",
      },
    },
    ContentScreen: {
      Content: "資訊",
      "Create Content": "發佈資訊",
      "Create a new content to encourage player to join your activity":
        "發佈新的資訊去推廣你的活動",
      Created: "已發佈",
      Expired: "過期",
      "Other content": "其他用戶發佈的資訊",
      "No record now": "暫時沒有資訊",
      "View all": "查看所有",
      "No content yet": "暫時沒有資訊",
      "Please wait for a moment": "請稍等片刻.",
      "Event Deleted": "活動已刪除",
    },
    // Add Content & Update Content
    Content: {
      Created: "已發佈",
      "Created  successfully": "成功發佈",
      "Create content": "發佈資訊",
      Title: "標題",
      Description: "描述",
      "Content photo (Optional)": "資訊圖片",
      "Files smaller than 5mb": "圖片需要小於5mb",
      "Content photo": "資訊圖片",
      "Content Name": "資訊内容",
      "From Date": "發佈日期",
      "End Date": "到期日期",
      "Post duration (DD-MM-YYYY)(Optional)": "資訊限期",
      From: "發佈",
      To: "到期",
      "from date must be earlier than the to date": "發佈日期必須早於到期日期",
      Saved: "儲存變更",
      "Saved  successfully": "已儲存變更",
      "Edit content": "編輯資訊",
      "Are you sure to delete this content you created?":
        "你確認要刪除此資訊？",
      "Once the content delete participants are not longer to reach this content":
        "當你刪除此資訊，其他用戶將無法再讀取此資訊",
      "Yes, delete": "是，刪除",
      Deleted: "已刪除",
      "Delete  successfully": "成功刪除",
      "Video Link": "影片鏈接",
      Name: "名稱",
      "(Optional)": "(選填)",
      Image: "圖片",
      "Only support YouTube URL": "只支持YouTube鏈接",
      "Image must be less than 5MB": "圖片需要小於5mb",
      "Add Image": "新增圖片",
    },
    ContentList: {
      "Content list": "資訊目錄",
    },
    ContentDetails: {
      "Content details": "資訊内容",
      "Event Deleted": "活動已刪除",
    },
    EventList: {
      "Your club does not have right to create event yet":
        "你的球會尚未有創建活動的權限，如你想創建活動，請聯絡我們",
      "View all": "查看所有",
      "Recommended Event": "推薦活動",
      Applied: "已申請",
      Confirmed: "已確認",
      Finished: "已完成",
      Search: "搜尋",
      Before: "之前",
      After: "之後",
      "There is no event": "沒有活動",
      Created: "已創建",
      OnGoing: "進行中",
      "There is no %{type} event": "沒有%{type}的活動",
      "You dont have access to event creator permission":
        "你沒有新增活動的權限",
      "On-going events": "進行中的活動",
      "Finished events": "已完成活動",
      "Tap here to create a new event and have fun with other player":
        "按此新增活動.",
      "Created events": "已創建活動",
      Host: "舉辦方",
      Participant: "參加者",
      "No Event": "暫時沒有合適活動",
    },
    AllEvent: {
      Filter: "篩選",
      "All event": "所有活動",
      Search: "搜尋",
      Before: "之前",
      After: "之後",
      "No Event available": "暫時沒有合適活動",
      "Please search again": "請重新搜尋",
    },
    EventFiltering: {
      "Filter event": "篩選活動",
      Location: "地點",
      "All areas": "所有區域",
      "All districts": "所有地區",
      "All types": "所有類型",
      "After a certain starting date": "之後",
      "Before a certain starting date": "之前",
    },
    Profile: { Profile: {} },
    SuccessfullSignUp: {},
    RequestDetails: { RequestDetails: {} },
    CreateAccount: {},
    EmailVerification: {},
    SignUp: {
      CreateAccount: {
        "You will recieve an confirmation email later": "你將會收到確認電郵",
        Password: "密碼",
        Email: "電郵",
        Register: "註冊",
        "Ok, got it": "好的",
        Error: "錯誤",
        "Your email has already been used": "填寫的電郵已被注冊",
        "There is an error creating your account": "注冊賬號時發生錯誤",
        "Please consider the rules for your new password": "您的密碼必須使用：",
        "at least 1 Uppercase, 1 Lowercase, 1 number, at least 8 characters":
          "大寫和小寫字符, 數字, 至少8個字符",
      },
      BasicInfo: {
        "Getting Started": "開始",
        "Phone Number be 8-digit number": "電話號碼必須是 8 位數字",
        "Invalid date": "無效的日期",
      },
      UserType: {
        "I'm player": "我是球員",
        "I'm coach": "我是教練",
        "I'm club stuff": "我是球會負責人",
        "Getting Started": "開始",
        "What is your role?": "你的角色是什麼?",
      },
      PlayerInfo: {
        "Start year as player must greater than date of birth":
          "開始年份必須大於出生日期",
        "Player's Information": "球員信息",
        "When you start": "你什麼時候開始打乒乓球?",
        "Start Year": "開始年份",
      },
      CoachInfo: {
        "Start year as a player should be after your date of birth":
          "開始打乒乓球的年份應在出生年份之後",
        "Start year as a coach should be after your start year as a player":
          "開始當教練的年份應在開始打乒乓球之後",
        "Coach's Information": "教練信息",
        "When you start": "你什麼時候開始打乒乓球?",
        "When you start as a player": "你什麼時候開始打乒乓球?",
        "When you start as a coach": "你什麼時候成為乒乓球教練?",
        "Start Year": "開始年份",
      },
      ClubStaffInfo: {
        "Club Staff's Information": "球會信息",
        "Club name": "球會名稱",
      },
      EmailVerification: {
        "Invalid email and/or password": "錯誤的電郵和/或密碼",
        "Email not confirmed": "電郵尚未驗證",
        "Registration confirmed": "成功註冊帳號",
        "Thank you for registration": "感謝註冊",
        "Thank you for your patient Please continue the onboarding process":
          "多謝你的耐心等候，請繼續完成填寫帳號信息。",
        "You will soon receive a confirmation email then you can continue the onboarding process":
          "你將會收到一封確認信，之後你將可以繼續填寫帳號信息。",
        "Not yet receive any email? ": "仲未收到電郵？ ",
        "Resend email": "重新發送電郵",
      },
      SignUpSuccess: {
        Successful: "成功",
        "Admin will soon to approve your application Thank you":
          "管理員會儘快批核你的賬號申請。",
        OK: "好的",
      },
      Splash: { Splash: {} },
    },
    ProfileScreen: {
      ProfileScreen: {
        "Edit profile": "編輯簡介",
        Profile: "簡介",
        "Join Club": "加入球會",
        "Will be updated later": "即將更新",
      },
    },
    SettingsScreen: {
      SettingsScreen: {
        Account: "帳戶",
        Language: "語言",
        Password: "密碼",
        "Biometric Login": "生物識別登錄",
        Logout: "登出",
        Setting: "設置",
        "Are you sure to log out?": "確定登出嗎?",
        "Delete Account": "永久刪除帳戶",
        "Are you sure to delete your account?": "你是否確定要刪除你的帳戶？",
        Deleted: "刪除",
        "Deleted successfully": "刪除成功",
        "Deleted failure": "刪除失敗",
        Failure: "錯誤",
        "Terms and Conditions": "條款及細則",
        "Rate GoPingPong": "GoPingPong評分",
        "Change password": "更改密碼",
        Version: "版本",
        "You cannot open this url": "你無法打開此鏈接",
        "Change Email": "更改郵箱",
      },
    },
    UserProfileViewerScreen: {
      Profile: "簡介",
      "Coach Profile": "教練簡介",
      "Save as Favourite": "標記為最愛",
      "Send Request": "發出邀請",
      "Remove from Favourite": "從最愛中移除",
    },
    //  Manage Sessions & Edit Sessions
    ManageSessions: {
      "View Session": "查看課堂",
      Edited: "已更改",
      Group: "循環時段",
      "Confirm to save changes?": "確認儲存變更？",
      "Leave Page": "離開頁面？",
      "Changes you made may not be saved": "你所做的變更可能不會儲存",
      "Saved successfully": "儲存成功",
      Session: "課堂",
      "Removed successfully": "移除成功",
      "%{number} players applied this session": "%{number} 球員申請此課堂",
      "End Time": "結束時間",
      "Start Time": "開始時間",
      "End Date": "結束日期",
      "Start Date": "開始日期",
      Date: "日期",
      "Day of Week": "星期幾",
      Time: "時間",
      Type: "類型",
      Single: "單一",
      Loop: "循環",
      "Days of week must be selected at least 1 value": "必須選擇其中一日",
      "Assigned Coach": "教練",
      Applied: "申請中",
      "Edit Session": "更改課堂",
    },
    CourseRefund: {
      "Refund Summary": "Refund Summary",
      "Number of Sessions": "Number of Sessions",
      Session: "Session",
      "Refund Reason (Optional)": "Refund Reason (Optional)",
      "Refund Amount": "Refund Amount",
      "Confirm to refund": "Confirm to refund ?",
      "Date repetition": "Date repetition",
      "Date should be in the future": "Date should be in the future",
    },
    ApplySessions: {
      "Date of First Session": "第一堂日子",
      "Number of Sessions": "堂數",
      "Course session": "課堂",
      "Minimum session to apply": "最少需要報名的堂數",
      "Select sessions": "選擇課堂",
      "Out of index of sessions": "無效選項",
      "Apply Sessions": "申請課堂",
    },
  },
  component: {
    AddSessionComponent: {
      "Head coach name": "主教練姓名",
      "Head Coach": "主教練",
      "Customized Price": "自訂薪金",
      "Assistant Coach(es)": "助教",
      Name: "姓名",
      "Assistant Coach": "助教",
      "Select coach": "選擇教練",
      Date: "日期",
      "Start Date": "開始日期",
      "End Date": "結束日期",
      "Day of Week": "星期幾",
      Time: "時間",
      "Start Time": "開始時間",
      "End Time": "結束時間",
      "Assigned coach": "教練",
      Type: "類型",
      Loop: "循環",
      Single: "單一",
      "Select start time": "選擇開始時間",
      "Select end time": "選擇結束時間",
      "Select Start Date": "選擇開始日期",
      "Select End Date": "選擇結束日期",
      "Select Date": "選擇日期",
      Group: "循環時段",
      "Preview Session": "預覽課堂",
      "Is there a fee for the course": "新增支付方式？",
      "Add payment method": "新增",
      "start date must be earlier than the end date": "無效日期",
      "start time must be earlier than the end time": "無效時間",
    },
    FeatureCardSlider: {
      Course: "訓練班",
      Venue: "場地",
      Event: "活動",
      Meetup: "開波",
    },
    SliderInput: {
      "Max must be greater than Min and an integer": "必須大於最小",
      "Min must be less than Max and an integer": "必須少於最大",
    },
    ArrayEditMatch: {
      Match: "賽程",
    },
    MatchGameResultItem: {
      Game: "場次",
    },
    ArrayAddTeamInput: {
      "Add team": "新增隊伍",
    },
    DateTimePicker: {
      Date: "日期",
      Jan: "一月",
      Feb: "二月",
      Mar: "三月",
      Apr: "四月",
      May: "五月",
      Jun: "六月",
      Jul: "七月",
      Aug: "八月",
      Sep: "九月",
      Oct: "十月",
      Nov: "十一月",
      Dec: "十二月",
      Sun: "日",
      Mon: "一",
      Tue: "二",
      Wed: "三",
      Thu: "四",
      Fri: "五",
      Sat: "六",
    },
    FixtureItem: {
      Session: "賽季",
      Round: "回合",
      WIN: "勝",
      LOSE: "負",
      DRAW: "和",
    },
    TeamRequestCard: {
      "yrs exp": "球員年資",
      "Player details": "球員資料",
      "Thumb nail": "Thumb nail",
      Approved: "已批准",
      Rejected: "已拒絕",
      Pending: "待審核",
      WaitingList: "候補名單",
    },
    ArrayDivisionsInput: {
      A: "甲組",
      B: "乙組",
      C: "丙組",
      D: "丁組",
      "Add division": "新增組別",
      "New Division can be created afterward": "之後仍可新增組別",
      Name: "名稱",
      Tier: "級別",
      Division: "組別",
      "Select tier": "選擇級別",
    },
    OrganizerBottomTabNavigator: {
      League: "聯賽",
    },
    ApiToastError: {
      "Error please try again later": "發生錯誤，請稍後重試.",
      Error: "錯誤",
      "Invalid email": "電郵不正確",
      "Email already registered": "電郵已被註冊",
      "Invalid password": "密碼不正確",
      "Please check and enter a valid email address to register account":
        "請輸入正確的電郵地址註冊",
      "Please enter a different email address for registration or login to existing email":
        "請輸入另一電郵地址註冊或登入已註冊的電郵",
      "Please follow the password format listed below to register account":
        "請跟隨密碼提示輸入帳戶密碼",
      "The date and time for this course is already past, please edit the session and retry":
        "訓練班的日期和時間已經過去，請更改並重試",
      "Create Course Failed": "創建訓練班失敗",
    },
    ArrayPaymentInput: {
      "Phone number": "電話號碼",
      "Account name": "帳號名",
      Identifier: "識別碼",
      "Both of them": "所有",
      Bank: "銀行",
      "Bank account": "銀行帳號",
      "Free text": "自由文本",
      "Payment method": "支付方式",
      Method: "方式",
      "Select payment type": "選擇支付類型",
      "Please select 1 information to show": "請選擇一條信息",
      Others: "其他",
      "New Payment Method": "新支付方式",
      "Other Payment Method": "其他支付方式",
    },
    ArrayDateInput: {
      From: "開始時間",
      To: "結束時間",
      "Start time must be ealier than end time": "開始時間必須早於結束時間",
      Venue: "地址",
      "Add date with same address": "添加相同地址的日子",
      "Add date with different address": "添加不同地址的日子",
      Day: "天",
      "Day %{number}": "第%{number}天",
      "Date should be in the future": "請不要選擇過去的日子",
      Group: "循環時段",
      "players applied this session": "球員申請此課堂",
    },
    AttendanceCard: {
      Leave: "請假",
    },
    CourseApplicantDetailsCard: {
      "yrs exp": "年資",
      "Player details": "球員詳情",
      Start: "開始",
      "Number of sessions": "堂數",
      "Make up session": "補堂日期",
      Leave: "請假",
      "Upcoming Session": "下一堂",
      "Sessions left": "餘下堂數",
      Reject: "拒絕",
    },
    EventApplicantDetailsCard: {
      "Player details": "球員詳情",
      "Thumb nail": "Thumb nail",
      Approved: "已批准",
      Rejected: "已拒絕",
      Pending: "待確認",
      WaitingList: "候補名單",
      "Applicant details": "申請者資料",
    },
    EventApplicantInfoCard: {
      Pending: "待確認",
      Unpaid: "未付款",
      Paid: "已付款",
      Rejected: "已拒絕",
    },
    CourseApplicantInfoCard: {
      Approved: "已批准",
      Rejected: "已拒絕",
      Pending: "待確認",
      WaitingList: "候補名單",
      Paid: "已付款",
      Unpaid: "未付款",
      Refund: "已退款",
      "Start date": "開始日期",
      Sessions: "堂數",
      Ammount: "金額",
    },
    TeamManageDetailsCard: {
      "Player details": "球員詳情",
      Approved: "已批准",
      Rejected: "已拒絕",
      Pending: "待確認",
      Remove: "移除",
    },
    CourseList: {
      "Roll call": "點名",
      Manage: "管理",
    },
    EventList: {
      "Roll call": "點名",
      Manage: "管理",
    },
    CreateJoinClub: {
      "Join Club": "加入球會",
      "Create Club": "創建球會",
      Pending: "待確認",
      "Rejected Reason": "拒絕原因",
      "Waiting for approval": "待審核",
      "Check for availability now": "查看詳情",
      Rejected: "已拒絕",
    },
    MeetupDetails: {
      "Meet up details": "開波詳情",
      "hkd/hr": "港元/小時",
      "Venue is missing": "沒有提供場地",
      "hkd/person": "港元",
      Pending: "處理中",
      Matched: "已配對",
      Completed: "已完成",
      Cancelled: "已取消",
      LateCancelled: "不正常取消",
      NoShow: "沒有出現",
    },
    VenueDetails: {
      "hkd/hour": "港元/小時",
      Date: "日期",
      Description: "描述",
      "Phone Number": "電話號碼",
      "Number of tables": "乒乓球枱數量",
    },
    ClubShortProfile: {
      ClubStaff: "球會負責人",
      "Number of Coach %{number}": "教練人數 %{number}",
      "Club Name": "球會名稱",
      District: "地區",
      Address: "地址",
      Manage: "管理",
      Edit: "更改",
      Coaches: "教練",
    },
    CalenderListItem: {
      O3Coach: "揾教練",
      Course: "訓練班",
      Venue: "場地",
      Event: "活動",
      Fixture: "聯賽",
    },
    ApplicantListItem: {
      "Player details": "球員詳情",
      "yrs exp": "年資",
      Rank: "排名",
      Rejected: "已拒絕",
      Approved: "已批准",
    },
    MeetupSmallCard: {
      Hide: "隱藏",
    },
    Badge: { FilterBadge: {} },
    Calendar: { Calendar: {} },
    CalendarListItem: { CalendarListItem: {} },
    Card: {
      BadgeHeader: {},
      VenueBookingCard: {
        "Quick Approve": "快速確認",
      },
      AppliedCoachCard: {
        Details: "詳情",
        "Venue is missing": "沒有提供場地",
        "L/R": "左/右手",
        Age: "年齡",
        Sex: "性別",
        "hkd/hr": "港元/小時",
        "Not interested": "不感興趣",
        "Matched for %{times} times": "匹配了 %{times} 次",
      },
      ContactsCard: {
        Details: "詳情",
        "Venue is missing": "沒有提供場地",
        "L/R": "左/右手",
        Age: "年齡",
        Sex: "性別",
        "hkd/hr": "港元/小時",
        "Not interested": "不感興趣",
        "Matched for %{times} times": "匹配了 %{times} 次",
      },
      CourseBookingCard: {
        Accepted: "已受理",
        Applied: "申請中",
        hkd: "港元",
      },
      CourseCard: {
        Accepted: "已批准",
        Applied: "申請中",
        hkd: "港元",
        Started: "已開始",
        Completed: "已結束",
        to: "到",
        Available: "尚有名額",
        Full: "滿額",
      },
      CourseManageCard: {
        Accepted: "已受理",
        Applied: "申請中",
        hkd: "港元",
        Details: "詳情",
        applications: "申請人",
        Approved: "已批准",
        Pending: "待確認",
      },
      VenueManageCard: {
        Accepted: "已受理",
        Applied: "申請中",
        hkd: "港元",
        Details: "詳情",
        applications: "申請人",
        Approved: "已批准",
        Pending: "待確認",
      },
      FindCoachCard: {
        "Find Coach": "搵教練",
        "Enter your preference to find the most suitable coach to you !":
          "搵適合你的教練同你練波",
      },
      FindCoachTipCard: {
        "Finding Coach": "搵教練中",
        "Contacting the coach Please wait for a while": "聯繫教練中，請稍等.",
      },
      EventCard: {
        to: "至",
        hkd: "港元",
        days: "天",
        "Please check Day 2 or more details in details page":
          "請在詳情頁面查看第二天或者更多細節",
        Day: "天",
        "Day %{number}": "第%{number}天",
        Manage: "管理",
        "Address is missing": "沒有地址",
      },
      HighlightCard: {},
      FeaturePlaceholderCard: {
        "Enjoy matching coach anytime": "Go!揾教練~",
        "Encourage user to match coach, and this card will be direct user to find coach":
          "AI幫你揾教練同你練波!",
      },
      DivisionCard: {
        Tier: "級別",
      },
      FixtureCard: {
        "My team up-coming match": "下一場比賽",
      },
    },
    Coach: {
      CoachProfile: {
        Club: "球會",
        "Join club": "加入球會",
        hand: "握拍手",
        Years: "",
        "as coach": "教練年資",
        "as player": "球員年資",
        Clubs: "球會",
        Experience: "教練年資",
        "Player Experience": "球員年資",
        "Date of birth": "出生日期",
        Email: "電郵",
        "Price / hour": "每小時收費",
        "Lisence No": "教練編號",
        Ranking: "排名",
        "Waiting for Approval": "待審核",
      },
    },
    CoachShortProfile: {
      Rank: "排名",
      Coach: "教練",
      "View Profile": "查看簡介",
      "Coached for": "執教了",
      years: "年",
    },
    ErrorMessage: {
      "Error occured": "發生錯誤",
    },
    Modal: {
      OneColumnPickerModal: {},
      PickerModal: {},
      RejectWithReasonModal: {
        "Please provide reason for rejecting ": "請提供拒絕的原因",
        "to apply": " 申請 ",
      },
      TextInputModal: {
        "Please provide reason for rejecting ": "請提供拒絕的原因",
        " to apply ": " 申請 ",
      },
      ThreeColumnPickerModal: {},
      TwoColumnPickerModal: {},
      ParticipantListModal: {
        "Waiting list": "候補列表",
        "participant list": "參與者列表",
        "Member list": "成員列表",
        "No participant": "沒有參與者",
      },
    },
    Picker: { WheelPicker: {} },
    ClubStaff: {
      ClubStaffProfile: {
        Mobile: "電話號碼",
      },
    },
    Player: {
      PlayerProfile: {
        hand: "握拍手",
        Years: "",
        "as player": "球員年資",
        PlayerLevel: "水平",
      },
    },
    PlayerShortProfile: {
      Hand: "握拍手",
      Player: "球員",
      Age: "年齡",
      "View Profile": "查看簡介",
      "Matched for %{times} times": "匹配了 %{times} 次",
    },
    RangeSlider: { RangeSlider: {} },
    Section: { Section: {} },
    TabBar: { TabBar: {} },
    TextButton: { TextButton: {} },
    Button: {
      Loading: "載入中",
    },
    ClubRejectVenueModal: {
      "Please provide reason for rejecting ": "請提供拒絕的原因",
      " to apply ": " to apply ",
      Submit: "提交",
    },
    ImagePicker: {
      "Profile\npicture": "個人資料圖片",
    },
    FeatureCard: {
      "Meet up with": "開波啦！",
    },
    O3Details: {
      "Meet up details": "練球詳情",
      "hkd/hr": "港元/小時",
      "Venue is missing": "沒有提供場地",
      "hkd/person": "港元/人",
    },
    PlayerBottomTabNavigator: {
      Home: "主頁",
      History: "紀錄",
      Meetup: "開波",
      Calendar: "日曆",
      Profile: "簡介",
      Content: "資訊",
      WebPortal: "網頁版",
    },
    PlayerMeetupButton: {
      Meet: "開波",
      Event: "活動",
      Course: "訓練班",
      Coach: "搵教練",
      Venue: "場地",
      League: "聯賽",
      ClubCalendar: "日曆",
      Cancel: "取消",
      WebPortal: "網頁版",
    },
    CoachBottomTabNavigator: {
      Home: "主頁",
      History: "紀錄",
      Meetup: "開波",
      Calendar: "日曆",
      Profile: "簡介",
      Content: "資訊",
      WebPortal: "網頁版",
    },
    CoachMeetupButton: {
      Meet: "開波",
      Event: "活動",
      Course: "訓練班",
      Coach: "搵教練",
      Venue: "場地",
      League: "聯賽",
      Cancel: "取消",
      WebPortal: "網頁版",
    },
    StaffMeetupButton: {
      Meet: "開波",
      Event: "活動",
      Course: "訓練班",
      Coach: "搵教練",
      Venue: "場地",
      League: "聯賽",
      ClubCalendar: "日曆",
      Report: "報告",
      Cancel: "取消",
      WebPortal: "網頁版",
    },
    ClubBottomTabNavigator: {
      Home: "主頁",
      Manage: "管理",
      Calendar: "日曆",
      Profile: "簡介",
      "Please join or create a club before further actions":
        "請先加入或登記球會",
      Reminder: "提示",
      Content: "資訊",
      WebPortal: "網頁版",
    },
    AdminBottomTabNavigator: {
      User: "User",
      Club: "Club",
      Content: "資訊",
      Permission: "Permission",
    },
    MeetupSummaryCounter: {
      Pending: "待確認",
      "On-going": "進行中",
      Past: "歷史",
      "No show": "缺席",
    },
    VenueBookingDetails: {
      "Applied Venue": "申請的場地",
      "Venue Photo": "場地照片",
      "Selected date": "所選日期",
      "Selected time": "所選時段",
      hkd: "港元",
    },
    Toast: {
      MesssageToast: {
        "See details": "查看詳情",
      },
    },
    ClubDetails: {
      ApprovalStatus: "審核狀態",
      "Club Name": "球會名稱",
      "Club Address": "球會地址",
      ClubCoaches: "球會教練",
      District: "地區",
      CreatedAt: "創建於",
      "Club Admin": "球會管理員",
    },
    ContentDetailsComponent: {
      Views: "點擊",
      "View Event Detail": "查看活動詳情",
      "You can not open, please download the corresponding application":
        "無法打開鏈接",
    },
    ArrayTeamMemberInput: {
      "Select player": "選擇球員",
      "Foreign Player": "外援球員",
      Other: "其他",
      Female: "女",
      Player: "球員",
      name: "名稱",
    },
    NoDataComponent: {
      "No Data": "沒有數據",
    },
    NoAccessRight: {
      "No Access Right": "沒有權限",
      "You do not have the right to access this function":
        "你沒有使用此功能的權限",
    },
    MatchCard: {
      WIN: "勝",
      LOSE: "負",
      DRAW: "和",
      set: "局",
      Approval: "確認",
    },
    meetupCard: {
      "Recommended by AI": "AI推薦",
      Matched: "即將進行",
      Completed: "已完成",
      LateCancelled: "已取消",
      Rejected: "已拒絕",
      Cancelled: "已取消",
      NoShow: "沒有出現",
      MON: "一",
      TUE: "二",
      WED: "三",
      THU: "四",
      FRI: "五",
      SAT: "六",
      SUN: "日",
      "Not Available": "已完結",
    },
    ComingSoon: {
      "Coming Soon": "即將推出",
      "We’re developing the report feature, stay tuned for updates":
        "球會報告功能開發中，請留意新版本",
    },
  },
};
