import React from "react";
import { Icon } from "native-base";
import { Path, Svg } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function RequestIcon(props: InterfaceIconProps) {
  return (
    <Icon size="lg" viewBox="0 0 20 28" {...props}>
      <Svg width="20" height="28" viewBox="0 0 20 28" fill="none">
        <Path
          d="M7.87137 26.01L12.4428 25.99M2.08896 10.4351C2.07922 8.20728 2.91273 6.06704 4.40612 4.4852C5.89951 2.90335 7.93046 2.00947 10.0522 2.00019C12.1739 1.99092 14.2126 2.86702 15.7197 4.43575C17.2269 6.00448 18.0791 8.13735 18.0888 10.3651C18.0955 11.7176 17.7898 13.0515 17.1979 14.2525C16.606 15.4534 15.7456 16.4857 14.6903 17.2609L14.0844 20.3427C14.0048 20.9107 13.7341 21.4298 13.3212 21.8058C12.9083 22.1819 12.3806 22.3901 11.8339 22.3926L8.44877 22.4074C7.90205 22.4097 7.37258 22.2061 6.95641 21.8337C6.54024 21.4612 6.26494 20.9445 6.18045 20.3773L5.54763 17.314C4.48529 16.5454 3.61584 15.5183 3.01355 14.3204C2.41126 13.1225 2.09403 11.7894 2.08896 10.4351Z"
          stroke="#F16514"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.84245 11.256L7.39247 11.2623C7.22678 11.2631 7.09188 11.1293 7.09116 10.9637L7.08285 9.06367C7.08213 8.89799 7.21586 8.76308 7.38154 8.76236L12.7815 8.73876C12.9472 8.73804 13.0821 8.87176 13.0828 9.03745L13.0911 10.9374C13.0918 11.1031 12.9581 11.238 12.7924 11.2387L11.3424 11.2451L11.3575 14.695C11.3582 14.8607 11.2245 14.9956 11.0588 14.9963L9.15884 15.0047C8.99315 15.0054 8.85825 14.8717 8.85753 14.706L8.84245 11.256Z"
          fill="#F16514"
        />
        <Path
          d="M7.87158 26.0098L12.443 25.9898"
          stroke="#F16514"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
      </Svg>
    </Icon>
  );
}
