import React from "react";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function ErrorIcon(props: InterfaceIconProps) {
  return (
    <Icon size="sm" viewBox="0 0 16 14" {...props}>
      <G fill="none">
        <Path
          d="M15.2223 14H0.779291C0.642495 14 0.50811 13.9641 0.389643 13.8958C0.271175 13.8275 0.172799 13.7293 0.104403 13.6111C0.0360065 13.4928 -8.688e-07 13.3587 0 13.2221C8.68832e-07 13.0856 0.0360099 12.9515 0.104408 12.8332L7.32551 0.387549C7.39401 0.269674 7.49234 0.17183 7.61065 0.103815C7.72895 0.0357999 7.86308 0 7.99961 0C8.13614 0 8.27027 0.0357999 8.38857 0.103815C8.50688 0.17183 8.60521 0.269674 8.67371 0.387549L15.8956 12.8332C15.964 12.9514 16 13.0855 16 13.2219C16 13.3584 15.9641 13.4925 15.8958 13.6107C15.8275 13.729 15.7292 13.8272 15.6109 13.8955C15.4925 13.9638 15.3582 13.9999 15.2215 14H15.2223ZM7.22186 10.1107V11.6664H8.77892V10.1107H7.22186ZM7.22186 4.66575V8.55502H8.78048V4.66575H7.22186Z"
          fill="#FF0000"
        />
      </G>
    </Icon>
  );
}
