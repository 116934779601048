import { VStack, Button, HStack, Text } from "native-base";
import React from "react";
import { Venue } from "../../models/requests/Venue";
import { getTranslation } from "../../utils/translation";
import VenueManageCard from "../Card/VenueManageCard";

interface VenueCardListProps {
  venues: Venue[];
  onPressVenue: (venue: Venue) => void;
  onPressEdit: (venue: Venue) => void;
  onPressDelete: (venue: Venue) => void;
  onPressManage: (venue: Venue) => void;
}

const t = getTranslation("constant.button");

export default function VenueList({
  venues,
  onPressVenue,
  onPressDelete,
  onPressEdit,
  onPressManage,
}: VenueCardListProps) {
  return (
    <VStack space="4">
      {Array.isArray(venues) &&
        venues.map((venue) => {
          return (
            <VenueManageCard
              key={venue.id}
              venue={venue}
              onPressVenueCard={() => onPressVenue(venue)}
              footer={
                <HStack
                  alignItems="center"
                  justifyContent="space-between"
                  space="2"
                >
                  <Button
                    flex="1"
                    h="12"
                    borderRadius="18"
                    py="1"
                    onPress={() => onPressManage(venue)}
                    _text={{ fontSize: "md" }}
                  >
                    {t("Manage")}
                  </Button>
                  <Button
                    flex="1"
                    variant="outline"
                    h="12"
                    borderRadius="18"
                    py="1"
                    onPress={() => onPressEdit(venue)}
                    _text={{ fontSize: "md", color: "rs.primary_purple" }}
                  >
                    {t("Edit")}
                  </Button>
                </HStack>
              }
            />
          );
        })}
    </VStack>
  );
}
