import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import SuccessMessage from "../../../components/Feedback/SuccessMessage";
import { MainStackNavigatorParamList } from "../../../routers/Types";
import { getTranslation } from "../../../utils/translation";

const t = getTranslation("screen.ClubScreens.AddVenueSuccess");

export type AddVenueSuccessNavigationProp = NativeStackNavigationProp<
  MainStackNavigatorParamList,
  "AddVenueSuccess"
>;

export type AddVenueSuccessRouteProp = RouteProp<
  MainStackNavigatorParamList,
  "AddVenueSuccess"
>;

interface AddVenueSuccessProps {
  navigation: AddVenueSuccessNavigationProp;
  route: AddVenueSuccessRouteProp;
}

export default function AddVenueSuccess({ route }: AddVenueSuccessProps) {
  return (
    <SuccessMessage
      mx="defaultLayoutSpacing"
      destination="ClubNavigator"
      headerLabel={t("Created a venue")}
      buttonLabel={t("Back")}
    />
  );
}
