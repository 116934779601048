import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  useTheme,
  VStack,
} from "native-base";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { isEqual } from "date-fns";
import useSWR from "swr";
import axios from "axios";
import HeaderLayout from "../../../components/Layout/HeaderLayout";
import { MainStackNavigatorParamList } from "../../../routers/Types";
import ImageDirectory from "../../../assets";
import FilterBadge from "../../../components/Badge/FilterBadge";
import { format24HTo12H, formatHourMinute } from "../../../utils/date";
import { getTranslation } from "../../../utils/translation";
import {
  isTimeSlotsArraySequential,
  TimeSlot,
} from "../../../services/MeetUpVenueServices";
import { formatCoreUrl, formatFileUrl } from "../../../services/ServiceUtil";
import { Venue } from "../../../models/requests/Venue";
import Loading from "../../../components/Loading";
import ErrorMessage from "../../../components/ErrorMessage";
import { VenueDetails as VenueComponent } from "../../../components/VenueDetails";

export type VenueDetailsScreenProps = NativeStackScreenProps<
  MainStackNavigatorParamList,
  "ClubVenueDetails"
>;

const t = getTranslation([
  "screen.ClubScreens.VenueDetails",
  "constant.area",
  "constant.button",
]);

export default function VenueDetails({
  navigation,
  route,
}: VenueDetailsScreenProps) {
  const { space } = useTheme();
  const { venue, venueId } = route.params;
  const {
    data: venueFetched,
    isValidating,
    error,
  } = useSWR<Venue>(
    venueId && !venue ? formatCoreUrl(`/venue/${venueId}`) : null,
    (path) => axios.get(path).then((res) => res.data)
  );

  const venueResult = venueFetched || venue;

  const data =
    (venueResult &&
      venueResult.availableTimeSlots &&
      venueResult.availableTimeSlots?.map((timeSlot) => {
        const timeLabel = new Date(timeSlot.fromTime);
        return {
          name: formatHourMinute(timeLabel),
          isSelected: false,
          value: timeSlot,
        };
      })) ||
    [];

  const [timeslotData, setTimeSlots] = useState(data);

  const onPressTimeSlot = (tappedTimeslot: TimeSlot) => {
    if (timeslotData) {
      let selectedTimeSlots = timeslotData.filter(
        (timeslot) => timeslot.isSelected
      );
      const tapped = tappedTimeslot;
      tapped.isSelected = true;
      if (selectedTimeSlots.length > 0) {
        if (
          tapped.value.fromTime.getTime() >=
          selectedTimeSlots[selectedTimeSlots.length - 1].value.toTime.getTime()
        ) {
          selectedTimeSlots.push(tapped);
        } else {
          selectedTimeSlots = [tapped].concat(selectedTimeSlots);
        }
      } else {
        selectedTimeSlots.push(tapped);
      }

      setTimeSlots(
        timeslotData.map((timeSlot) => {
          const newTimeSlot = timeSlot;
          if (isEqual(tappedTimeslot.value.fromTime, timeSlot.value.fromTime)) {
            newTimeSlot.isSelected = true;
          } else {
            newTimeSlot.isSelected =
              isTimeSlotsArraySequential(selectedTimeSlots) &&
              timeSlot.isSelected;
          }

          return newTimeSlot;
        })
      );
    }
  };

  let imageSource = ImageDirectory.VENUE;
  if (venueResult && venueResult.imageUrl) {
    imageSource = {
      uri: formatFileUrl(venueResult.imageUrl),
    };
  }

  return (
    <HeaderLayout
      headerProps={{
        title: t("Venue details"),
        hasBackButton: true,
        headerLabelStyle: { fontSize: 16 },
        containerStyle: { alignItems: "center" },
      }}
      containerProps={{ marginHorizontal: space.defaultLayoutSpacing }}
    >
      {isValidating && <Loading />}
      {!isValidating && (error || !venueResult) && <ErrorMessage />}
      {venueResult && (
        <VStack space="2" p="2">
          <VenueComponent venue={venueResult} />

          {/* Time */}
          <Heading mt="2" mb="2">
            {t("Time")}
          </Heading>
          <Text>
            {t("Opening Time From To", {
              from: format24HTo12H(venueResult.openingTime),
              to: format24HTo12H(venueResult.closingTime),
            })}
          </Text>
          {timeslotData && timeslotData.length > 0 && (
            <>
              <Text mb="4">{t("Please select at least 1 time slot")}</Text>
              <Box flexDirection="row" flexWrap="wrap">
                {timeslotData.map((item) => {
                  return (
                    <HStack m="1" key={item.name}>
                      <FilterBadge
                        onPress={() => onPressTimeSlot(item)}
                        label={item.name}
                        isActive={item.isSelected}
                      />
                    </HStack>
                  );
                })}
              </Box>
            </>
          )}
        </VStack>
      )}
    </HeaderLayout>
  );
}
