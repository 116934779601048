import React from "react";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function ReportIcon({
  props,
  color,
}: {
  props?: InterfaceIconProps;
  color?: string;
}) {
  return (
    <Icon size="lg" viewBox="0 0 24 24" {...props}>
      <Path
        d="M18.8401 12.8301L18.8436 12.8292H11.1685V5.15452C11.1686 4.66725 10.9628 4.20272 10.6016 3.87565C10.2403 3.54857 9.75763 3.38977 9.27284 3.43826C4.03336 3.96216 0.03314 8.35428 0.000207142 13.6199C-0.0329233 18.8856 3.91186 23.3279 9.14427 23.9175C9.53143 23.9614 9.92089 23.984 10.3105 23.9854C15.5974 23.9882 20.0262 19.9854 20.5563 14.7254C20.6047 14.2406 20.4458 13.7581 20.1189 13.397C19.7918 13.0358 19.3273 12.8301 18.84 12.8304L18.8401 12.8301ZM15.7098 20.36C12.2969 23.1345 7.339 22.8793 4.22887 19.7689C1.1187 16.6588 0.863854 11.7008 3.6387 8.288C5.07385 6.50128 7.17077 5.37078 9.45247 5.15419V12.8293C9.45247 13.7773 10.2208 14.5456 11.1687 14.5456H18.8439C18.8469 14.5474 18.849 14.5505 18.849 14.5542C18.6261 16.8328 17.4946 18.9256 15.7099 20.3603L15.7098 20.36Z"
        fill={color || "#000000"}
      />
      <Path
        d="M23.9925 9.22513C23.4972 4.35377 19.6445 0.501977 14.7727 0.00798431C14.2889 -0.0387172 13.808 0.120944 13.4485 0.447841C13.089 0.774738 12.8842 1.23823 12.8848 1.72424V9.39669C12.8848 10.3446 13.6531 11.1129 14.601 11.1129H22.2762C22.7621 11.1135 23.2257 10.9087 23.5526 10.5492C23.8793 10.1895 24.0391 9.70861 23.9924 9.22504L23.9925 9.22513ZM14.6012 9.39677V1.71574C18.6607 2.12546 21.8704 5.337 22.2778 9.39677H14.6012Z"
        fill={color || "#000000"}
      />
    </Icon>
  );
}
