import React from "react";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function LocationIcon({
  color,
  size,
  ...otherProps
}: {
  color?: string;
  size?: string;
  otherProps?: InterfaceIconProps;
}) {
  return (
    <Icon size={size || "sm"} viewBox="0 0 12 16" {...otherProps}>
      <G fill="none">
        <Path
          d="M5.99976 4.12659C5.60419 4.12659 5.21751 4.24388 4.88862 4.46365C4.55972 4.68341 4.30337 4.99577 4.152 5.36122C4.00062 5.72667 3.96102 6.1288 4.03819 6.51677C4.11536 6.90473 4.30584 7.2611 4.58554 7.5408C4.86525 7.8205 5.22161 8.01099 5.60958 8.08816C5.99754 8.16533 6.39967 8.12572 6.76512 7.97435C7.13058 7.82297 7.44293 7.56663 7.6627 7.23773C7.88246 6.90883 7.99976 6.52215 7.99976 6.12659C7.99915 5.59634 7.78825 5.08798 7.4133 4.71304C7.03836 4.3381 6.53 4.12719 5.99976 4.12659ZM5.99976 7.12659C5.80198 7.12659 5.60864 7.06794 5.44419 6.95806C5.27974 6.84817 5.15156 6.692 5.07588 6.50927C5.00019 6.32654 4.98039 6.12548 5.01897 5.9315C5.05756 5.73752 5.1528 5.55933 5.29265 5.41948C5.4325 5.27963 5.61068 5.18439 5.80467 5.1458C5.99865 5.10722 6.19971 5.12702 6.38244 5.20271C6.56517 5.2784 6.72134 5.40657 6.83123 5.57102C6.94111 5.73547 6.99976 5.92881 6.99976 6.12659C6.99945 6.39171 6.89399 6.64589 6.70652 6.83336C6.51905 7.02083 6.26488 7.12628 5.99976 7.12659Z"
          fill={color || "#6D6D6D"}
        />
        <Path
          d="M9.84295 2.2805C8.89272 1.33051 7.62428 0.76618 6.28243 0.696422C4.94059 0.626663 3.62047 1.05642 2.57686 1.90276C1.53325 2.74909 0.840123 3.952 0.631263 5.27932C0.422404 6.60664 0.712614 7.96428 1.44588 9.09022L5.22423 14.8906C5.30831 15.0197 5.42327 15.1257 5.5587 15.1991C5.69413 15.2725 5.84573 15.311 5.99977 15.311C6.15381 15.311 6.30542 15.2725 6.44085 15.1991C6.57627 15.1257 6.69124 15.0197 6.77532 14.8906L10.5538 9.09022C11.2342 8.04575 11.5349 6.79915 11.4054 5.55935C11.276 4.31956 10.7244 3.16193 9.84295 2.2805ZM9.71591 8.54437L5.99979 14.2491L2.28363 8.54437C1.14613 6.79815 1.3901 4.46128 2.86373 2.98759C3.27556 2.57575 3.76447 2.24906 4.30256 2.02617C4.84065 1.80328 5.41737 1.68857 5.99979 1.68857C6.58221 1.68857 7.15893 1.80328 7.69702 2.02617C8.23511 2.24906 8.72402 2.57575 9.13585 2.98759C10.6095 4.46128 10.8534 6.79815 9.71591 8.54437Z"
          fill={color || "#6D6D6D"}
        />
      </G>
    </Icon>
  );
}
