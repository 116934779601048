import React from "react";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function BellIcon(props: InterfaceIconProps) {
  return (
    <Icon size="xl" viewBox="0 0 21 22" {...props}>
      <G fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5001 0C8.64356 0 6.86309 0.737498 5.55033 2.05025C4.23758 3.36301 3.50008 5.14348 3.50008 7C3.50008 10.3527 2.78301 12.4346 2.12218 13.6461C1.79039 14.2544 1.4673 14.6535 1.24288 14.8904C1.1304 15.0091 1.04202 15.0878 0.9888 15.1322C0.962178 15.1544 0.944325 15.168 0.936625 15.1737L0.933767 15.1758C0.575282 15.4221 0.416643 15.8725 0.542986 16.2898C0.670684 16.7115 1.05941 17 1.50008 17H19.5001C19.9407 17 20.3295 16.7115 20.4572 16.2898C20.5835 15.8725 20.4249 15.4221 20.0664 15.1758L20.0635 15.1737C20.0558 15.168 20.038 15.1544 20.0114 15.1322C19.9581 15.0878 19.8698 15.0091 19.7573 14.8904C19.5329 14.6535 19.2098 14.2544 18.878 13.6461C18.2171 12.4346 17.5001 10.3527 17.5001 7C17.5001 5.14349 16.7626 3.36301 15.4498 2.05025C14.1371 0.737498 12.3566 0 10.5001 0ZM17.1222 14.6039C17.1983 14.7434 17.2747 14.8753 17.3508 15H3.6494C3.72549 14.8753 3.80188 14.7434 3.87797 14.6039C4.71715 13.0654 5.50008 10.6473 5.50008 7C5.50008 5.67392 6.02686 4.40215 6.96454 3.46447C7.90223 2.52678 9.174 2 10.5001 2C11.8262 2 13.0979 2.52678 14.0356 3.46447C14.9733 4.40215 15.5001 5.67392 15.5001 7C15.5001 10.6473 16.283 13.0654 17.1222 14.6039Z"
          fill="#2D3648"
        />
        <Path
          d="M9.6351 19.4982C9.35798 19.0205 8.74605 18.8579 8.26833 19.135C7.7906 19.4121 7.62798 20.024 7.9051 20.5018C8.16881 20.9564 8.54733 21.3337 9.00276 21.5961C9.45818 21.8584 9.97453 21.9965 10.5001 21.9965C11.0257 21.9965 11.542 21.8584 11.9974 21.5961C12.4529 21.3337 12.8314 20.9564 13.0951 20.5018C13.3722 20.024 13.2096 19.4121 12.7319 19.135C12.2541 18.8579 11.6422 19.0205 11.3651 19.4982C11.2772 19.6498 11.151 19.7756 10.9992 19.863C10.8474 19.9504 10.6753 19.9965 10.5001 19.9965C10.3249 19.9965 10.1528 19.9504 10.001 19.863C9.84918 19.7756 9.723 19.6498 9.6351 19.4982Z"
          fill="#2D3648"
        />
      </G>
    </Icon>
  );
}
