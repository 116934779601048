import React from "react";
import { HStack, VStack, Text, Image, Pressable } from "native-base";
import Card from "./Card";
import CalendarIcon from "../Icons/CalendarIcon";
import ClockIcon from "../Icons/ClockIcon";
import LocationIcon from "../Icons/LocationIcon";
import MoneyIcon from "../Icons/MoneyIcon";
import { getTranslation } from "../../utils/translation";
import { format24HTo12H, formatUtcToLocalDate } from "../../utils/date";
import { formatName } from "../../utils/name";
import ApplicationsIcon from "../Icons/ApplicationsIcon";
import { DaysOfWeek } from "../../models/Response";
import { User, UserType } from "../../models/User";
import { Venue } from "../../models/requests/Venue";
import RoundedCurrencyIcon from "../Icons/RoundedCurrencyIcon";
import { SCREEN_WIDTH } from "../../constants/constants";
import ImageDirectory from "../../assets";
import { formatFileUrl } from "../../services/ServiceUtil";
import { useAuth } from "../../hooks/UseAuth";

const t = getTranslation([
  "component.Card.VenueManageCard",
  "constant.district",
  "constant.profile",
  "constant.week",
  "constant.button",
]);

export interface VenueManageCardProps {
  venue: Venue;
  onPressVenueCard?: (venue: Venue) => void;
  footer?: React.ReactNode;
  pendingParticipantNumber?: number;
  approvedParticipantNumber?: number;
  onPressApplicationDetails?: () => void;
}

export default function VenueManageCard({
  venue,
  onPressVenueCard,
  footer,
  pendingParticipantNumber = 0,
  approvedParticipantNumber = 0,
  onPressApplicationDetails,
}: VenueManageCardProps) {
  const venueDate =
    Array.isArray(venue.availableTimeSlots) &&
    venue.availableTimeSlots.length > 0 &&
    venue.availableTimeSlots[0].fromTime;

  let imageSource = ImageDirectory.VENUE;
  if (venue.imageUrl) {
    imageSource = {
      uri: formatFileUrl(venue.imageUrl),
    };
  }

  const { user } = useAuth();
  return (
    <VStack bg="rs.lightGrey" borderRadius="3xl">
      <Pressable
        onPress={() => {
          onPressVenueCard?.(venue);
        }}
      >
        <Card
          onPress={() => {
            onPressVenueCard?.(venue);
          }}
          body={
            <VStack>
              <Image
                alt="Venue Image"
                source={imageSource}
                style={{
                  flex: 1,
                  alignSelf: "center",
                  width: SCREEN_WIDTH * 0.85,
                  height: 170,
                  borderRadius: 16,
                }}
                resizeMode="cover"
              />
              <Text fontWeight="700" fontSize="lg" mt="2" numberOfLines={2}>
                {venue.name}
              </Text>
              <HStack mt="4">
                {venueDate && (
                  <HStack alignItems="center">
                    <CalendarIcon />
                    <Text fontWeight="400" fontSize="sm" ml="2">
                      {formatUtcToLocalDate(venueDate)}
                    </Text>
                  </HStack>
                )}

                <HStack alignItems="center" ml={venueDate ? "6" : "0"}>
                  <ClockIcon />
                  <Text fontWeight="400" fontSize="sm" ml="2">
                    {`${format24HTo12H(venue.openingTime)} - ${format24HTo12H(
                      venue.closingTime
                    )}`}
                  </Text>
                </HStack>
              </HStack>

              <HStack alignItems="center">
                <HStack alignItems="center" mt="4">
                  <LocationIcon />
                  <Text fontWeight="400" fontSize="sm" ml="2">
                    {t(venue.district)}
                  </Text>
                </HStack>

                <HStack alignItems="center" mt="4" ml="6">
                  <RoundedCurrencyIcon />
                  <Text fontWeight="400" fontSize="sm" ml="2">
                    {`${venue.fee} ${"hkd"}`}
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          }
          footer={footer}
        />
      </Pressable>
      {/* {user?.userType === UserType.ClubStaff && (
        <HStack p="defaultLayoutSpacing" justifyContent="space-between">
          <HStack space={2} flexDirection="row">
            <ApplicationsIcon />
            <Text>
              {pendingParticipantNumber} {t("Pending")}
            </Text>
            <Text>
              {approvedParticipantNumber} {t("Approved")}
            </Text>
          </HStack>
          <Pressable
            onPress={() => {
              onPressApplicationDetails?.();
            }}
          >
            <Text color="rs.primary_purple">
              {t("Details")}
              {">"}
            </Text>
          </Pressable>
        </HStack>
      )} */}
    </VStack>
  );
}
