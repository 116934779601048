/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Icon } from "native-base";
import Svg, { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

interface ChatBotIconProps extends InterfaceIconProps {
  fillColor?: string;
}

export default function ChatBotIcon(props: ChatBotIconProps) {
  return (
    <Icon size="5xl" viewBox="0 0 43 44" {...props}>
      <Svg width="43" height="44" viewBox="0 0 43 44" fill="none">
        <Path
          d="M20.5567 34.788C19.8175 35.1977 18.9667 35.4315 18.0614 35.4315L17.9279 35.4315C16.5797 36.6337 14.6894 37.3294 12.5028 37.3294C10.3159 37.3294 8.42552 36.6337 7.07737 35.4315L6.94384 35.4315C6.03858 35.4315 5.18779 35.1977 4.44859 34.788C6.0003 37.3676 8.95971 38.9564 12.5028 38.9564C16.0459 38.9564 19.0054 37.3676 20.5567 34.788ZM1.7915 30.2791C1.7915 33.1246 4.09829 35.4314 6.94343 35.4314L18.061 35.4314C20.9065 35.4314 23.2129 33.1246 23.2129 30.2791C23.2129 28.3211 22.1208 26.618 20.5119 25.7464L20.4865 25.7328C19.0191 23.1638 16.3832 21.3677 13.3156 21.0957L13.3156 19.3019C14.3396 18.9614 15.078 17.9953 15.078 16.857C15.078 15.4343 13.9246 14.2808 12.5022 14.2808C11.0795 14.2808 9.92602 15.4342 9.92602 16.857C9.92602 17.9953 10.6644 18.9611 11.6885 19.3019L11.6885 21.0957C8.62087 21.3677 5.9853 23.1641 4.51755 25.7328L4.49221 25.7464C2.88375 26.618 1.79117 28.3211 1.79117 30.2791L1.7915 30.2791ZM6.94343 26.7542L18.061 26.7542C20.0077 26.7542 21.5858 28.3325 21.5858 30.2791C21.5858 32.2261 20.0076 33.8044 18.061 33.8044L6.94343 33.8044C4.9967 33.8044 3.41856 32.2261 3.41856 30.2791C3.41856 28.3324 4.9968 26.7542 6.94343 26.7542ZM12.5024 22.6869C14.6847 22.6869 16.6534 23.6246 18.0398 25.1272L6.96493 25.1272C8.35094 23.6246 10.32 22.6869 12.5024 22.6869ZM12.5024 15.9079C13.0263 15.9079 13.4511 16.3327 13.4511 16.857C13.4511 17.3809 13.0263 17.8061 12.5024 17.8061C11.9781 17.8061 11.5533 17.3809 11.5533 16.857C11.5533 16.3327 11.9781 15.9079 12.5024 15.9079Z"
          fill="black"
        />
        <Path
          d="M36.5005 5.87501L23.2137 5.87501C21.1172 5.87501 19.4175 7.57469 19.4175 9.67153L19.4175 18.0492C19.4175 19.4125 20.5225 20.5179 21.8858 20.5179L24.2188 20.5179C24.2937 20.5179 24.3546 20.5784 24.3546 20.6533L24.3546 22.611C24.3546 23.0604 24.7185 23.4243 25.1679 23.4243C25.354 23.4243 25.5345 23.3608 25.679 23.2439L29.0163 20.5478C29.0401 20.5285 29.0704 20.5179 29.1014 20.5179L36.5004 20.5179C38.5972 20.5179 40.2969 18.8182 40.2969 16.7214L40.2969 9.67152C40.2969 7.57468 38.5972 5.875 36.5004 5.875L36.5005 5.87501ZM25.9814 20.6532C25.9814 19.6798 25.1923 18.8907 24.219 18.8907L21.886 18.8907C21.4215 18.8907 21.0447 18.514 21.0447 18.0491L21.0447 9.67139C21.0447 8.47335 22.0158 7.50192 23.2138 7.50192L36.5006 7.50193C37.6986 7.50193 38.6701 8.47335 38.6701 9.67139L38.6701 16.7212C38.6701 17.9193 37.6986 18.8907 36.5006 18.8907L29.1016 18.8907C28.6984 18.8907 28.3076 19.0288 27.994 19.2822L25.9815 20.9077L25.9814 20.6532Z"
          fill="black"
        />
        <Path
          d="M29.8567 14.2812C29.4073 14.2812 29.043 14.6455 29.043 15.0949C29.043 15.544 29.4073 15.9082 29.8567 15.9082L35.822 15.9082C36.2714 15.9082 36.6357 15.544 36.6357 15.0949C36.6357 14.6455 36.2714 14.2812 35.822 14.2812L29.8567 14.2812ZM23.7106 10.3496C23.2612 10.3496 22.8969 10.7135 22.8969 11.1629C22.8969 11.6123 23.2612 11.9763 23.7106 11.9763L35.8218 11.9763C36.2712 11.9763 36.6355 11.6123 36.6355 11.1629C36.6355 10.7135 36.2712 10.3496 35.8218 10.3496L23.7106 10.3496Z"
          fill="black"
        />
        <Path
          d="M9.24858 28.4531C8.79917 28.4531 8.43526 28.817 8.43525 29.2664L8.43525 31.5381C8.43525 31.9871 8.79917 32.3514 9.24858 32.3514C9.69799 32.3514 10.0619 31.9871 10.0619 31.5381L10.0619 29.2664C10.0619 28.817 9.69799 28.4531 9.24858 28.4531ZM15.824 28.4531C15.375 28.4531 15.0107 28.817 15.0107 29.2664L15.0107 31.5381C15.0107 31.9871 15.375 32.3514 15.824 32.3514C16.2734 32.3514 16.6377 31.9871 16.6377 31.5381L16.6377 29.2664C16.6377 28.817 16.2734 28.4531 15.824 28.4531Z"
          fill="black"
        />
      </Svg>
    </Icon>
  );
}
