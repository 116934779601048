import React from "react";
import { Icon } from "native-base";
import Svg, { Circle, G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function VectorIcon({
  color,
  ...otherProps
}: Omit<InterfaceIconProps, "color"> & { color?: string }) {
  return (
    <Icon size="md" viewBox="0 0 24 24" {...otherProps}>
      <Path
        d="M12 21.6C13.5 21.6 14.7752 21.0752 15.8256 20.0256C16.8752 18.9752 17.4 17.7 17.4 16.2C17.4 14.7 16.8752 13.4248 15.8256 12.3744C14.7752 11.3248 13.5 10.8 12 10.8C10.5 10.8 9.2248 11.3248 8.1744 12.3744C7.1248 13.4248 6.6 14.7 6.6 16.2C6.6 17.7 7.1248 18.9752 8.1744 20.0256C9.2248 21.0752 10.5 21.6 12 21.6ZM8.49 9.24C8.89 9.02 9.3152 8.8448 9.7656 8.7144C10.2152 8.5848 10.67 8.5 11.13 8.46L8.1 2.4H5.1L8.49 9.24ZM15.51 9.24L18.93 2.4H15.9L12.87 8.46C13.33 8.5 13.7852 8.5848 14.2356 8.7144C14.6852 8.8448 15.11 9.02 15.51 9.24ZM5.28 20.16C4.94 19.58 4.6752 18.9548 4.4856 18.2844C4.2952 17.6148 4.2 16.92 4.2 16.2C4.2 15.48 4.2952 14.7848 4.4856 14.1144C4.6752 13.4448 4.94 12.82 5.28 12.24C4.44 12.52 3.75 13.0152 3.21 13.7256C2.67 14.4352 2.4 15.26 2.4 16.2C2.4 17.14 2.67 17.9648 3.21 18.6744C3.75 19.3848 4.44 19.88 5.28 20.16ZM18.72 20.16C19.56 19.88 20.25 19.3848 20.79 18.6744C21.33 17.9648 21.6 17.14 21.6 16.2C21.6 15.26 21.33 14.4352 20.79 13.7256C20.25 13.0152 19.56 12.52 18.72 12.24C19.06 12.82 19.3252 13.4448 19.5156 14.1144C19.7052 14.7848 19.8 15.48 19.8 16.2C19.8 16.92 19.7052 17.6148 19.5156 18.2844C19.3252 18.9548 19.06 19.58 18.72 20.16ZM12 24C11.2 24 10.4352 23.8848 9.7056 23.6544C8.9752 23.4248 8.3 23.11 7.68 22.71C7.5 22.75 7.32 22.7752 7.14 22.7856C6.96 22.7952 6.77 22.8 6.57 22.8C4.75 22.8 3.2 22.16 1.92 20.88C0.64 19.6 0 18.05 0 16.23C0 14.49 0.58 13 1.74 11.76C2.9 10.52 4.33 9.83 6.03 9.69L1.2 0H9.6L12 4.8L14.4 0H22.8L18 9.63C19.7 9.79 21.1248 10.49 22.2744 11.73C23.4248 12.97 24 14.46 24 16.2C24 18.04 23.36 19.6 22.08 20.88C20.8 22.16 19.24 22.8 17.4 22.8C17.22 22.8 17.0352 22.7952 16.8456 22.7856C16.6552 22.7752 16.47 22.75 16.29 22.71C15.67 23.11 15 23.4248 14.28 23.6544C13.56 23.8848 12.8 24 12 24ZM12 19.2L11.07 17.13L9 16.2L11.07 15.27L12 13.2L12.93 15.27L15 16.2L12.93 17.13L12 19.2Z"
        fill={color || "white"}
      />
    </Icon>
  );
}
