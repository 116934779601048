import React from "react";
import { Icon } from "native-base";
import { Circle, G, Path, Svg } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function LightPubIcon({
  props,
}: {
  props?: InterfaceIconProps;
}) {
  return (
    <Icon size="md" viewBox="0 0 24 24" alignSelf="center" {...props}>
      <Svg width="25" height="25" viewBox="0 0 25 25" fill="none">
        <Circle
          opacity="0.15"
          cx="12.0523"
          cy="12.0529"
          r="12"
          transform="rotate(-0.250408 12.0523 12.0529)"
          fill="#31095E"
        />
        <Path
          d="M10.9359 18.0577L13.2216 18.0477M8.04472 10.2703C8.03986 9.15637 8.45661 8.08626 9.20331 7.29533C9.95 6.50441 10.9655 6.05747 12.0263 6.05283C13.0872 6.04819 14.1065 6.48624 14.8601 7.27061C15.6137 8.05498 16.0398 9.12141 16.0446 10.2353C16.048 10.9115 15.8951 11.5785 15.5992 12.179C15.3032 12.7795 14.873 13.2956 14.3454 13.6832L14.0424 15.2241C14.0027 15.5081 13.8673 15.7676 13.6608 15.9557C13.4544 16.1437 13.1905 16.2478 12.9172 16.249L11.2246 16.2564C10.9513 16.2576 10.6865 16.1558 10.4784 15.9696C10.2704 15.7833 10.1327 15.525 10.0905 15.2414L9.77406 13.7097C9.24289 13.3254 8.80817 12.8119 8.50702 12.2129C8.20587 11.614 8.04726 10.9474 8.04472 10.2703Z"
          stroke="#31095E"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4215 10.6798L10.8465 10.6823C10.6808 10.683 10.5459 10.5493 10.5452 10.3836L10.5423 9.73359C10.5416 9.56791 10.6753 9.43301 10.841 9.43228L13.241 9.42179C13.4067 9.42107 13.5416 9.5548 13.5423 9.72048L13.5451 10.3705C13.5459 10.5362 13.4121 10.6711 13.2465 10.6718L12.6715 10.6743L12.6783 12.2493C12.6791 12.415 12.5453 12.5499 12.3797 12.5506L11.7297 12.5534C11.564 12.5542 11.4291 12.4204 11.4284 12.2547L11.4215 10.6798Z"
          fill="#31095E"
        />
        <Path
          d="M10.9355 18.0586L13.2212 18.0486"
          stroke="#31095E"
          stroke-width="2.5"
          stroke-linejoin="round"
        />
      </Svg>
    </Icon>
  );
}
