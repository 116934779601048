import React from "react";
import { Icon } from "native-base";
import Svg, { Path, G } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function RefreshIconV2(props: InterfaceIconProps) {
  return (
    <Icon size="sm" viewBox="0 0 24 24" {...props}>
      <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <Path
          d="M19.9877 12.263C19.9428 11.4197 19.7739 10.5885 19.4867 9.79806C19.3801 9.51844 19.1387 9.31793 18.8518 9.27068C18.5649 9.22343 18.2753 9.33656 18.0901 9.56809C17.9049 9.79975 17.8517 10.1152 17.95 10.3981C18.4804 11.8509 18.4979 13.4507 17.9992 14.9156C17.5007 16.3803 16.5179 17.6156 15.2247 18.4032C13.9315 19.191 12.4109 19.4803 10.9316 19.2205C9.45216 18.9605 8.10909 18.168 7.13965 16.9827C6.17004 15.7975 5.63629 14.2958 5.63273 12.7427C5.62902 11.1897 6.1556 9.68528 7.11959 8.49515C8.08357 7.30495 9.42291 6.50567 10.901 6.23814C12.379 5.97076 13.9009 6.25251 15.198 7.0336L14.003 7.44582L14.0029 7.44597C13.7242 7.54188 13.516 7.7845 13.457 8.08234C13.3979 8.38017 13.4969 8.68797 13.7165 8.88974C13.9362 9.09167 14.2432 9.15684 14.5221 9.06093L16.986 8.21098C17.1501 8.15434 17.293 8.04534 17.3939 7.89977C17.4947 7.75405 17.5486 7.57916 17.5476 7.4V4.84999C17.5476 4.54632 17.3912 4.26571 17.1371 4.11388C16.883 3.96204 16.5698 3.96204 16.3157 4.11388C16.0616 4.26571 15.9051 4.54632 15.9051 4.84999V5.49434C14.5827 4.72224 13.0737 4.35966 11.5581 4.44971C9.47881 4.5691 7.52649 5.52294 6.11574 7.10828C4.70505 8.69366 3.94705 10.7858 4.00288 12.9405C4.0587 15.0951 4.92381 17.1422 6.41468 18.6471C7.90551 20.1519 9.90454 20.996 11.987 21C12.1372 21 12.2875 21 12.4386 20.9872V20.9873C14.5575 20.8662 16.5431 19.8791 17.9588 18.2429C19.3746 16.6069 20.1044 14.4558 19.9879 12.2628L19.9877 12.263Z"
          fill="black"
        />
      </Svg>
    </Icon>
  );
}
