import React from "react";
import { Icon, useTheme } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function CoffeeIcon({ props }: { props?: InterfaceIconProps }) {
  return (
    <Icon size="lg" viewBox="0 0 17 17" {...props}>
      <G fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F16514"
          d="M0.915323 4.32519C0.913211 3.84194 1.30325 3.44848 1.78649 3.44637L9.53642 3.4125C10.0197 3.41039 10.4131 3.80042 10.4152 4.28367L10.4436 10.7836C10.45 12.2333 9.27987 13.4137 7.83014 13.4201L3.58018 13.4386C2.13044 13.445 0.950067 12.2749 0.943731 10.8251L0.915323 4.32519ZM2.16695 4.69472L2.19372 10.8197C2.19704 11.579 2.81533 12.192 3.57472 12.1886L7.82467 12.1701C8.58406 12.1667 9.19697 11.5485 9.19365 10.7891L9.16688 4.66413L2.16695 4.69472Z"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.19092 10.1641L9.17016 5.41411L11.5451 5.40373C12.4426 5.39981 13.1733 6.12416 13.1772 7.02161L13.1838 8.5216C13.1877 9.41905 12.4633 10.1498 11.5659 10.1537L9.19092 10.1641ZM10.4354 8.90861L11.5604 8.90369C11.7675 8.90279 11.9347 8.73416 11.9338 8.52706L11.9272 7.02707C11.9263 6.81997 11.7577 6.65281 11.5506 6.65372L10.4256 6.65863L10.4354 8.90861Z"
          fill="#F16514"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.12166 0.0652438C3.34257 0.0642783 3.52244 0.24258 3.5234 0.463492L3.52865 1.66348C3.52961 1.88439 3.35131 2.06426 3.1304 2.06522L2.6804 2.06719C2.45949 2.06816 2.27962 1.88986 2.27866 1.66894L2.27341 0.468955C2.27245 0.248043 2.45075 0.068176 2.67166 0.0672105L3.12166 0.0652438Z"
          fill="#F16514"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.62166 0.0545016C5.84257 0.0535361 6.02244 0.231838 6.0234 0.45275L6.02865 1.65274C6.02961 1.87365 5.85131 2.05352 5.6304 2.05448L5.1804 2.05645C4.95949 2.05741 4.77962 1.87911 4.77866 1.6582L4.77341 0.458213C4.77245 0.237301 4.95075 0.0574338 5.17166 0.0564683L5.62166 0.0545016Z"
          fill="#F16514"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.12166 0.0437594C8.34257 0.0427939 8.52244 0.221096 8.5234 0.442007L8.52865 1.642C8.52961 1.86291 8.35131 2.04277 8.1304 2.04374L7.6804 2.04571C7.45949 2.04667 7.27962 1.86837 7.27866 1.64746L7.27341 0.44747C7.27245 0.226559 7.45075 0.0466916 7.67166 0.0457261L8.12166 0.0437594Z"
          fill="#F16514"
        />
      </G>
    </Icon>
  );
}
