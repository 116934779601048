import React, { useState } from "react";
import { Box, Button, Heading, HStack, Text, useTheme } from "native-base";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { isEqual } from "date-fns";
import useSWR from "swr";
import axios from "axios";
import HeaderLayout from "../../components/Layout/HeaderLayout";
import { MainStackNavigatorParamList } from "../../routers/Types";
import FilterBadge from "../../components/Badge/FilterBadge";
import { formatHourMinute, formatUtcToLocalTime } from "../../utils/date";
import { getTranslation } from "../../utils/translation";
import {
  applyMeetUpVenue,
  isTimeSlotsArraySequential,
  TimeSlot,
} from "../../services/MeetUpVenueServices";
import { formatCoreUrl } from "../../services/ServiceUtil";
import { Venue, VenueStatus } from "../../models/requests/Venue";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import { VenueDetails } from "../../components/VenueDetails";
import { showApiToastError } from "../../components/ApiToastError";

type VenueDetailsScreenProps = NativeStackScreenProps<
  MainStackNavigatorParamList,
  "VenueDetail"
>;

const t = getTranslation([
  "screen.PlayerScreens.VenueDetails",
  "constant.area",
  "constant.button",
  "formInput",
]);

export default function VenueDetail({
  navigation,
  route,
}: VenueDetailsScreenProps) {
  const { space } = useTheme();
  const { venue, venueId } = route.params;

  const {
    data: venueMeetup,
    isValidating,
    mutate,
    error: fetchError,
  } = useSWR<Venue>(
    venueId ? formatCoreUrl(`/venue/${venueId}`) : null,
    (path) => axios.get(path).then((res) => res.data)
  );

  const venueResult = venue || venueMeetup;

  const data =
    (venueResult &&
      venueResult.availableTimeSlots?.map((timeSlot: any) => {
        const timeLabel = new Date(timeSlot.fromTime);
        return {
          name: formatHourMinute(timeLabel),
          isSelected: false,
          value: timeSlot,
        };
      })) ||
    [];

  const [timeslotData, setTimeSlots] = useState(data);

  const onPressTimeSlot = (tappedTimeslot: TimeSlot) => {
    if (timeslotData) {
      let selectedTimeSlots = timeslotData.filter(
        (timeslot: any) => timeslot.isSelected
      );
      const tapped = tappedTimeslot;
      tapped.isSelected = true;
      if (selectedTimeSlots.length > 0) {
        if (
          tapped.value.fromTime.getTime() >=
          selectedTimeSlots[selectedTimeSlots.length - 1].value.toTime.getTime()
        ) {
          selectedTimeSlots.push(tapped);
        } else {
          selectedTimeSlots = [tapped].concat(selectedTimeSlots);
        }
      } else {
        selectedTimeSlots.push(tapped);
      }

      setTimeSlots(
        timeslotData.map((timeSlot: any) => {
          const newTimeSlot = timeSlot;
          if (isEqual(tappedTimeslot.value.fromTime, timeSlot.value.fromTime)) {
            newTimeSlot.isSelected = true;
          } else {
            newTimeSlot.isSelected =
              isTimeSlotsArraySequential(selectedTimeSlots) &&
              timeSlot.isSelected;
          }

          return newTimeSlot;
        })
      );
    }
  };

  const onPressApply = async () => {
    if (venueResult) {
      const selectedTimeSlots = timeslotData
        ?.filter((timeslot: any) => timeslot.isSelected)
        .map((timeSlot: any) => {
          return {
            fromTime: timeSlot.value.fromTime,
            toTime: timeSlot.value.toTime,
          };
        });
      try {
        await applyMeetUpVenue({
          action: "BookVenue",
          selectedTimeSlots,
          venueId: venueResult.id,
        });
        navigation.reset({
          index: 0,
          routes: [
            {
              name: "VenueApplySuccess",
              params: {
                destination: "PlayerNavigator",
                nestedDestination: "VenueList",
                venue: route.params.venue,
                appliedFromTime:
                  selectedTimeSlots && selectedTimeSlots[0]?.fromTime
                    ? formatUtcToLocalTime(selectedTimeSlots[0]?.fromTime)
                    : "",
                appliedToTime:
                  selectedTimeSlots && selectedTimeSlots[0]?.toTime
                    ? formatUtcToLocalTime(
                        selectedTimeSlots[selectedTimeSlots.length - 1]?.toTime
                      )
                    : "",
              },
            },
          ],
        });
      } catch (error) {
        showApiToastError(error);
      }
    }
  };

  return (
    <HeaderLayout
      headerProps={{
        title: t("Venue details"),
        headerLabelStyle: { fontSize: 16 },
        containerStyle: { alignItems: "center" },
      }}
      containerProps={{ marginHorizontal: space.defaultLayoutSpacing }}
    >
      {isValidating && <Loading />}
      {!isValidating && (fetchError || !venueResult) && <ErrorMessage />}
      {venueResult && (
        <>
          <VenueDetails venue={venueResult} />
          {/* Time */}
          <Heading mt="4" mb="4">
            {t("Time")}
          </Heading>
          <Text mb="4">{t("Please select at least 1 time slot")}</Text>
          <Box flexDirection="row" flexWrap="wrap">
            {timeslotData.map((item) => {
              return (
                <HStack m="1" key={item.name}>
                  <FilterBadge
                    onPress={() => onPressTimeSlot(item)}
                    label={item.name}
                    isActive={item.isSelected}
                  />
                </HStack>
              );
            })}
          </Box>
          {venueResult.status === VenueStatus.Open && (
            <Button
              isDisabled={
                !(
                  timeslotData.findIndex(
                    (timeSlot) => timeSlot.isSelected === true
                  ) !== -1
                )
              }
              mt="6"
              onPress={onPressApply}
            >
              {t("Apply")}
            </Button>
          )}
        </>
      )}
    </HeaderLayout>
  );
}
