import React from "react";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function PasswordIcon(props: InterfaceIconProps) {
  return (
    <Icon size="md" viewBox="0 0 18 21" {...props}>
      <Path
        d="M14.9 7V7.1H15H17C17.2387 7.1 17.4676 7.19482 17.6364 7.3636C17.8052 7.53239 17.9 7.76131 17.9 8V20C17.9 20.2387 17.8052 20.4676 17.6364 20.6364C17.4676 20.8052 17.2387 20.9 17 20.9H1C0.761305 20.9 0.532386 20.8052 0.363604 20.6364C0.194821 20.4676 0.1 20.2387 0.1 20V8C0.1 7.76131 0.194821 7.53239 0.363604 7.3636C0.532387 7.19482 0.761305 7.1 1 7.1H3H3.1V7V6C3.1 4.43522 3.72161 2.93453 4.82807 1.82807C5.93454 0.721605 7.43522 0.1 9 0.1C10.5648 0.1 12.0655 0.721605 13.1719 1.82807C14.2784 2.93453 14.9 4.43522 14.9 6V7ZM2 8.9H1.9V9V19V19.1H2H16H16.1V19V9V8.9H16H2ZM13 7.1H13.1V7V6C13.1 4.91261 12.668 3.86976 11.8991 3.10086C11.1302 2.33196 10.0874 1.9 9 1.9C7.91261 1.9 6.86976 2.33196 6.10086 3.10086C5.33196 3.86976 4.9 4.91261 4.9 6V7V7.1H5H13ZM9.9 13.1V14.9H8.1V13.1H9.9ZM5.9 13.1V14.9H4.1V13.1H5.9ZM13.9 13.1V14.9H12.1V13.1H13.9Z"
        fill="black"
        stroke="white"
        strokeWidth="0.2"
      />
    </Icon>
  );
}
