/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Icon } from "native-base";
import { Path, G } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

interface EyesIconProps extends InterfaceIconProps {
  strokeColor?: string;
}

export default function EyesIcon(props: EyesIconProps) {
  return (
    <Icon size="md" viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M0.590781 11.6935C0.624822 11.6593 1.44345 10.5337 2.7908 9.39109C4.5985 7.85629 6.49156 7.05469 8.24822 7.05469C12.4605 7.05469 15.7691 11.5057 15.9055 11.6935C16.0419 11.8811 16.0419 12.1198 15.9055 12.2904C15.8715 12.3415 15.0528 13.4671 13.7055 14.6098C11.8978 16.1446 10.0047 16.9462 8.24807 16.9462C4.03577 16.9462 0.727172 12.4951 0.590781 12.3074C0.454265 12.1198 0.454265 11.881 0.590781 11.6935ZM8.24807 15.9228C11.3519 15.9228 14.0466 12.9725 14.848 12.0003C14.0464 11.0282 11.3689 8.07777 8.24807 8.07777C5.14421 8.07777 2.44952 11.0281 1.64818 12.0003C2.44979 12.9723 5.1272 15.9228 8.24807 15.9228Z"
        fill={props.strokeColor || "#31095E"}
      />
      <Path
        d="M8.24657 8.93066C9.93489 8.93066 11.3164 10.312 11.3164 12.0005C11.3164 13.689 9.93504 15.0703 8.24657 15.0703C6.55809 15.0703 5.17673 13.689 5.17673 12.0005C5.17673 10.312 6.55809 8.93066 8.24657 8.93066ZM8.24657 14.047C9.37216 14.047 10.293 13.1261 10.293 12.0005C10.293 10.8749 9.37216 9.95404 8.24657 9.95404C7.12097 9.95404 6.2001 10.8749 6.2001 12.0005C6.2001 13.1261 7.12097 14.047 8.24657 14.047Z"
        fill={props.strokeColor || "#31095E"}
      />
    </Icon>
  );
}
