import React from "react";
import { Icon, useTheme } from "native-base";
import { Circle, G, Path, Svg } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function ClubCradIcon({
  boxProps,
  color,
}: {
  boxProps?: InterfaceIconProps;
  color?: string;
}) {
  return (
    <Icon size="lg" viewBox="0 0 15 19" {...boxProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11257 0.749268C4.23394 0.749268 3.52166 1.46154 3.52166 2.34018H2.72621C2.0933 2.34018 1.48632 2.5916 1.03879 3.03913C0.591263 3.48666 0.339844 4.09364 0.339844 4.72654V15.8629C0.339844 16.4958 0.591263 17.1028 1.03879 17.5503C1.48632 17.9978 2.0933 18.2493 2.72621 18.2493H12.2717C12.9046 18.2493 13.5115 17.9978 13.9591 17.5503C14.4066 17.1028 14.658 16.4958 14.658 15.8629V4.72654C14.658 4.09364 14.4066 3.48666 13.9591 3.03913C13.5115 2.5916 12.9046 2.34018 12.2717 2.34018H11.4762C11.4762 1.46154 10.7639 0.749268 9.8853 0.749268H5.11257ZM11.4762 3.93109C11.4762 4.80972 10.7639 5.52199 9.8853 5.52199H5.11257C4.23394 5.52199 3.52166 4.80972 3.52166 3.93109H2.72621C2.51524 3.93109 2.31291 4.01489 2.16374 4.16407C2.01456 4.31325 1.93075 4.51557 1.93075 4.72654V15.8629C1.93075 16.0739 2.01456 16.2762 2.16374 16.4254C2.31291 16.5746 2.51524 16.6584 2.72621 16.6584H12.2717C12.4826 16.6584 12.685 16.5746 12.8341 16.4254C12.9833 16.2762 13.0671 16.0739 13.0671 15.8629V4.72654C13.0671 4.51557 12.9833 4.31325 12.8341 4.16407C12.685 4.01489 12.4826 3.93109 12.2717 3.93109H11.4762ZM5.11257 3.93109H9.8853V2.34018H5.11257V3.93109Z"
        fill={color || "#000000"}
      />
    </Icon>
  );
}
