import React from "react";
import { Icon } from "native-base";
import { G, Path } from "react-native-svg";
import { InterfaceIconProps } from "native-base/lib/typescript/components/primitives/Icon/types";

export default function MoneyIcon({
  props,
  color,
}: {
  props?: InterfaceIconProps;
  color?: string;
}) {
  return (
    <Icon size="sm" viewBox="0 0 15 14" alignSelf="center" {...props}>
      <G fill="none">
        <Path
          d="M8.25341 6.125C7.91245 6.0375 7.57148 5.8625 7.31575 5.6C7.06003 5.5125 6.97479 5.25 6.97479 5.075C6.97479 4.9 7.06003 4.6375 7.23051 4.55C7.48624 4.375 7.74196 4.2 7.99769 4.2875C8.50914 4.2875 8.93535 4.55 9.19108 4.9L9.95825 3.85C9.70253 3.5875 9.4468 3.4125 9.19108 3.2375C8.93535 3.0625 8.59438 2.975 8.25341 2.975V1.75H7.23051V2.975C6.8043 3.0625 6.37809 3.325 6.03712 3.675C5.69616 4.1125 5.44043 4.6375 5.52567 5.1625C5.52567 5.6875 5.69616 6.2125 6.03712 6.5625C6.46333 7 7.06003 7.2625 7.57148 7.525C7.8272 7.6125 8.16817 7.7875 8.4239 7.9625C8.59438 8.1375 8.67962 8.4 8.67962 8.6625C8.67962 8.925 8.59438 9.1875 8.4239 9.45C8.16817 9.7125 7.8272 9.8 7.57148 9.8C7.23051 9.8 6.8043 9.7125 6.54858 9.45C6.29285 9.275 6.03712 9.0125 5.86664 8.75L5.01422 9.7125C5.26995 10.0625 5.52567 10.325 5.86664 10.5875C6.29285 10.85 6.8043 11.1125 7.31575 11.1125V12.25H8.25341V10.9375C8.76487 10.85 9.19108 10.5875 9.53204 10.2375C9.95825 9.8 10.214 9.1 10.214 8.4875C10.214 7.9625 10.0435 7.35 9.61728 7C9.19108 6.5625 8.76487 6.3 8.25341 6.125ZM7.74196 0C3.99132 0 0.922607 3.15 0.922607 7C0.922607 10.85 3.99132 14 7.74196 14C11.4926 14 14.5613 10.85 14.5613 7C14.5613 3.15 11.4926 0 7.74196 0ZM7.74196 13.0375C4.50277 13.0375 1.86027 10.325 1.86027 7C1.86027 3.675 4.50277 0.9625 7.74196 0.9625C10.9812 0.9625 13.6237 3.675 13.6237 7C13.6237 10.325 10.9812 13.0375 7.74196 13.0375Z"
          fill={color || "#6D6D6D"}
        />
      </G>
    </Icon>
  );
}
