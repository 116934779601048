export default [
  {
    label: "Beginner",
    value: "Beginner",
  },
  {
    label: "GroupE",
    value: "GroupE",
  },
  {
    label: "GroupD",
    value: "GroupD",
  },
  {
    label: "GroupC",
    value: "GroupC",
  },
  {
    label: "GroupB",
    value: "GroupB",
  },
  {
    label: "GroupA",
    value: "GroupA",
  },
];
