import { AreaCode } from "../Request";

export enum VenueStatus {
  Open = "Open",
  Closed = "Closed",
}
export interface Venue {
  id: number;
  area: AreaCode;
  district: string;
  name: string;
  description: string;
  imageUrl: string;
  address: string;
  fee: number;
  phoneNo: string;
  numberOfTables: number;
  date: Date;
  openingTime: string;
  closingTime: string;
  availableTimeSlots?: Array<{ fromTime: Date; toTime: Date }>;
  ballsProvided: boolean;
  status: VenueStatus;
}

export interface CreateVenueRequest {
  name: string;
  imageUrl: string;
  area: string;
  district: string;
  address: string;
  phoneNo: string;
  numberOfTables: string;
  fee: number;
  ballsProvided: boolean;
  openingTime: string;
  closingTime: string;
  status: string;
  venueImage?: {
    fileName: string;
    fileContent: string;
  };
  clubStaffId: string;
  cancellationPeriod: "24" | "48" | "72";
  sameForEveryDay: boolean;
  listVenueOpeningHoursDtos: {
    dayOfWeek: number;
    openingTime: string;
    closingTime: string;
  }[];
}

export interface UpdateVenueRequest extends CreateVenueRequest {
  status: VenueStatus;
}
